import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core'
import OpenPlayerJS from 'openplayerjs'

@Component({
    selector: 'fbr-open-player',
    templateUrl: './open-player.component.html',
    styleUrls: ['./open-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenPlayer {
    @Input() id: string
    @Input() source: string

    constructor() {}

    @ViewChild('openplayer') playerElement: ElementRef

    public player

    ngAfterViewInit(): void {
        this.openPlayer()
    }

    openPlayer() {
        this.player = new OpenPlayerJS(this.playerElement.nativeElement, {
            controls: {
                // By default, the player will display the controls for a number of seconds before they are hidden; this option will allow the user to permanently show the controls if they need fully customize them.
                alwaysVisible: false,
                // Controls configuration by default; `levels` can be added as well since it's an optional feature;
                // Each one of the items will have in their class name the `op-control__[left|middle|right]` according
                // to the controls' structure listed below. Also available: `top-left`, `top-middle`,
                // `top-right`, `bottom-left`, `bottom-middle` and `bottom-right`.
                // If you use `main`, it will be appended to the media's container to create elements.
                layers: {
                    left: ['play', 'time', 'volume'],
                    middle: ['progress'],
                    right: ['captions', 'settings', 'fullscreen']
                }
            },
            hls: {
                startLevel: -1,
                enableWorker: true,
                widevineLicenseUrl: 'https://cwip-shaka-proxy.appspot.com/no_auth',
                emeEnabled: true
                // all HLS options available at https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning.
            },
            flv: {
                // all FLV options available at https://github.com/bilibili/flv.js/blob/master/docs/api.md#mediadatasource
            }
        })
        this.player.init()
    }
}
