import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { Advertiser } from '../models/Advertiser'

export class AdvertiserDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve an Advertiser by id.
     *
     * @param tenantId id of the current tenant
     * @param profileFieldId id of the advert to retrieve
     */
    byId(tenantId: string, advertId: string): Observable<Advertiser> {
        return this.http.get<Advertiser>(`${this.environment.adCMSApiHost}/api/${tenantId}/advertiser/${advertId}`)
    }

    /**
     * Create an advertiser.
     *
     * @param tenantId id of the current tenant
     * @param payload advertiser to create
     */
    create(tenantId: string, payload: any): Observable<Advertiser> {
        return this.http.post<Advertiser>(`${this.environment.adCMSApiHost}/api/${tenantId}/advertiser`, payload)
    }

    /**
     * List of Advertsers for a tenant
     *
     * @param tenantId id of the current tenant
     * @param page paginated page index
     * @param limit max number of records to return
     */
    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Advertiser>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Advertiser>>(`${this.environment.adCMSApiHost}/api/${tenantId}/Advertiser/all`, { params })
    }

    /**
     * Enable and disable an Advertiser
     *
     * @param tenantId id of the current tenant
     * @param advertiserId id of the selected advertiser
     * @param isActive new state of advertiser
     */
    toggleStatus(tenantId: string, advertiserId: string, isActive: boolean): Observable<Advertiser> {
        return this.http.put<Advertiser>(`${this.environment.adCMSApiHost}/api/${tenantId}/Advertiser/${advertiserId}/activate`, {})
    }

    /**
     * Update an Advertiser
     *
     * @param tenantId id of the current tenant
     * @param payload advertiser to update
     */
    update(tenantId: string, payload: any): Observable<Advertiser> {
        return this.http.put<Advertiser>(`${this.environment.adCMSApiHost}/api/${tenantId}/Advertiser/`, payload)
    }
}
