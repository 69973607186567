"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LanguageCode;
(function (LanguageCode) {
    /** Afrikaans */
    LanguageCode["af"] = "af";
    /** Akan */
    LanguageCode["ak"] = "ak";
    /** Albanian */
    LanguageCode["sq"] = "sq";
    /** Amharic */
    LanguageCode["am"] = "am";
    /** Arabic */
    LanguageCode["ar"] = "ar";
    /** Armenian */
    LanguageCode["hy"] = "hy";
    /** Assamese */
    LanguageCode["as"] = "as";
    /** Azerbaijani */
    LanguageCode["az"] = "az";
    /** Bambara */
    LanguageCode["bm"] = "bm";
    /** Bangla */
    LanguageCode["bn"] = "bn";
    /** Basque */
    LanguageCode["eu"] = "eu";
    /** Belarusian */
    LanguageCode["be"] = "be";
    /** Bosnian */
    LanguageCode["bs"] = "bs";
    /** Breton */
    LanguageCode["br"] = "br";
    /** Bulgarian */
    LanguageCode["bg"] = "bg";
    /** Burmese */
    LanguageCode["my"] = "my";
    /** Catalan */
    LanguageCode["ca"] = "ca";
    /** Chechen */
    LanguageCode["ce"] = "ce";
    /** Chinese */
    LanguageCode["zh"] = "zh";
    /** Simplified Chinese */
    LanguageCode["zh_Hans"] = "zh_Hans";
    /** Traditional Chinese */
    LanguageCode["zh_Hant"] = "zh_Hant";
    /** Church Slavic */
    LanguageCode["cu"] = "cu";
    /** Cornish */
    LanguageCode["kw"] = "kw";
    /** Corsican */
    LanguageCode["co"] = "co";
    /** Croatian */
    LanguageCode["hr"] = "hr";
    /** Czech */
    LanguageCode["cs"] = "cs";
    /** Danish */
    LanguageCode["da"] = "da";
    /** Dutch */
    LanguageCode["nl"] = "nl";
    /** Flemish */
    LanguageCode["nl_BE"] = "nl_BE";
    /** Dzongkha */
    LanguageCode["dz"] = "dz";
    /** English */
    LanguageCode["en"] = "en";
    /** Australian English */
    LanguageCode["en_AU"] = "en_AU";
    /** Canadian English */
    LanguageCode["en_CA"] = "en_CA";
    /** British English */
    LanguageCode["en_GB"] = "en_GB";
    /** American English */
    LanguageCode["en_US"] = "en_US";
    /** Esperanto */
    LanguageCode["eo"] = "eo";
    /** Estonian */
    LanguageCode["et"] = "et";
    /** Ewe */
    LanguageCode["ee"] = "ee";
    /** Faroese */
    LanguageCode["fo"] = "fo";
    /** Finnish */
    LanguageCode["fi"] = "fi";
    /** French */
    LanguageCode["fr"] = "fr";
    /** Canadian French */
    LanguageCode["fr_CA"] = "fr_CA";
    /** Swiss French */
    LanguageCode["fr_CH"] = "fr_CH";
    /** Fulah */
    LanguageCode["ff"] = "ff";
    /** Galician */
    LanguageCode["gl"] = "gl";
    /** Ganda */
    LanguageCode["lg"] = "lg";
    /** Georgian */
    LanguageCode["ka"] = "ka";
    /** German */
    LanguageCode["de"] = "de";
    /** Austrian German */
    LanguageCode["de_AT"] = "de_AT";
    /** Swiss High German */
    LanguageCode["de_CH"] = "de_CH";
    /** Greek */
    LanguageCode["el"] = "el";
    /** Gujarati */
    LanguageCode["gu"] = "gu";
    /** Haitian Creole */
    LanguageCode["ht"] = "ht";
    /** Hausa */
    LanguageCode["ha"] = "ha";
    /** Hebrew */
    LanguageCode["he"] = "he";
    /** Hindi */
    LanguageCode["hi"] = "hi";
    /** Hungarian */
    LanguageCode["hu"] = "hu";
    /** Icelandic */
    LanguageCode["is"] = "is";
    /** Igbo */
    LanguageCode["ig"] = "ig";
    /** Indonesian */
    LanguageCode["id"] = "id";
    /** Interlingua */
    LanguageCode["ia"] = "ia";
    /** Irish */
    LanguageCode["ga"] = "ga";
    /** Italian */
    LanguageCode["it"] = "it";
    /** Japanese */
    LanguageCode["ja"] = "ja";
    /** Javanese */
    LanguageCode["jv"] = "jv";
    /** Kalaallisut */
    LanguageCode["kl"] = "kl";
    /** Kannada */
    LanguageCode["kn"] = "kn";
    /** Kashmiri */
    LanguageCode["ks"] = "ks";
    /** Kazakh */
    LanguageCode["kk"] = "kk";
    /** Khmer */
    LanguageCode["km"] = "km";
    /** Kikuyu */
    LanguageCode["ki"] = "ki";
    /** Kinyarwanda */
    LanguageCode["rw"] = "rw";
    /** Korean */
    LanguageCode["ko"] = "ko";
    /** Kurdish */
    LanguageCode["ku"] = "ku";
    /** Kyrgyz */
    LanguageCode["ky"] = "ky";
    /** Lao */
    LanguageCode["lo"] = "lo";
    /** Latin */
    LanguageCode["la"] = "la";
    /** Latvian */
    LanguageCode["lv"] = "lv";
    /** Lingala */
    LanguageCode["ln"] = "ln";
    /** Lithuanian */
    LanguageCode["lt"] = "lt";
    /** Luba-Katanga */
    LanguageCode["lu"] = "lu";
    /** Luxembourgish */
    LanguageCode["lb"] = "lb";
    /** Macedonian */
    LanguageCode["mk"] = "mk";
    /** Malagasy */
    LanguageCode["mg"] = "mg";
    /** Malay */
    LanguageCode["ms"] = "ms";
    /** Malayalam */
    LanguageCode["ml"] = "ml";
    /** Maltese */
    LanguageCode["mt"] = "mt";
    /** Manx */
    LanguageCode["gv"] = "gv";
    /** Maori */
    LanguageCode["mi"] = "mi";
    /** Marathi */
    LanguageCode["mr"] = "mr";
    /** Mongolian */
    LanguageCode["mn"] = "mn";
    /** Nepali */
    LanguageCode["ne"] = "ne";
    /** North Ndebele */
    LanguageCode["nd"] = "nd";
    /** Northern Sami */
    LanguageCode["se"] = "se";
    /** Norwegian Bokmål */
    LanguageCode["nb"] = "nb";
    /** Norwegian Nynorsk */
    LanguageCode["nn"] = "nn";
    /** Nyanja */
    LanguageCode["ny"] = "ny";
    /** Odia */
    LanguageCode["or"] = "or";
    /** Oromo */
    LanguageCode["om"] = "om";
    /** Ossetic */
    LanguageCode["os"] = "os";
    /** Pashto */
    LanguageCode["ps"] = "ps";
    /** Persian */
    LanguageCode["fa"] = "fa";
    /** Dari */
    LanguageCode["fa_AF"] = "fa_AF";
    /** Polish */
    LanguageCode["pl"] = "pl";
    /** Portuguese */
    LanguageCode["pt"] = "pt";
    /** Brazilian Portuguese */
    LanguageCode["pt_BR"] = "pt_BR";
    /** European Portuguese */
    LanguageCode["pt_PT"] = "pt_PT";
    /** Punjabi */
    LanguageCode["pa"] = "pa";
    /** Quechua */
    LanguageCode["qu"] = "qu";
    /** Romanian */
    LanguageCode["ro"] = "ro";
    /** Moldavian */
    LanguageCode["ro_MD"] = "ro_MD";
    /** Romansh */
    LanguageCode["rm"] = "rm";
    /** Rundi */
    LanguageCode["rn"] = "rn";
    /** Russian */
    LanguageCode["ru"] = "ru";
    /** Samoan */
    LanguageCode["sm"] = "sm";
    /** Sango */
    LanguageCode["sg"] = "sg";
    /** Sanskrit */
    LanguageCode["sa"] = "sa";
    /** Scottish Gaelic */
    LanguageCode["gd"] = "gd";
    /** Serbian */
    LanguageCode["sr"] = "sr";
    /** Shona */
    LanguageCode["sn"] = "sn";
    /** Sichuan Yi */
    LanguageCode["ii"] = "ii";
    /** Sindhi */
    LanguageCode["sd"] = "sd";
    /** Sinhala */
    LanguageCode["si"] = "si";
    /** Slovak */
    LanguageCode["sk"] = "sk";
    /** Slovenian */
    LanguageCode["sl"] = "sl";
    /** Somali */
    LanguageCode["so"] = "so";
    /** Southern Sotho */
    LanguageCode["st"] = "st";
    /** Spanish */
    LanguageCode["es"] = "es";
    /** European Spanish */
    LanguageCode["es_ES"] = "es_ES";
    /** Mexican Spanish */
    LanguageCode["es_MX"] = "es_MX";
    /** Sundanese */
    LanguageCode["su"] = "su";
    /** Swahili */
    LanguageCode["sw"] = "sw";
    /** Congo Swahili */
    LanguageCode["sw_CD"] = "sw_CD";
    /** Swedish */
    LanguageCode["sv"] = "sv";
    /** Tajik */
    LanguageCode["tg"] = "tg";
    /** Tamil */
    LanguageCode["ta"] = "ta";
    /** Tatar */
    LanguageCode["tt"] = "tt";
    /** Telugu */
    LanguageCode["te"] = "te";
    /** Thai */
    LanguageCode["th"] = "th";
    /** Tibetan */
    LanguageCode["bo"] = "bo";
    /** Tigrinya */
    LanguageCode["ti"] = "ti";
    /** Tongan */
    LanguageCode["to"] = "to";
    /** Turkish */
    LanguageCode["tr"] = "tr";
    /** Turkmen */
    LanguageCode["tk"] = "tk";
    /** Ukrainian */
    LanguageCode["uk"] = "uk";
    /** Urdu */
    LanguageCode["ur"] = "ur";
    /** Uyghur */
    LanguageCode["ug"] = "ug";
    /** Uzbek */
    LanguageCode["uz"] = "uz";
    /** Vietnamese */
    LanguageCode["vi"] = "vi";
    /** Volapük */
    LanguageCode["vo"] = "vo";
    /** Welsh */
    LanguageCode["cy"] = "cy";
    /** Western Frisian */
    LanguageCode["fy"] = "fy";
    /** Wolof */
    LanguageCode["wo"] = "wo";
    /** Xhosa */
    LanguageCode["xh"] = "xh";
    /** Yiddish */
    LanguageCode["yi"] = "yi";
    /** Yoruba */
    LanguageCode["yo"] = "yo";
    /** Zulu */
    LanguageCode["zu"] = "zu";
})(LanguageCode = exports.LanguageCode || (exports.LanguageCode = {}));
var Permission;
(function (Permission) {
    /**  The Authenticated role means simply that the user is logged in  */
    Permission["Authenticated"] = "Authenticated";
    /**  SuperAdmin can perform the most sensitive tasks */
    Permission["SuperAdmin"] = "SuperAdmin";
    /**  Owner means the user owns this entity */
    Permission["Owner"] = "Owner";
    /**  Public means any unauthenticated user may perform the operation  */
    Permission["Public"] = "Public";
    Permission["CreateMember"] = "CreateMember";
    Permission["ReadMember"] = "ReadMember";
    Permission["UpdateMember"] = "UpdateMember";
    Permission["DeleteMember"] = "DeleteMember";
    Permission["CreateAdministrator"] = "CreateAdministrator";
    Permission["ReadAdministrator"] = "ReadAdministrator";
    Permission["UpdateAdministrator"] = "UpdateAdministrator";
    Permission["DeleteAdministrator"] = "DeleteAdministrator";
    Permission["CreateSettings"] = "CreateSettings";
    Permission["ReadSettings"] = "ReadSettings";
    Permission["UpdateSettings"] = "UpdateSettings";
    Permission["DeleteSettings"] = "DeleteSettings";
})(Permission = exports.Permission || (exports.Permission = {}));
