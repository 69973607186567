import { Environment } from '@fabrik/common'

export const environment: Environment = {
    production: false,
    hideNavItems: false,
    tokenKey: 'FBR_CMMNT_CNT_TKN',
    adCMSApiHost: 'https://staging-adcms.fabrik.fm',
    authHost: 'https://staging-identity.fabrik.fm',
    authClientId: '15ea2d8d-f12b-43e5-88b7-2883bd779ab0',
    appApiHost: 'https://staging-app.fabrik.fm',
    echocastHost: 'https://staging-echocast.fabrik.fm',
    fabrikFormsHost: 'https://staging-app.fabrik.fm',
    fabrikFrontEndHost: ' https://staging-forms.fabrik.fm',
    metricsHost: 'https://metrics.fabrik.fm',
    metricsApiHost: 'https://staging-metrics-api.fabrik.fm/api',
    matomoHost: 'https://matomo.fabrik.fm/',
    matomoId: 112,
    smashboardHost: 'https://staging-smashboard.fabrik.fm',
    smashboardAPI: 'https://staging-api-smashboard.fabrik.fm',
    podcastBase: 'https://streaming.fabrik.fm/staging-fabrik/echocast/player/rewind/podcast-player.html',
    livestreamBase: 'https://streaming.fabrik.fm/staging-fabrik/echocast/player/live/player.html',
    singlepodcastBase: 'https://streaming.fabrik.fm/staging-fabrik/echocast/player/rewind-single/player.html',
    enableHttpsRedirect: true,
    bundledBaseUrl: 'https://staging-static.fabrik.fm/bundled-images/chat-groups',
    availableLanguages: ['en'],
    defaultLanguage: 'en',
    defaultPageSize: 25,
    defaultPageSizeOptions: [25, 50, 75, 100],
    identityTenantId: 'ba3fb062-20b4-4661-9b26-8f35190a15b9',
    googleMapKey: 'AIzaSyCIc8_0VApTJA--OCljeBToZ4Y_ZXRCVGo',
    echoLimit: 15,
    echoGeneralPresetName: 'General',
    echoGeneralPresetId: '-1',
    isDisableThread: false,
    isDisableDefaultIcon: false,
    nowInternalMetricsDelayInMinutes: 6
}
