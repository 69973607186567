import { Observable } from 'rxjs'

import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { ChatGroup, ChatGroupBundleImage, ChatGroupUploadImage } from '../models/ChatGroup'

export class ChatGroupDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a list of chat group bundled images
     *
     * @param tenantId id of the current tenant
     */
    bundledImages(tenantId: string, chatGroupImageType: number): Observable<ChatGroupBundleImage[]> {       
        return this.http.get<ChatGroupBundleImage[]>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/images?imageType=${chatGroupImageType.toString()}`)
    }

    /**
     * Retrieve chat group by id.
     *
     * @param tenantId id of the current tenant
     * @param chatGroupId id of the chat group to retrieve
     */
    byId(tenantId: string, chatGroupId: string): Observable<ChatGroup> {
        return this.http.get<ChatGroup>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}`)
    }

    /**
     * Create a chat group
     *
     * @param tenantId id of the current tenant
     */
    create(tenantId: string, payload: ChatGroup): Observable<ChatGroup> {
        return this.http.post<ChatGroup>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups`, payload)
    }

    /**
     * Delete chat group
     *
     * @param tenantId id of the current tenant
     * @param chatGroupId id of the chat group to delete
     */
    delete(tenantId: string, chatGroupId: string): Observable<ChatGroup> {
        return this.http.delete<ChatGroup>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}/delete`)
    }

    /**
     * Disable a chat group
     *
     * @param tenantId id of the current tenant
     * @param chatGroupId id of the chat group to disable
     */
    disable(tenantId: string, chatGroupId: string): Observable<ChatGroup> {
        return this.http.delete<ChatGroup>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}/deactivate`)
    }

    /**
     * Enable a chat group
     *
     * @param tenantId id of the current tenant
     * @param chatGroupId id of the chat group to enable
     */
    enable(tenantId: string, chatGroupId: string): Observable<ChatGroup> {
        return this.http.put<ChatGroup>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}/activate`, {})
    }

    /**
     * List chat groups
     *
     * @param tenantId id of the current tenant
     * @param sortField field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { isActive?: string; sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any; includeOneOnOne?: boolean } = {}): Observable<PaginatedList<ChatGroup>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<ChatGroup>>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups`, { params })
    }

    /**
   * Update a chat group

   * @param tenantId id of the current tenant
   * @param data chat group data to update
   */
    update(tenantId: string, payload: ChatGroup): Observable<ChatGroup> {
        return this.http.put<ChatGroup>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups`, payload)
    }

    /**
   * Order chatgroups

   * @param tenantId id of the current tenant
   * @param data chat groups order
   */
    order(tenantId: string, payload: any): Observable<ChatGroup> {
        return this.http.put<ChatGroup>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/order`, payload)
    }

    /**
     * Upload a chat group image
     *
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadImage(tenantId: string, file: FormData): Observable<HttpEvent<ChatGroupUploadImage>> {
        return this.http.post<ChatGroupUploadImage>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/image`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }
}
