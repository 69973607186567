import { Observable } from 'rxjs'

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { Member, MemberSummary } from '../models/Member'

export class MemberDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Activate
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to activate
     */
    activate(tenantId: string, memberId: string): Observable<Member> {
        return this.http.post<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/users/${memberId}/unsuspend`, {})
    }

    /**
     * Add member to one or more chat groups
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to update
     * @param chatGroupIds id array of the chat groups to add
     */
    addChatGroups(tenantId: string, memberId: string, chatGroupIds: string[]) {
        return this.http.post(
            `${this.environment.appApiHost}/api/${tenantId}/admin/users/${memberId}/chatgroups`,
            chatGroupIds.map(chatGroupId => ({ chatGroupId, joined: true }))
        )
    }

    /**
     * Retrieve a member by id. Also included the chatgroups and custom profile fields
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to retrieve
     */
    byId(tenantId: string, memberId: string): Observable<Member> {
        return this.http.get<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/users/${memberId}`)
    }

    /**
     * Distrust
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to distrust
     */
    distrust(tenantId: string, memberId: string): Observable<Member> {
        return this.http.delete<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/users/${memberId}/trust`, {})
    }

    /**
     * Export members (via email)
     *
     * @param tenantId The id of the tenant
     * @param email The email address to send the exported CSV file to
     */
    export(tenantId: string, email: string) {
        return this.http.get(`${this.environment.appApiHost}/api/${tenantId}/admin/users/export?email=${encodeURIComponent(email)}`)
    }

    /**
     * List members
     *
     * @param tenantId id of the current tenant
     * @param sortField member field to sort
     * @param sortDirection ascending or descending
     * @param userState current state of the member
     * @param suspended suspended state of the member
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */
    list(tenantId: string, args: { sortField?: string; sortDirection?: string; userState?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Member>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                case 'userState':
                    switch (args.userState) {
                        case 'Suspended':
                            params = params.set('suspended', 'true')
                            break

                        case 'Trusted':
                            params = params.set('trusted', 'true')
                            break

                        default:
                            if (utils.notNullOrUndefined(args.userState)) {
                                params = params.set(key, args.userState)
                            }
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Member>>(`${this.environment.appApiHost}/api/${tenantId}/admin/users`, { params })
    }

    /**
     * List members
     *
     * @param tenantId id of the current tenant
     * @param chatGroupId id of the chat group to list members for
     * @param sortField member field to sort
     * @param sortDirection ascending or descending
     * @param userState current state of the member
     * @param suspended suspended state of the member
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */
    listByChatGroup(tenantId: string, chatGroupId: string, args: { sortField?: string; sortDirection?: string; userState?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Member>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                case 'userState':
                    switch (args.userState) {
                        case 'Suspended':
                            params = params.set('suspended', 'true')
                            break

                        case 'Trusted':
                            params = params.set('trusted', 'true')
                            break

                        default:
                            if (utils.notNullOrUndefined(args.userState)) {
                                params = params.set(key, args.userState)
                            }
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Member>>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}/users`, { params })
    }

    /**
     * Remove member from chat group
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to update
     * @param chatGroupId id of the chat group to remove
     */
    removeChatGroup(tenantId: string, memberId: string, chatGroupId: string) {
        const body: any = [memberId]
        const headers = new HttpHeaders().set('Content-Type', 'application/json')

        return this.http.request('delete', `${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}/users`, { body, headers })
    }

    /**
     * Update member state to either Approved or Rejected
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to update
     * @param state the new state value. either Approved or Rejected
     */
    setState(tenantId: string, memberId: string, state: 'Approved' | 'Rejected'): Observable<Member> {
        return this.http.post<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/users/${memberId}/state?userState=${state}`, {})
    }

    /**
     * Retrieve the member summary for the specified tenant
     *
     * @param tenantId The id of the tenant
     */
    summary(tenantId: string): Observable<MemberSummary> {
        return this.http.get<MemberSummary>(`${this.environment.appApiHost}/api/${tenantId}/admin/users/summary`)
    }

    /**
     * Suspend
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to suspend
     */
    suspend(tenantId: string, memberId: string): Observable<Member> {
        return this.http.post<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/users/${memberId}/suspend`, {})
    }

    /**
     * Trust
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to trust
     */
    trust(tenantId: string, memberId: string): Observable<Member> {
        return this.http.post<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/users/${memberId}/trust`, {})
    }

    /**
     * Verify users in a chatgroup
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to update
     * @param chatGroupId id of the chat group to remove
     *
     */
    verify(tenantId: string, memberId: string, chatGroupId: string): Observable<Member> {
        const body: any = [memberId]
        return this.http.post<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}/verifiedUser`, body)
    }

    /**
     * Unverify users in a chatgroup
     *
     * @param tenantId id of the current tenant
     * @param memberId id of the member to update
     * @param chatGroupId id of the chat group to remove
     */
    unverify(tenantId: string, memberId: string, chatGroupId: string) {
        const body: any = [memberId]
        const headers = new HttpHeaders().set('Content-Type', 'application/json')

        return this.http.request('delete', `${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups/${chatGroupId}/verifiedUser`, { body, headers })
    }
}
