<div class="image-container">
    <img [src]="src" [alt]="alt" />
</div>
<ng-template fbrDialogButtons>
    <ng-container *ngFor="let button of buttons">
        <button class="btn" [class.btn-primary]="button.type === 'primary'" [class.btn-danger]="button.type === 'danger'" (click)="resolveWith(button.returnValue)">
            {{ button.label | translate }}
        </button>
    </ng-container>
</ng-template>
