import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { Ticket } from '../models/Ticket'

export class FreshDeskDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Create a freshdesk ticket- Feedback
     *
     *
     */
    async createTicket(body: Ticket) {
        return this.http.request('post', `${this.environment.appApiHost}/api/support?api-version=1.15.0`, { body }).subscribe()
    }
}
