import { Component } from '@angular/core'
import { DataService } from '../../data/providers/data.service'

import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { Router } from '@angular/router'
import { ModalOptions } from '../../providers/modal/modal.service'

/**
 * A form input control which displays a number input with a percentage sign suffix.
 */
@Component({
    selector: 'fbr-trust-page',
    templateUrl: './trust-page.component.html',
    styleUrls: ['./trust-page.component.scss']
})
export class TrustPageComponent implements ModalOptions<any> {
    size: 'xl'
    constructor(public dataService: DataService, private router: Router) {}

    resolveWith: (result?: any) => void
    cancel() {
        this.router.navigate(['./'], {
            queryParams: null
        })
        this.resolveWith()
    }
}
