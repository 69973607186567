import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { config } from 'process'

import { ComponentRenderer } from '../interfaces/component-renderer.interface'
import { ColumnComponentRendererSpec, ColumnRendererSpec } from '../interfaces/datagrid-column.interface'
declare var MediaElementPlayer: any

export interface AudioRendererConfig {
    id: string
    source: string
    fallbackSrc: string
}

@Component({
    selector: 'media-element',
    template: `
        <audio id="{{ id }}" #mediaelement></audio>
    `
})
export class AudioRenderedComponent implements ComponentRenderer<AudioRendererConfig>, AfterViewInit {
    @Input() id: string
    @Input() source: string

    config: AudioRendererConfig

    constructor() {}

    @ViewChild('mediaelement') mediaPlayerElement: ElementRef

    public player

    ngAfterViewInit(): void {
        this.loadMediaPlayer()
    }

    loadMediaPlayer() {
        this.player = new MediaElementPlayer(this.mediaPlayerElement.nativeElement, {
            alwaysShowControls: true,
            renderers: ['native_hls', 'html5', 'flash_hls', 'flash_video', 'flash_audio'],
            features: ['playpause'],
            audioVolume: 'horizontal',
            framesPerSecond: 25,
            audioWidth: 30,
            audioHeight: 30
        })
        if (this.source) {
            this.player.src = this.source
        } else {
            this.player.src = this.config.source
        }
    }
}

/**
 * Creates a {@link ColumnRendererSpec} for rendering an audio player in a column.
 */
export function AudioRenderer<R>(idProperty: string, srcPropertyName: string, fallbackSrc: string = ''): ColumnRendererSpec<R, AudioRendererConfig> {
    return ColumnComponentRendererSpec({
        type: AudioRenderedComponent,
        config(record): AudioRendererConfig {
            return {
                id: ((record as R)[idProperty] as string) || '',
                source: ((record as R)[srcPropertyName] as string) || fallbackSrc,
                fallbackSrc
            }
        }
    })
}
