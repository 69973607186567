import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { PaginatedList } from '@fabrik/common'
import { utils } from '@fabrik/common'
import { FormQuestion, FormRecord, ProfileKey, QuestionPosition } from '../models/Form'

export class FormDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Return a single form record.
     *
     * @param tenantId id of the current tenant
     * @param formId id of the desired form
     */
    getById(tenantId: string, formId: string): Observable<FormRecord> {
        return this.http.get<FormRecord>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms/${formId}`)
    }

    /**
     * List form records for a tenant
     *
     * @param tenantId id of the current tenant
     */
    list(tenantId: string): Observable<FormRecord[]> {
        return this.http.get<FormRecord[]>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms`)
    }

    /**
     * List paginated form records for a tenant
     *
     * @param tenantId id of the current tenant
     */
    listPaginated(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<FormRecord>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (args && args.page) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<FormRecord>>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms/paginated`, { params })
    }

    /**
     * Create form record for a tenant
     *
     * @param tenantId id of the current tenant
     * @param formData object containing form config data.
     */
    create(tenantId: string, formData: FormRecord): Observable<FormRecord> {
        return this.http.post<FormRecord>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms`, formData)
    }

    /**
     * Update form record for a tenant
     *
     * @param tenantId id of the current tenant
     * @param formId id of the desired form record to update.
     * @param formData object containing form config data.
     */
    update(tenantId: string, formId: string, formData: FormRecord): Observable<FormRecord> {
        return this.http.put<FormRecord>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms/${formId}`, formData)
    }

    /**
     * Create a question for an existing form
     *
     * @param tenantId id of the current tenant
     * @param formId id of the parent form record.
     * @param questionData object containing question config data.
     */
    createQuestion(tenantId: string, formId: string, questionData: FormQuestion): Observable<FormQuestion> {
        return this.http.post<FormQuestion>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms/${formId}/questions`, questionData)
    }

    /**
     * Update a question for an existing form
     *
     * @param tenantId id of the current tenant
     * @param formId id of the parent form record.
     * @param questionId id of the question record to update.
     * @param questionData object containing question config data.
     */
    updateQuestion(tenantId: string, formId: string, questionId: string, questionData: FormQuestion): Observable<FormQuestion> {
        return this.http.put<FormQuestion>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms/${formId}/questions/${questionId}`, questionData)
    }

    /**
     * Delete a question from an existing form
     *
     * @param tenantId id of the current tenant
     * @param formId id of the parent form record.
     * @param questionId id of the question to delete.
     */
    deleteQuestion(tenantId: string, formId: string, questionId: string): Observable<FormQuestion> {
        return this.http.delete<FormQuestion>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms/${formId}/questions/${questionId}`)
    }

    /**
     * Delete a question from an existing form
     *
     * @param tenantId id of the current tenant
     * @param formId id of the parent form record.
     * @param questionId id of the question to delete.
     */
    sortQuestions(tenantId: string, formId: string, sortedQuestions: QuestionPosition[]): Observable<FormRecord> {
        return this.http.put<FormRecord>(`${this.environment.appApiHost}/api/${tenantId}/administration/forms/${formId}/questions/position/order`, sortedQuestions)
    }

    /**
     * Gets a list of profile keys
     *
     * @param tenantId id of the current tenant
     */
    getProfileKeys(tenantId: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.environment.appApiHost}/api/${tenantId}/administration/profiles`)
    }
}
