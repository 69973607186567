import { Pipe, PipeTransform } from '@angular/core'

/**
 * Formats a number. e.g. 6000000 -> 6M
 */
@Pipe({
    name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
    transform(value: any, ...args: any[]): string {
        const number = Number(value)

        if (isNaN(number)) {
            return value
        }

        if (value < 1000) {
            return value
        } else if (value > 1000 && value < 1000000) {
            value = parseInt(value, 10)
            return (value / 1000).toFixed(1).replace('.0', '') + 'K'
        }

        return value / 1000000 + 'M'
    }
}
