import { Route } from '@angular/router'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { AppShellComponent, AuthAppShellComponent, AuthGuard, NoAuthGuard } from '@fabrik/web/core'

export const routes: Route[] = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: AppShellComponent,
        data: {
            breadcrumb: _('breadcrumb.dashboard')
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('@fabrik/web/dashboard').then(m => m.DashboardModule)
            },
            {
                path: 'esg',                
                loadChildren: () => import('@fabrik/web/esg').then(m => m.EsgModule)
            },
            {
                path: 'access-control',
                loadChildren: () => import('@fabrik/web/access-control').then(m => m.AccessControlModule)
            },
            {
                path: 'campaign-cast',
                loadChildren: () => import('@fabrik/web/campaign-cast').then(m => m.CampaignCastModule)
            },
            {
                path: 'member',
                loadChildren: () => import('@fabrik/web/member').then(m => m.MemberModule)
            },
            {
                path: 'metrics',
                loadChildren: () => import('@fabrik/web/metrics').then(m => m.MetricsModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('@fabrik/web/settings').then(m => m.SettingModule)
            },
            {
                path: 'content-moderation',
                loadChildren: () => import('@fabrik/web/content-moderation').then(m => m.ReportModule)
            },
            {
                path: 'advertising',
                loadChildren: () => import('@fabrik/web/advertising').then(m => m.AdvertisingModule)
            },
            {
                path: 'service-directory',
                loadChildren: () => import('@fabrik/web/service-directory').then(m => m.ServiceDirectoryModule)
            },
            {
                path: 'smashboard',
                loadChildren: () => import('@fabrik/web/smashboard').then(m => m.SmashboardModule)
            },
            {
                path: 'content-feed',
                loadChildren: () => import('@fabrik/web/content-feed').then(m => m.ContentFeedModule)
            },
            {
                path: 'forms',
                loadChildren: () => import('@fabrik/web/forms').then(m => m.FormsModule)
            },
            {
                path: 'echocast',
                loadChildren: () => import('@fabrik/web/echocast').then(m => m.EchocastModule)
            }           
        ]
    },
    {
        path: '',
        canActivate: [NoAuthGuard],
        component: AuthAppShellComponent,
        children: [
            {
                path: 'authenticate',
                loadChildren: () => import('@fabrik/web/authenticate').then(m => m.AuthenticateModule)
            }
        ]
    }
]
