import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { Environment } from '@fabrik/common'
import { size } from 'lodash'
import { EMPTY } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { MeResponse } from '../../data/models/MeResponse'
import { Ticket } from '../../data/models/Ticket'
import { DataService } from '../../data/providers/data.service'
import { ModalService } from '../../providers/modal/modal.service'
import { NavMenuItem, NavMenuSection } from '../../providers/nav-builder/nav-builder-types'
import { NavBuilderService } from '../../providers/nav-builder/nav-builder.service'
import { NotificationService } from '../../providers/notification/notification.service'
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component'
import { TrustPageComponent } from '../trust-page/trust-page.component'
import { mainNavItems } from './main-nav-items'
@Component({
    selector: 'fbr-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
    private abilities = new Array<string>()
    private isRadioTenant: boolean
    isIdentityMember: boolean
    isDemoMode: boolean

    constructor(
        private route: ActivatedRoute,
        public navBuilderService: NavBuilderService,
        public dataService: DataService,
        private environment: Environment,
        private modalService: ModalService,
        private notificationService: NotificationService
    ) {
        this.me = this.dataService.auth.me

        this.route.queryParams.subscribe(params => {
            if (params['feedback-form']) {
                this.openFeedback()
            }
        })

        this.route.queryParams.subscribe(params => {
            if (params['trust-page']) {
                this.openTrustCenter()
            }
        })

        this.abilities = this.dataService.auth.abilities
        this.isRadioTenant = this.dataService.auth.isRadioTenant
        this.isIdentityMember = this.dataService.auth.isIdentityMember
        this.isDemoMode = this.dataService.auth.demoMode
    }

    private navMenuItems: NavMenuSection[] = mainNavItems

    public me: MeResponse | null

    public get filteredNavMenuItems(): NavMenuSection[] {
        if (this.environment.hideNavItems) {
            return this.navMenuItems.filter(item => {
                if (item.hidden) {
                    return false
                }

                if (item.items && item.items.length) {
                    item.items = item.items.filter(subitem => {
                        if(subitem.isInternalMenu){   
                                return this.dataService.auth.isInternalUser;
                        }
                        else if (subitem.requiresPermission) {                          
                            return Boolean(
                                subitem.requiresPermission.find(term => {
                                    return this.abilities.find(ability => ability.includes(term))
                                })
                            )
                        }                        
                        return true
                    })
                }

                if (!item.items.length) {
                    return false
                }

                if (!item.requiresPermission || !item.requiresPermission.length) {
                    return true
                }

                return this.dataService.auth.validateAbility(item.requiresPermission)
            })
        }

        return this.navMenuItems
    }

    getRouterLink(item: NavMenuItem) {
        return this.navBuilderService.getRouterLink(item, this.route)
    }

    openFeedback() {
        if (this.me) {
            this.modalService
                .fromComponent(FeedbackFormComponent, {
                    locals: { ticketdata: { name: '', email: this.me.email, contactNumber: '', subject: 'Community Center: Feedback Form', description: '', type: 'HowTo' } as Ticket }
                })
                .pipe(switchMap(ticketData => (ticketData ? this.dataService.freshDesk.createTicket(ticketData as Ticket) : EMPTY)))
                .subscribe(
                    () => {
                        this.notificationService.success(_('feedback-form.success'), { entity: _('noun.key-name'), verb: _('common.added') })
                    },
                    () => this.notificationService.error(_('common.notify-error'), { entity: _('noun.key-name'), verb: _('common.add') })
                )
        }
    }

    openTrustCenter() {
        if (this.me) {
            this.modalService
                .fromComponent(TrustPageComponent, {
                    size: 'xl'
                })
                .pipe()
                .subscribe()
        }
    }

    showIfRadioTenant(item: NavMenuItem): boolean {
        return item.id !== 'streaming' ? true : this.isRadioTenant
    }

    toggleDemoMode() {
        if (this.dataService.auth.demoMode) {
            this.dataService.auth.setDemoMode(false)
        } else {
            this.dataService.auth.setDemoMode(true)
        }

        this.isDemoMode = this.dataService.auth.demoMode
    }
}
