"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Environment = /** @class */ (function () {
    function Environment() {
        this.production = false;
        this.hideNavItems = true;
        this.tokenKey = '';
        this.adCMSApiHost = '';
        this.authHost = '';
        this.authClientId = '';
        this.appApiHost = '';
        this.echocastHost = '';
        this.fabrikFormsHost = '';
        this.fabrikFrontEndHost = '';
        this.metricsHost = '';
        this.metricsApiHost = '';
        this.matomoHost = '';
        this.matomoId = 0;
        this.smashboardHost = '';
        this.smashboardAPI = '';
        this.podcastBase = '';
        this.livestreamBase = '';
        this.singlepodcastBase = '';
        this.enableHttpsRedirect = true;
        this.bundledBaseUrl = '';
        this.availableLanguages = ['en'];
        this.defaultLanguage = 'en';
        this.defaultPageSize = 25;
        this.defaultPageSizeOptions = [25, 50, 75, 100];
        this.identityTenantId = '';
        this.googleMapKey = '';
        this.echoLimit = 15;
        this.echoGeneralPresetName = 'General';
        this.echoGeneralPresetId = '-1';
        this.isDisableThread = false;
        this.isDisableDefaultIcon = false;
        this.nowInternalMetricsDelayInMinutes = 1;
    }
    return Environment;
}());
exports.Environment = Environment;
