<ng-container *ngIf="!items || (items && items.length); else emptyPlaceholder">
    <table class="table">
        <thead>
            <tr>
                <th *ngIf="isRowSelectedFn" class="align-middle">
                    <input type="checkbox" clrCheckbox [checked]="allSelected" (change)="allSelectChange.emit()" />
                </th>
                <th *ngFor="let header of columns?.toArray()" class="left" [class.expand]="header.expand">
                    <ng-container *ngTemplateOutlet="header.template"></ng-container>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="
                    let item of items
                        | paginate
                            : {
                                  itemsPerPage: itemsPerPage,
                                  currentPage: currentPage,
                                  totalItems: totalItems
                              };
                    index as i;
                    trackBy: trackByFn
                "
            >
                <td *ngIf="isRowSelectedFn" class="align-middle">
                    <input type="checkbox" clrCheckbox [checked]="isRowSelectedFn(item)" (change)="rowSelectChange.emit(item)" />
                </td>
                <ng-container *ngTemplateOutlet="rowTemplate; context: { item: item, index: i }"></ng-container>
            </tr>
        </tbody>
    </table>
    <div class="table-footer">
        <fbr-items-per-page-controls *ngIf="totalItems" [itemsPerPage]="itemsPerPage" (itemsPerPageChange)="itemsPerPageChange.emit($event)"></fbr-items-per-page-controls>
        <fbr-pagination-controls *ngIf="totalItems" [currentPage]="currentPage" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" (pageChange)="pageChange.emit($event)"></fbr-pagination-controls>
    </div>
</ng-container>

<ng-template #emptyPlaceholder>
    <fbr-empty-placeholder [emptyStateLabel]="emptyStateLabel"></fbr-empty-placeholder>
</ng-template>
