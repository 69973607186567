import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { MeResponse } from '../models/MeResponse'

export class OAuthDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieves the user information from oauth/me
     */
    getMe(): Observable<MeResponse> {
        return this.http.get<MeResponse>(`${this.environment.authHost}/oauth/me`)
    }
}
