import { Component, Input, TemplateRef, ViewChild } from '@angular/core'

@Component({
    selector: 'fbr-dt-column',
    template: `
        <ng-template><ng-content></ng-content></ng-template>
    `
})
export class DataTableColumnComponent {
    /**
     * When set to true, this column will expand to use avaiable width
     */
    @Input() expand = false
    @ViewChild(TemplateRef, { static: true }) template: TemplateRef<any>
}
