<fbr-dropdown *ngIf="entity.id">
    <button class="btn btn-icon btn-link info-button" [class.btn-sm]="small" fbrDropdownTrigger>
        <clr-icon shape="info-standard"></clr-icon>
    </button>
    <fbr-dropdown-menu>
        <div class="entity-info">
            <fbr-labeled-data [label]="'common.ID' | translate">
                {{ entity.id }}
            </fbr-labeled-data>
            <fbr-labeled-data *ngIf="entity.createdAt" [label]="'common.created-at' | translate">
                {{ entity.createdAt | date: 'medium' }}
            </fbr-labeled-data>
            <fbr-labeled-data *ngIf="entity.updatedAt" [label]="'common.updated-at' | translate">
                {{ entity.updatedAt | date: 'medium' }}
            </fbr-labeled-data>
        </div>
    </fbr-dropdown-menu>
</fbr-dropdown>
