<ng-container *ngIf="existingImage?.url && !replacing; else uploadControl">
    <div class="card">
        <div class="card-block" *ngIf="title && description">
            <h4 class="card-title" *ngIf="title">{{ title }}</h4>
            <p class="card-text" *ngIf="description">{{ description }}</p>
        </div>
        <div class="card-img">
            <img [src]="existingImage?.url" />
        </div>
        <div class="card-footer">
            <div class="btn-row mt-0">
                <button class="btn btn-outline-warning" (click)="replace()">
                    <clr-icon shape="sync"></clr-icon>
                    Replace
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #uploadControl>
    <form class="fbr-file pt-0">
        <div class="card">
            <div class="card-block" *ngIf="title && description">
                <h4 class="card-title" *ngIf="title">{{ title }}</h4>
                <p class="card-text" *ngIf="description">{{ description }}</p>
            </div>
            <ul class="list-group">
                <li class="list-group-item" [ngClass]="{ warning: dirty && !sizeValid }" *ngIf="maxMb">
                    Maximum file size:
                    <b>{{ maxMb }}Mb</b>
                </li>
                <li class="list-group-item" [ngClass]="{ warning: dirty && dimensionErrors && dimensionErrors.x }" *ngIf="dimensionsRules">
                    Minimum image width:
                    <b>{{ dimensionsRules.x }} pixels</b>
                </li>
                <li class="list-group-item" [ngClass]="{ warning: dirty && dimensionErrors && dimensionErrors.y }" *ngIf="dimensionsRules">
                    Minimum image height:
                    <b>{{ dimensionsRules.y }} pixels</b>
                </li>
                <li class="list-group-item" [ngClass]="{ warning: dirty && dimensionErrors && dimensionErrors.a }" *ngIf="dimensionsRules">
                    Image Aspect Ratio:
                    <b>{{ dimensionsRules.x }}/{{ dimensionsRules.y }}</b>
                </li>
                <li class="list-group-item" *ngIf="!uploaded.length">
                    <input type="file" [formControl]="control" [accept]="accept" (change)="onChange($event)" />
                </li>
                <li class="list-group-item" *ngFor="let file of uploaded">
                    {{ file.originalFileName }}
                </li>
            </ul>
            <div class="card-footer">
                <div class="progress">
                    <progress [value]="progress | async" max="100"></progress>
                </div>

                <div class="btn-row mt-0">
                    <button class="btn btn-outline-warning" (click)="reset()" *ngIf="control.value || uploaded.length">
                        <clr-icon shape="sync"></clr-icon>
                        Reset
                    </button>

                    <button class="btn btn-primary" (click)="upload()" [clrLoading]="state" [disabled]="!control.value || uploaded.length || !valid">
                        <clr-icon shape="upload-cloud"></clr-icon>
                        Upload{{ uploaded.length ? 'ed' : '' }}
                    </button>
                </div>
            </div>

            <div class="card-img" *ngIf="image">
                <img #preview [src]="image.attachmentValue" [alt]="image.originalFileName" />
            </div>
        </div>
    </form>
</ng-template>
