import { Component, EventEmitter, Input, Output } from '@angular/core'
import { LanguageCode } from '@fabrik/common'

@Component({
    selector: 'fbr-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
    @Input() availableLanguages: LanguageCode[] = []
    @Output() logOut = new EventEmitter<void>()
    @Output() selectUiLanguage = new EventEmitter<void>()
    @Input() uiLanguage: LanguageCode
    @Input() userName = ''
}
