import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { Subscription } from 'rxjs/internal/Subscription'

import { Tenant } from '../../data/models/MeResponse'
import { DataService } from '../../data/providers/data.service'

@Component({
    selector: 'fbr-tenant-switcher',
    templateUrl: './tenant-switcher.component.html',
    styleUrls: ['./tenant-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSwitcherComponent implements OnInit, OnDestroy {
    currentTenant: Tenant | null
    tenants: Tenant[] = []
    defaultTenantImage: string = 'assets/community_icon-300x300.png'
    tenantLogo: string = ''
    isDemoMode: boolean
    tenantName: string
    private demoModeSubscription: Subscription

    constructor(private dataService: DataService, private router: Router, private changeDetector: ChangeDetectorRef) {
        this.isDemoMode = this.dataService.auth.demoMode
    }

    ngOnInit() {
        const property = 'name'
        this.tenants = this.dataService.auth.tenants.sort((a, b) => a[property].localeCompare(b[property]))
        this.currentTenant = this.dataService.auth.tenant
        this.setTenantLogo()

        if (this.currentTenant === null) {
            this.router.navigate(['/misc', 'no-tenant'])
        }

        this.demoModeSubscription = this.dataService.auth.demoModeChange$.subscribe((isDemoMode: boolean) => {
            this.isDemoMode = isDemoMode
            this.setTenantLogo()
        })
    }

    setTenantLogo() {
        this.tenantLogo = this.currentTenant?.logo && !this.isDemoMode ? this.currentTenant.logo : this.defaultTenantImage
        this.changeDetector.detectChanges()
    }

    switchTenant(tenantId: string) {
        if (tenantId !== this.currentTenant?.id) {
            this.router.navigateByUrl(`/authenticate/${tenantId}?source=${encodeURIComponent(this.router.url)}`)
        }
    }

    ngOnDestroy() {
        this.demoModeSubscription?.unsubscribe()
    }
}
