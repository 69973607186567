import { Observable, PartialObserver, Subscription } from 'rxjs'
import { toSubscriber } from 'rxjs/internal-compatibility'

import { OnDestroy } from '@angular/core'

export interface ISubscriptionTracker {
    subscribe<T>(observable: Observable<T>, observerOrNext?: PartialObserver<T> | ((value: T) => void), error?: (error: any) => void, complete?: () => void): Subscription
    unsubscribe(subscription: Subscription): Subscription
    unsubscribeAll(): void
}

export class SubscriptionTracker implements ISubscriptionTracker {
    private subscriptions: Subscription[] = []

    constructor(destroyable: OnDestroy) {
        const originalOnDestroy = destroyable.ngOnDestroy

        destroyable.ngOnDestroy = () => {
            this.unsubscribeAll()
            originalOnDestroy.call(destroyable)
        }
    }

    subscribe<T>(observable: Observable<T>, observerOrNext?: PartialObserver<T> | ((value: T) => void), error?: (error: any) => void, complete?: () => void): Subscription {
        const subscription = observable.subscribe(toSubscriber(observerOrNext, error, complete))
        this.subscriptions.push(subscription)
        return subscription
    }

    unsubscribe(subscription: Subscription): Subscription {
        subscription.unsubscribe()
        const indexOfSubscription = this.subscriptions.indexOf(subscription)

        if (indexOfSubscription === -1) {
            throw new Error('Unsubscribing to untracked subscription')
        }

        this.subscriptions.splice(indexOfSubscription, 1)
        return subscription
    }

    unsubscribeAll(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
        this.subscriptions = []
    }
}
