import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { MembersReport, UpdateMembersReport, MembersReportCategories } from '../models/MembersReport'

export class MembersReportDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * List reported members
     *
     * @param tenantId id of the current tenant
     * @param sortField member field to sort
     * @param sortDirection ascending or descending
     * @param resolutionStatus current state of the reported member
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */
    list(tenantId: string, args: { sortField?: string; sortDirection?: string; resolutionStatus?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<MembersReport>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                case 'resolutionStatus':
                    switch (args.resolutionStatus) {
                        case 'Pending':
                            params = params.set('resolutionStatus', 'Pending')
                            break

                        case 'Removed':
                            params = params.set('resolutionStatus', 'removed')
                            break

                        case 'NoAction':
                            params = params.set('resolutionStatus', 'noAction')
                            break

                        default:
                            if (utils.notNullOrUndefined(args.resolutionStatus)) {
                                params = params.set(key, args.resolutionStatus)
                            }
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<MembersReport>>(`${this.environment.appApiHost}/api/${tenantId}/admin/reports/member`, { params })
    }

    /**
     * List reports categories
     *
     * @param tenantId id of the current tenant
     * @param sortField category field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */
    listCategories(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<MembersReportCategories>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<MembersReportCategories>>(`${this.environment.appApiHost}/api/${tenantId}/admin/reportcategories`, { params })
    }

    /**
     * Retrieve reported member by id
     *
     * @param tenantId id of the current tenant
     * @param reportId id of the report to retrieve
     */
    byId(tenantId: string, reportId: string): Observable<MembersReport> {
        return this.http.get<MembersReport>(`${this.environment.appApiHost}/api/${tenantId}/admin/reports/member/${reportId}`)
    }

    /**
     * Update a report
     *
     * @param tenantId id of the current tenant
     * @param data report object
     */
    updateReport(tenantId: string, data: UpdateMembersReport): Observable<UpdateMembersReport> {
        return this.http.put<UpdateMembersReport>(`${this.environment.appApiHost}/api/${tenantId}/admin/reports/member`, data)
    }

    /**
     * Update a category
     *
     * @param tenantId id of the current tenant
     * @param data report object
     */
    updateCategory(tenantId: string, data: MembersReportCategories): Observable<MembersReportCategories> {
        return this.http.put<MembersReportCategories>(`${this.environment.appApiHost}/api/${tenantId}/admin/reportcategories`, data)
    }

    /**
     * Create a category
     *
     * @param tenantId id of the current tenant
     * @param data category object
     */
    createCategory(tenantId: string, data: MembersReportCategories): Observable<MembersReportCategories> {
        return this.http.post<MembersReportCategories>(`${this.environment.appApiHost}/api/${tenantId}/admin/reportcategories`, data)
    }
}
