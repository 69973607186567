import { saveAs } from 'file-saver'
import { Parser } from 'json2csv'

import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'fbr-metrics-data-download-button',
    templateUrl: './data-download-button.component.html',
    styleUrls: ['./data-download-button.component.scss']
})
export class DataDownloadButtonComponent implements OnInit {
    @Input('data') data: any
    @Input('filename') filename?: string
    @Input('startDate') startDate?: Date
    @Input('endDate') endDate?: Date

    constructor() {}

    ngOnInit(): void {}

    download() {
        this.downloadDataAsCsv(this.data, this.filename, this.startDate, this.endDate)
    }

    downloadDataAsCsv(data: any, filename: string = 'data', startDate: Date | null = null, endDate: Date | null = null) {
        const parser = new Parser()
        const csv = parser.parse(data)
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
        const name = filename + (startDate ? '_' + new Date(startDate).toISOString() : '') + (endDate ? '_' + new Date(endDate).toISOString() : '')

        saveAs(blob, `${name}.csv`)
    }
}
