import { Observable, Subject } from 'rxjs'
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators'

import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { DataService } from '../data/providers/data.service'
import { DeactivateAware } from './deactivate-aware'

export abstract class BaseDetailComponent<Entity extends { id: string; updatedAt?: string }> implements DeactivateAware {
    entity$: Observable<Entity>
    id: string
    isNew$: Observable<boolean>
    abstract detailForm: FormGroup
    protected destroy$ = new Subject<void>()

    protected constructor(protected route: ActivatedRoute, protected router: Router, protected dataService: DataService) {}

    init() {
        this.entity$ = this.route.data.pipe(
            switchMap(data => (data.entity as Observable<Entity>).pipe(takeUntil(this.destroy$))),
            tap(entity => (this.id = entity.id)),
            shareReplay(1)
        )

        this.isNew$ = this.entity$.pipe(
            map(entity => entity.id === ''),
            shareReplay(1)
        )

        this.entity$.pipe(takeUntil(this.destroy$)).subscribe(entity => {
            this.setFormValues(entity)
            this.detailForm.markAsPristine()
        })
    }

    destroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }

    canDeactivate(): boolean {
        return this.detailForm && this.detailForm.pristine
    }

    protected abstract setFormValues(entity: Entity): void

    protected setQueryParam(key: string, value: any) {
        this.router.navigate(
            [
                './',
                {
                    ...this.route.snapshot.params,
                    [key]: value
                }
            ],
            {
                relativeTo: this.route,
                queryParamsHandling: 'merge'
            }
        )
    }
}
