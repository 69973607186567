import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { LikeDislikeMetadata } from '../models/LikeDislike'

export class LikeDislikeDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    checkWidgetFeedback(tenantId, widgetId): Observable<any> {
        return this.http.get<any>(`${this.environment.appApiHost}/api/${tenantId}/ratings/${widgetId}`)
    }

    postWidgetFeedback(tenantId, payload: LikeDislikeMetadata): Observable<LikeDislikeMetadata> {
        return this.http.post<LikeDislikeMetadata>(`${this.environment.appApiHost}/api/${tenantId}/ratings`, payload)
    }
}
