import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { ExploreAxis } from '../models/Metrics'
import { TimeUtils } from '../utils/time-utils'

export class MetricsDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    metrics(tenantId: string, metricName: string, bucketSize: number = 1, startDate: Date, endDate: Date): Observable<ExploreAxis[]> {
        return this.http.get<ExploreAxis[]>(
            `${this.environment.metricsApiHost}/metrics/${tenantId}?metricName=${metricName}&bucketSize=${bucketSize}&startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`
        )
    }

    countMetric(tenantId: string, metricName: string, startDate: Date | null = null, endDate: Date | null = null): Observable<number> {
        let dateRangeParams = ''

        if (startDate && endDate) {
            dateRangeParams += `&startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`
        }

        return this.http.get<number>(`${this.environment.metricsApiHost}/metrics/${tenantId}/count?metricName=${metricName}${dateRangeParams}`)
    }
}
