<fbr-dropdown>
    <button class="btn btn-link btn-sm details-button" fbrDropdownTrigger>
        <clr-icon shape="details" size="12"></clr-icon>
        {{ 'common.details' | translate }}
    </button>
    <fbr-dropdown-menu>
        <div class="entry-dropdown">
            <ng-content></ng-content>
        </div>
    </fbr-dropdown-menu>
</fbr-dropdown>
