import { Observable, of } from 'rxjs'

import { Injectable } from '@angular/core'

import { MeResponse } from '../../data/models/MeResponse'
import { DataService } from '../../data/providers/data.service'

/**
 * This service handles logic relating to authentication of the current user.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private dataService: DataService) {}

    /**
     * Checks the app state to see if the user is already logged in,
     * and if not, attempts to validate any auth token found.
     */
    checkAuthenticatedStatus(): Observable<boolean> {
        return of(this.dataService.auth.authenticated)
    }

    /**
     * Returns the endpoint to redirect to to authenticate with fabrik
     * @param tenantId
     */
    getAuthEndpoint(tenantId: string = '0', returnTo: string | null = null): string {
        return this.dataService.auth.getAuthEndpoint(tenantId, returnTo)
    }

    getToken(): string | null {
        return this.dataService.auth.token
    }

    /**
     * Returns the id of the current tenant
     */
    tenantId(): string | null {
        return this.dataService.auth.tenantId
    }

    getMe(): Observable<MeResponse> {
        const persistedMe = this.dataService.auth.me

        if (persistedMe && this.dataService.auth.authenticated) {
            return of(persistedMe)
        } else {
            return this.dataService.oauth.getMe()
        }
    }

    /**
     * To be implemented
     */
    logIn(username: string, password: string, rememberMe: boolean): Observable<boolean> {
        return of(false)
    }

    /**
     * Logs the current user out
     */
    logOut() {
        this.dataService.auth.logOut()
    }

    /**
     * Saved the oauth/me response
     * @param payload The payload to save
     */
    saveMe(payload: MeResponse) {
        this.dataService.auth.saveMe(payload)
    }

    /**
     * Saves the bearer JWT
     * @param token The bearer token
     */
    saveToken(token: string, tenantId: string = '0'): void {
        this.dataService.auth.saveToken(token, tenantId)
    }

    /**
     * Saves the bearer JWT expiry time
     * @param tokenExpiresIn The amount of seconds until the token expires
     */
    saveTokenExpiry(tokenExpiresIn: number): void {
        this.dataService.auth.saveTokenExpiry(tokenExpiresIn)
    }
}
