import { Injectable, Injector } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class InjectorService {
    private static injector: Injector | null = null

    static setInjector(injector: Injector) {
        InjectorService.injector = injector
    }

    static getInjector(): Injector | null {
        return InjectorService.injector
    }
}
