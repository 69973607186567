import { CampaignInventoryPurposes, DeviceTarget, MemberTarget, TargetOperation, TargetOperationGroup } from '../enums/smart-inventory.enum'

export interface SmartCampaignInventory extends SmartInventory, CampaignInventory {
    warnings: string[]
}

export interface InventoryMeta {
    id: string
    url: string
    metaType: InventoryMetaTypes
}

export enum InventoryMetaTypes {
    FullScreenImage,
    SquareImage,
    AudioSegment,
    SnapChatSegment,
    VideoSegment,
    InlineImageSegment
}

export interface SmartInventory extends CreateSmartInventory {
    fromDate?: Date
    toDate?: Date
    id: string
    createdAt?: string
    updatedAt?: string
    smart?: boolean
    secondaryUrl: string
    secondaryUrlContents: string
}

export interface CreateSmartInventory {
    smartInventoryMeta: InventoryMeta[]
    smartInventoryTypeId?: string
    smartInventoryType: SmartInventoryType
    name: string
    order: number
    text: string
    url?: string
    chatKeyword?: string
    primaryColorHex: string
    secondaryColorHex: string
    tertiaryColorHex: string
    apiUrl?: string
    referenceData: string
    type?: string
    addedToCampaign?: boolean
    competitionHTML: string
    callToAction: string
    Order: any
    hasVideo: boolean
    hasAudio: boolean
    outputToChat: boolean
    hasThirdPartyText: boolean
    cancelBtnText: string
    completionText: string
    formTitle: string
    submitBtnText: string
    submitErrorString: string
    blockviaThirdPartyText: boolean
}

export interface CampaignInventory extends SmartInventory {
    startDate: string
    endDate: string
    purpose: keyof typeof CampaignInventoryPurposes
    budget: number
    amount: number
    advertiserCharge: number
    enable: boolean
    deviceTargetAudience: DeviceTarget
    targetAudience: MemberTarget
    appVersionTarget: string
    appVersionTargetOperation: TargetOperation.All
    smartInventoryId: string
    targetChatGroupIds: any
    smartTargetProfileFields: [
        {
            id: string
            operator: keyof typeof TargetOperationGroup
            profileFieldOperations: [
                {
                    id: string
                    profileFieldId: string
                    profileFieldName: string
                    operator: keyof typeof TargetOperation
                    constantValue: string
                }
            ]
        }
    ]
}

export interface SmartInventoryType {
    id: string
    description: string
    createdAt?: Date
}

export interface InventoryUploadFile {
    attachmentType: number
    attachmentValue: string
    contentType: string
    hlsAttachmentValue: string | null
    id: string
    originalFileName: string
    thumbnail: string | null
}
