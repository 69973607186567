import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { Campaign, CampaignPost, Advertiser, Attachment, LiveStreams, Stream } from '../models/Advertiser'

export class CampaignDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve valid advertisers for the specified tenant
     *
     * @param tenantId The id of the tenant
     */
    advertisers(tenantId: string): Observable<PaginatedList<Advertiser>> {
        return this.http.get<PaginatedList<Advertiser>>(`${this.environment.adCMSApiHost}/api/${tenantId}/Advertiser/all`)
    }

    /**
     * Upload attachment.
     *
     * @param tenantId id of the current tenant
     * @param payload advertiser to create
     */
    attachment(tenantId: string, file: FormData): Observable<Attachment> {
        return this.http.post<Attachment>(`${this.environment.adCMSApiHost}/api/${tenantId}/advert/attachment`, file)
    }

    /**
     * Retrieve a Campaign by id.
     *
     * @param tenantId id of the current tenant
     * @param advertId id of the advert to retrieve
     */
    byId(tenantId: string, advertId: string): Observable<Campaign> {
        return this.http.get<Campaign>(`${this.environment.adCMSApiHost}/api/${tenantId}/advert/${advertId}`)
    }

    /**
     * Create a campaign.
     *
     * @param tenantId id of the current tenant
     * @param payload advertiser to create
     */
    create(tenantId: string, payload: CampaignPost): Observable<CampaignPost> {
        return this.http.post<CampaignPost>(`${this.environment.adCMSApiHost}/api/${tenantId}/advert`, payload)
    }

    /**
     * List of Campaigns for a tenant
     *
     * @param tenantId id of the current tenant
     * @param page paginated page index
     * @param limit max number of records to return
     */
    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Campaign>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Campaign>>(`${this.environment.adCMSApiHost}/api/${tenantId}/Advert/all`, { params })
    }

    /**
     * Gets a list of livestreams for a tenant
     *
     * @param tenantId
     */
    livestreams(tenantId: string): Observable<LiveStreams> {
        return this.http.get<LiveStreams>(`${this.environment.appApiHost}/api/${tenantId}/live?api-version=1.9`)
    }

    /**
     *
     * @param tenantId id of the current tenant
     * @param advertId id of advert
     * @param isActive new status of this campaign
     */
    toggleStatus(tenantId: string, advertId: string, isActive: boolean): Observable<Campaign> {
        return this.http.put<Campaign>(`${this.environment.adCMSApiHost}/api/${tenantId}/Advert/${advertId}/activate`, {})
    }

    toggleStatusOfMultiple(tenantId: string, advertIds: any, isActive: boolean): Observable<Campaign[]> {
        return this.http.post<Campaign[]>(`${this.environment.adCMSApiHost}/api/${tenantId}/Advert/activate?active=${isActive}`, advertIds)
    }
    /**
     * Create a campaign.
     *
     * @param tenantId id of the current tenant
     * @param payload advertiser to create
     */
    update(tenantId: string, payload: CampaignPost): Observable<CampaignPost> {
        return this.http.put<CampaignPost>(`${this.environment.adCMSApiHost}/api/${tenantId}/advert`, payload)
    }
}
