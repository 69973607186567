import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { SmartAdvertisers } from '../models/SmartAdvertisers'

export class SmartAdvertisersDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Create an advertiser.
     *
     * @param tenantId id of the current tenant
     * @param payload advertiser to create
     */
    create(tenantId: string, payload: any): Observable<SmartAdvertisers> {
        return this.http.post<SmartAdvertisers>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartadvertisers`, payload)
    }

    /**
     * List of Advertsers for a tenant
     *
     * @param tenantId id of the current tenant
     * @param page paginated page index
     * @param limit max number of records to return
     */
    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any; includeGlobal?: boolean } = {}): Observable<PaginatedList<SmartAdvertisers>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<SmartAdvertisers>>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartadvertisers`, { params })
    }

    /**
     * Update an Advertiser
     *
     * @param tenantId id of the current tenant
     * @param advertiserId advertiser to update
     * @param payload advertiser to update
     */
    update(tenantId: string, advertiserId: string, payload: any): Observable<SmartAdvertisers> {
        return this.http.put<SmartAdvertisers>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartadvertisers/${advertiserId}`, payload)
    }

    /**
     * Delete an Advertiser
     *
     * @param tenantId id of the current tenant
     * @param advertiserId advertiser to delete
     */
    delete(tenantId: string, advertiserId: string): Observable<SmartAdvertisers> {
        return this.http.delete<SmartAdvertisers>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartadvertisers/${advertiserId}`)
    }
}
