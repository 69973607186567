import { Injectable, Injector } from '@angular/core'
import { LanguageCode } from '@fabrik/common'

export function initializeServerConfigService(serverConfigService: ServerConfigService): () => Promise<any> {
    return serverConfigService.init()
}

/**
 * A service which fetches the config from the server upon initialization, and then provides that config
 * to the components which require it.
 */
@Injectable()
export class ServerConfigService {
    private _serverConfig: any = {} as any

    constructor(private injector: Injector) {}

    /**
     * Fetches the ServerConfig. Should be run as part of the app bootstrap process by attaching
     * to the Angular APP_INITIALIZER token.
     */
    init(): () => Promise<any> {
        return () => this.getServerConfig()
    }

    getServerConfig() {
        return new Promise(resolve => {
            resolve()
        })
    }

    getAvailableLanguages() {
        return LanguageCode.en
    }

    refreshGlobalSettings() {
        return new Promise(resolve => {
            resolve()
        })
    }

    get serverConfig(): any {
        return this._serverConfig
    }
}
