import { ChangeDetectorRef, Component, OnInit } from '@angular/core'

import { LoadingService } from './data/providers/loading.service'
import { MatomoInjector, MatomoTracker } from 'ngx-matomo'
import { Router, NavigationEnd } from '@angular/router'
import { Environment } from '@fabrik/common'

@Component({
    selector: 'fbr-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    loading: boolean = false

    constructor(cdRef: ChangeDetectorRef, private environment: Environment, private loadingService: LoadingService, private matomoInjector: MatomoInjector, private matomoTracker: MatomoTracker, private router: Router) {
        this.loadingService.loading$.subscribe(loading => {
            this.loading = loading
            cdRef.detectChanges()
        })

        // Inject Matomo script with Matomo ID
        if (environment.matomoId !== 0) {
            this.matomoInjector.init('//matomo.fabrik.fm/', environment.matomoId)

            this.router.events.subscribe(ev => {
                if (ev instanceof NavigationEnd) {
                    this.matomoTracker.trackPageView()
                }
            })
        }
    }

    ngOnInit() {}
}
