import { Observable } from 'rxjs'
import { finalize, tap } from 'rxjs/operators'

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { Environment } from '@fabrik/common'

import { AuthService } from '../../providers/auth/auth.service'
import { NotificationService } from '../../providers/notification/notification.service'
import { LoadingService } from './loading.service'
import HttpStatusCode from '../../shared/enums/HttpStatusCode'

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
    activeRequests: number = 0

    constructor(private authService: AuthService, private environment: Environment, private injector: Injector, private loadingService: LoadingService) {}

    private errorSpool: Record<string | number, NodeJS.Timeout> = {}

    debounceErrorNotifications(response: HttpResponse<any> | HttpErrorResponse, customMessage?: string) {
        const timer = this.errorSpool[response.status]
        if (response instanceof HttpErrorResponse) {
            if (timer) {
                // Supress error
            } else {
                this.displayErrorNotification(this.extractErrorFromHttpResponse(response) + customMessage, response.status)
                this.errorSpool[response.status] = setTimeout(() => {
                    clearTimeout(this.errorSpool[response.status])
                    delete this.errorSpool[response.status]
                }, 0.5 * 1000 * 60)
            }
        }
    }

    showErrorNotification(response: HttpResponse<any> | HttpErrorResponse, customMessage?: string) {
        this.debounceErrorNotifications(response, customMessage)
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.activeRequests === 0) {
            this.loadingService.startLoading()
        }

        if (req.url.indexOf('i18n-messages') > 0) {
            return next.handle(req)
        } else {
            this.activeRequests++

            return next.handle(req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.authService.getToken()) })).pipe(
                tap(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.notifyOnError(event)
                        }
                    },
                    err => {
                        this.handleError(err)
                    }
                ),
                finalize(() => {
                    this.activeRequests--

                    if (this.activeRequests === 0) {
                        this.loadingService.stopLoading()
                    }
                })
            )
        }
    }

    handleError(response: HttpResponse<any> | HttpErrorResponse) {
        switch (response.status) {
            case HttpStatusCode.BAD_REQUEST:
                // Form validation message
                this.showErrorNotification(response, ' Invalid data provided')
                break
            case HttpStatusCode.FORBIDDEN:
                //Ideally redirect to a page that informs the user
                break
            case HttpStatusCode.UNAUTHORIZED:
                // Redirect to log out
                // Redirect to a 401 page
                this.authService.logOut()
                break
            case HttpStatusCode.GATEWAY_TIMEOUT:
                // Notify user of timeout, suggest refresh
                this.showErrorNotification(response, ' Kindly refresh page')
                break
            case HttpStatusCode.INTERNAL_SERVER_ERROR:
                // Unhandled error on the server
                // Something went wrong on the server
                this.showErrorNotification(response, ' Request currently unavailable, Please try again later ')
                break
            case HttpStatusCode.FABRIK_COULDNT_CONNECT:
            case HttpStatusCode.SERVICE_UNAVAILABLE:
                // The server is offline
                this.showErrorNotification(response, ' Request currently unavailable, Please try again later ')
                break
            case HttpStatusCode.NOT_FOUND:
                // Suppress, never shown to the user
                this.showErrorNotification(response, ' Data was not found')
                break
        }
    }

    private notifyOnError(response: HttpResponse<any> | HttpErrorResponse) {
        if (response instanceof HttpErrorResponse) {
            if (response.status === 0) {
                this.displayErrorNotification(_(`error.could-not-connect-to-server`), response.status, {
                    url: this.environment.appApiHost
                })
            } else if (response.status !== 400) {
                this.displayErrorNotification(this.extractErrorFromHttpResponse(response), response.status)
            }
        }
    }

    private extractErrorFromHttpResponse(response: HttpErrorResponse): string {
        const errors = response.error.errors

        if (Array.isArray(errors)) {
            return errors.map(e => e.message).join('\n')
        } else {
            return response.statusText || response.message
        }
    }

    /**
     * We need to lazily inject the NotificationService since it depends on the I18nService which
     * eventually depends on the HttpClient (used to load messages from json files). If we were to
     * directly inject NotificationService into the constructor, we get a cyclic dependency.
     */
    private displayErrorNotification(message: string, errorStatus: number, vars?: Record<string, any>): void {
        const notificationService = this.injector.get<NotificationService>(NotificationService)

        switch (errorStatus) {
            case HttpStatusCode.BAD_REQUEST:
                notificationService.error(message, vars)
                break
            case HttpStatusCode.NOT_FOUND:
            case HttpStatusCode.FORBIDDEN:
            case HttpStatusCode.GATEWAY_TIMEOUT:
            case HttpStatusCode.INTERNAL_SERVER_ERROR:
            case HttpStatusCode.FABRIK_COULDNT_CONNECT:
            case HttpStatusCode.SERVICE_UNAVAILABLE:
                notificationService.warning(message, vars)
                break
            default:
                notificationService.error(message, vars)
        }
    }
}
