import { Pipe, PipeTransform } from '@angular/core'

/**
 * Formats array duplicate entries
 */
@Pipe({
    name: 'filterUnique',
    pure: false
})
export class FilterUniquePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        // Remove the duplicate elements
        let uniqueArray = value.filter(function (el, index, array) {
            return array.indexOf(el) == index
        })

        return uniqueArray
    }
}
