import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { DataService } from '../../../data/providers/data.service'
import { LikeDislikeMetadata } from '../../../data/models/LikeDislike'

/**
 * The like, dislike feature.
 */
@Component({
    selector: 'fbr-like-dislike',
    templateUrl: './like-dislike.component.html',
    styleUrls: ['./like-dislike.component.scss']
})
export class LikeDislikeComponent implements OnInit {
    @Input() locationId: string
    @Input() widgetName: string
    @Input() isVisible: boolean = false
    tenantId: string

    constructor(private dataService: DataService, private cdRef: ChangeDetectorRef) {
        this.tenantId = this.dataService.auth.tenantId
    }

    ngOnInit() {
        this.checkFeedbackWidgets()
    }

    async checkFeedbackWidgets() {
        try {
            await this.dataService.likeDislike.checkWidgetFeedback(this.tenantId, this.locationId).subscribe(
                resp => {
                    this.isVisible = false
                    this.cdRef.detectChanges()
                },
                err => {
                    if (err) {
                        this.isVisible = true
                        this.cdRef.detectChanges()
                    }
                },
                () => {
                    this.isVisible = false
                    this.cdRef.detectChanges()
                }
            )
        } catch (e) {
            console.log('Api Request failed')
        }
    }

    featureFeedback(feedback: string) {
        const payload: LikeDislikeMetadata = {
            widgetId: this.locationId,
            featureName: this.widgetName,
            isPositive: false,
            comment: '',
            scale: 0
        }

        if (feedback == '👎') {
            payload.isPositive = false
        } else {
            payload.isPositive = true
        }
        this.dataService.likeDislike.postWidgetFeedback(this.tenantId, payload).subscribe(
            data => {
                this.checkFeedbackWidgets()
            },
            () => {}
        )
    }
}
