import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'

import { DataService } from './providers/data.service'
import { FetchAdapter } from './providers/fetch-adapter'
import { DefaultInterceptor } from './providers/interceptor'
import { initializeServerConfigService, ServerConfigService } from './server-config'

@NgModule({
    imports: [HttpClientModule],
    exports: [],
    declarations: [],
    providers: [
        DataService,
        FetchAdapter,
        ServerConfigService,
        { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: initializeServerConfigService,
            deps: [ServerConfigService]
        }
    ]
})
export class DataModule {}
