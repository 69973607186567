import { Subject } from 'rxjs'

import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    loading$: Subject<boolean> = new Subject()

    constructor() {}

    startLoading() {
        this.loading$.next(true)
    }

    stopLoading() {
        this.loading$.next(false)
    }
}
