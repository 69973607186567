<nav class="sidenav" [clr-nav-level]="2">
    <section class="sidenav-content">
        <ng-container *ngFor="let section of filteredNavMenuItems">
            <section class="nav-group" [attr.data-section-id]="section.id" [class.collapsible]="section.collapsible">
                <ng-container *ngIf="navBuilderService.sectionBadges[section.id] | async as sectionBadge">
                    <div *ngIf="sectionBadge !== 'none'" class="status-badge" [class]="sectionBadge"></div>
                </ng-container>
                <input [id]="section.id" [attr.name]="section.id" type="checkbox" [checked]="section.collapsedByDefault" />
                <label [for]="section.id">{{ section.label | translate }}</label>
                <ul class="nav-list">
                    <ng-container *ngFor="let item of section.items">
                        <li *ngIf="showIfRadioTenant(item)">
                            <a class="nav-link" [attr.data-item-id]="section.id" [routerLink]="getRouterLink(item)" routerLinkActive="active">
                                <ng-container *ngIf="item.statusBadge | async as itemBadge">
                                    <div *ngIf="itemBadge.type !== 'none'" class="status-badge" [class]="itemBadge.type"></div>
                                </ng-container>
                                <clr-icon [attr.shape]="item.icon || 'block'" size="20"></clr-icon>
                                {{ item.label | translate }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </section>
        </ng-container>

        <div class="feedback-container">
            <a [routerLink]="['./settings/how-to']">
                <label class="feedback-lbl" style="margin-right: 0px">How To Guides</label>
                <clr-icon shape="help" size="20" style="color: white"></clr-icon>
            </a>
        </div>

        <div class="demo-content" *ngIf="isIdentityMember">
            <div class="clickable" (click)="toggleDemoMode()" title="{{ isDemoMode ? 'Switch Off' : 'Switch On' }}">
                <clr-icon shape="flask" solid size="20"></clr-icon>
                Demo Mode: {{ isDemoMode ? 'On' : 'Off' }}
            </div>
        </div>

        <div class="feedback-container" *ngIf="me">
            <a [routerLink]="['./']" [queryParams]="{ 'trust-page': true }">
                <label class="feedback-lbl" style="margin-right: 0px">Trust Centre</label>
                <clr-icon shape="help-info" size="20" style="color: white"></clr-icon>
            </a>
        </div>

        <div class="feedback-container" *ngIf="me" style="margin-top: 10px">
            <a [routerLink]="['./']" [queryParams]="{ 'feedback-form': true }">
                <label class="feedback-lbl">Share your Feedback</label>
                <clr-icon shape="chat-bubble" size="20" style="color: white"></clr-icon>
            </a>
        </div>
    </section>
</nav>
