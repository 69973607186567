<ng-template fbrDialogTitle>{{ title | translate }}</ng-template>
<div class="alert alert-warning mb-3" role="alert" *ngIf="alertMessage">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
            </div>
            <span class="alert-text">
                {{ alertMessage | translate }}
            </span>
        </div>
    </div>
</div>
{{ body | translate }}
<ng-template fbrDialogButtons>
    <ng-container *ngFor="let button of buttons">
        <button class="btn" [class.btn-primary]="button.type === 'primary'" [class.btn-danger]="button.type === 'danger'" (click)="resolveWith(button.returnValue)">
            {{ button.label | translate }}
        </button>
    </ng-container>
</ng-template>
