export enum BookingStatus {
    Preview = 0,
    Draft,
    Pending,
    Confirmed,
    'Awaiting Asset',
    Accepted,
    Active,
    Completed,
    Cancelled,
    Rejected
}

export enum BookingAction {
    Save = 0,
    Submit,
    Approve,
    RequestAsset,
    Accept,
    Activate,
    Complete,
    Cancel,
    Reject
}

export enum DisplayOrders {
    Random = 0,
    Rank
}
