import { LOCATION_INITIALIZED } from '@angular/common'
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Environment } from '@fabrik/common'
import { AppComponent, AppComponentModule } from '@fabrik/web/core'
import { MatomoModule } from 'ngx-matomo'
import { environment } from '../environments/environment'

import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { routes } from './app.routes'

@NgModule({
    declarations: [],
    imports: [
        AppComponentModule,
        MatomoModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [Injector],
            multi: true
        },
        { provide: Environment, useValue: environment }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function appInitializerFactory(injector: Injector) {
    return async () => {
        await injector.get(LOCATION_INITIALIZED, Promise.resolve(null)).then(() => new Promise(resolve => setTimeout(resolve, 100)))
    }
}
