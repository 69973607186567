const VerticalProps = {
    clientHeight: 'clientHeight',
    offsetHeight: 'offsetHeight',
    scrollHeight: 'scrollHeight',
    pageYOffset: 'pageYOffset',
    offsetTop: 'offsetTop',
    scrollTop: 'scrollTop',
    top: 'top'
}

const HorizontalProps = {
    clientHeight: 'clientWidth',
    offsetHeight: 'offsetWidth',
    scrollHeight: 'scrollWidth',
    pageYOffset: 'pageXOffset',
    offsetTop: 'offsetLeft',
    scrollTop: 'scrollLeft',
    top: 'left'
}

export class AxisResolver {
    private propsMap: any

    constructor(private vertical: boolean = true) {
        this.propsMap = vertical ? VerticalProps : HorizontalProps
    }

    clientHeightKey() {
        return this.propsMap.clientHeight
    }

    offsetHeightKey() {
        return this.propsMap.offsetHeight
    }

    scrollHeightKey() {
        return this.propsMap.scrollHeight
    }

    pageYOffsetKey() {
        return this.propsMap.pageYOffset
    }

    offsetTopKey() {
        return this.propsMap.offsetTop
    }

    scrollTopKey() {
        return this.propsMap.scrollTop
    }

    topKey() {
        return this.propsMap.top
    }
}
