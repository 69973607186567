import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { ExploreAxis } from '../models/Metrics'
import { TimeUtils } from '../utils/time-utils'

export class RegistrationsDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    registrations(tenantId: string, startDate: Date, endDate: Date): Observable<ExploreAxis[]> {
        return this.http.get<ExploreAxis[]>(`${this.environment.metricsApiHost}/registrations/${tenantId}?startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`)
    }

    count(tenantId: string, startDate: Date | null = null, endDate: Date | null = null): Observable<number> {
        let dateRangeParams = ''

        if (startDate && endDate) {
            dateRangeParams += `?startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`
        }

        return this.http.get<number>(`${this.environment.metricsApiHost}/registrations/${tenantId}/count${dateRangeParams}`)
    }
}
