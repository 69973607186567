import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { Report, UpdateReport, ReportCategories } from '../models/Report'

export class ReportDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * List reports
     *
     * @param tenantId id of the current tenant
     * @param sortField member field to sort
     * @param sortDirection ascending or descending
     * @param resolutionStatus current state of the report
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */
    list(tenantId: string, args: { sortField?: string; sortDirection?: string; resolutionStatus?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Report>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                case 'resolutionStatus':
                    switch (args.resolutionStatus) {
                        case 'Pending':
                            params = params.set('resolutionStatus', 'Pending')
                            break

                        case 'Removed':
                            params = params.set('resolutionStatus', 'removed')
                            break

                        case 'NoAction':
                            params = params.set('resolutionStatus', 'noAction')
                            break

                        case 'Purged':
                            params = params.set('resolutionStatus', 'purged')
                            break

                        case 'PurgeIncChildren':
                            params = params.set('resolutionStatus', 'PurgedIncChildren')
                            break

                        default:
                            if (utils.notNullOrUndefined(args.resolutionStatus)) {
                                params = params.set(key, args.resolutionStatus)
                            }
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Report>>(`${this.environment.appApiHost}/api/${tenantId}/admin/reports`, { params })
    }

    /**
     * List reports categories
     *
     * @param tenantId id of the current tenant
     * @param sortField category field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */
    listCategories(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<ReportCategories>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<ReportCategories>>(`${this.environment.appApiHost}/api/${tenantId}/admin/reportcategories`, { params })
    }

    /**
     * Retrieve a report by id
     *
     * @param tenantId id of the current tenant
     * @param reportId id of the report to retrieve
     */
    byId(tenantId: string, reportId: string): Observable<Report> {
        return this.http.get<Report>(`${this.environment.appApiHost}/api/${tenantId}/admin/reports/${reportId}`)
    }

    /**
     * Update a report
     *
     * @param tenantId id of the current tenant
     * @param data report object
     */
    updateReport(tenantId: string, data: UpdateReport): Observable<UpdateReport> {
        return this.http.put<UpdateReport>(`${this.environment.appApiHost}/api/${tenantId}/admin/reports`, data)
    }

    /**
     * Update a category
     *
     * @param tenantId id of the current tenant
     * @param data report object
     */
    updateCategory(tenantId: string, data: ReportCategories): Observable<ReportCategories> {
        return this.http.put<ReportCategories>(`${this.environment.appApiHost}/api/${tenantId}/admin/reportcategories`, data)
    }

    /**
     * Create a category
     *
     * @param tenantId id of the current tenant
     * @param data category object
     */
    createCategory(tenantId: string, data: ReportCategories): Observable<ReportCategories> {
        return this.http.post<ReportCategories>(`${this.environment.appApiHost}/api/${tenantId}/admin/reportcategories`, data)
    }
}
