import { MESSAGE_FORMAT_CONFIG, MessageFormatConfig } from 'ngx-translate-messageformat-compiler'

import { PlatformLocation } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Injector, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Environment, LanguageCode } from '@fabrik/common'
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core'

import { AppShellComponent } from './components/app-shell/app-shell.component'
import { AuthAppShellComponent } from './components/app-shell/auth-app-shell.component'
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component'
import { MainNavComponent } from './components/main-nav/main-nav.component'
import { NotificationComponent } from './components/notification/notification.component'
import { OverlayHostComponent } from './components/overlay-host/overlay-host.component'
import { TenantSwitcherComponent } from './components/tentant-switcher/tenant-switcher.component'
import { UiLanguageSwitcherDialogComponent } from './components/ui-language-switcher-dialog/ui-language-switcher-dialog.component'
import { UserMenuComponent } from './components/user-menu/user-menu.component'
import { DataModule } from './data/data.module'
import { CustomHttpTranslationLoader } from './providers/i18n/custom-http-loader'
import { InjectableTranslateMessageFormatCompiler } from './providers/i18n/custom-message-format-compiler'
import { lowcase, upcase, upfirst } from './providers/i18n/i18n.formatters'
import { I18nService } from './providers/i18n/i18n.service'
import { InjectorService } from './providers/injector.service'
import { LocalStorageService } from './providers/local-storage/local-storage.service'
import { SharedModule } from './shared/shared.module'
import { TrustPageComponent } from './components/trust-page/trust-page.component'

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        DataModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, PlatformLocation]
            },
            compiler: { provide: TranslateCompiler, useClass: InjectableTranslateMessageFormatCompiler }
        })
    ],
    providers: [
        {
            provide: MESSAGE_FORMAT_CONFIG,
            useFactory: getLocales,
            deps: [Environment],
            useValue: { formatters: { lowcase, upcase, upfirst } }
        }
    ],
    exports: [SharedModule, OverlayHostComponent],
    declarations: [
        AppShellComponent,
        AuthAppShellComponent,
        UserMenuComponent,
        MainNavComponent,
        BreadcrumbComponent,
        OverlayHostComponent,
        NotificationComponent,
        TenantSwitcherComponent,
        UiLanguageSwitcherDialogComponent,
        FeedbackFormComponent,
        TrustPageComponent
    ]
})
export class CoreModule {
    constructor(private environment: Environment, private i18nService: I18nService, private injector: Injector, private localStorageService: LocalStorageService) {
        InjectorService.setInjector(injector)
        this.initUiLanguages()
    }

    private initUiLanguages() {
        const defaultLanguage = this.environment.defaultLanguage as LanguageCode
        const lastLanguage = this.localStorageService.get('uiLanguageCode')
        const availableLanguages = this.environment.availableLanguages.map(language => language as LanguageCode)

        if (!availableLanguages.includes(defaultLanguage)) {
            throw new Error(`The defaultLanguage "${defaultLanguage}" must be one of the availableLanguages [${availableLanguages.map(l => `"${l}"`).join(', ')}]`)
        }

        const uiLanguage = availableLanguages.includes(lastLanguage) ? lastLanguage : defaultLanguage

        this.localStorageService.set('uiLanguageCode', uiLanguage)

        this.i18nService.setLanguage(uiLanguage)
        this.i18nService.setDefaultLanguage(defaultLanguage)
        this.i18nService.setAvailableLanguages(availableLanguages || [defaultLanguage])
    }
}

export function HttpLoaderFactory(http: HttpClient, location: PlatformLocation) {
    // dynamically get the baseHref, which is configured in the angular.json file
    const baseHref = location.getBaseHrefFromDOM()
    return new CustomHttpTranslationLoader(http, baseHref + 'i18n-messages/')
}

/**
 * Returns the locales defined in environment config, ensuring that the
 * default language is the first item in the array as per the messageformat
 * documentation:
 *
 * > The default locale will be the first entry of the array
 * https://messageformat.github.io/messageformat/MessageFormat
 */
export function getLocales(environment: Environment): MessageFormatConfig {
    const locales = environment.availableLanguages
    const defaultLanguage = environment.defaultLanguage
    const localesWithoutDefault = locales.filter(l => l !== defaultLanguage)

    return {
        locales: [defaultLanguage, ...localesWithoutDefault]
    }
}
