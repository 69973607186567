import { Component, Input } from '@angular/core'

import { ComponentRenderer } from '../interfaces/component-renderer.interface'
import { ColumnComponentRendererSpec, ColumnRendererSpec } from '../interfaces/datagrid-column.interface'

export interface BoolIconRendererConfig {
    value: boolean
}

@Component({
    selector: 'fbr-icon-renderer',
    template: `
        <clr-icon [attr.shape]="config.value ? 'check' : 'times'" [class]="config.value ? 'true' : 'false'"></clr-icon>
    `,
    styles: ['.true { color: #308300; }', '.false { color: #c92100; }']
})
export class BoolIconRendererComponent implements ComponentRenderer<BoolIconRendererConfig> {
    @Input()
    config: BoolIconRendererConfig
}

/**
 * Creates a {@link ColumnRendererSpec} for rendering an icon in a column.
 */
export function BoolIconRenderer<R>(propertyName: string): ColumnRendererSpec<R, BoolIconRendererConfig> {
    return ColumnComponentRendererSpec({
        type: BoolIconRendererComponent,
        config(record): BoolIconRendererConfig {
            return {
                value: (record as R)[propertyName] as boolean
            }
        }
    })
}
