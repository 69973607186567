import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { Feed, FeedUrls } from '../models/Feed'

export class FeedDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Create competition
     *
     * @param tenantId id of the current tenant
     * @param competitionUrl Competition Url
     */
    createSecondaryFeed(tenantId: string, competitionUrl: string): Observable<Feed> {
        return this.http.post<Feed>(`${this.environment.appApiHost}/api/${tenantId}/admin/competitions?competitionUrl=${competitionUrl}`, { competitionUrl })
    }

    /**
     * Create news
     *
     * @param tenantId id of the current tenant
     * @param newsUrl News Url
     */
    createPrimaryFeed(tenantId: string, payload: FeedUrls): Observable<FeedUrls> {
        return this.http.post<FeedUrls>(`${this.environment.appApiHost}/api/${tenantId}/admin/news`, payload)
    }

    /**
     * Retrieve a news item by id.
     *
     * @param tenantId id of the current tenant
     * @param newUrlId id of the live stream item to retrieve
     */
    byId(tenantId: string, newUrlId: string): Observable<FeedUrls> {
        return this.http.get<FeedUrls>(`${this.environment.appApiHost}/api/${tenantId}/admin/news/newsImportDescriptor/${newUrlId}`)
    }

    /**
     * Update news url
     *
     * @param tenantId id of the current tenant
     * @param newsUrl News Url
     */
    updatePrimaryFeed(tenantId: string, payload: FeedUrls): Observable<FeedUrls> {
        return this.http.put<FeedUrls>(`${this.environment.appApiHost}/api/${tenantId}/admin/news/${payload.id}`, payload)
    }

    /**
     * Delete
     *
     * @param type feed type to list (competitions or news)
     * @param tenantId id of the current tenant
     * @param ids ids of the items to delete
     */
    delete(type: 'competitions' | 'news', tenantId: string, ids: string[]): Observable<Feed> {
        return this.http.request<Feed>('delete', `${this.environment.appApiHost}/api/${tenantId}/admin/${type}/delete`, { body: ids })
    }

    /**
     * Delete feed url
     *
     * @param tenantId id of the current tenant
     * @param id ids of the items to delete
     */
    deleteUrl(tenantId: string, id: string): Observable<Feed> {
        return this.http.delete<Feed>(`${this.environment.appApiHost}/api/${tenantId}/admin/news/${id}`)
    }

    /**
     * List feed items
     *
     * @param type feed type to list (competitions or news)
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(type: 'competitions' | 'news', tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Feed>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Feed>>(`${this.environment.appApiHost}/api/${tenantId}/admin/${type}`, { params })
    }

    /**
     * List primary feed articles(items)
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    listNews(tenantId: string, urlId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Feed>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Feed>>(`${this.environment.appApiHost}/api/${tenantId}/admin/news/${urlId}/Items`)
    }

    /**
     * List feed urls
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    listUrls(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<FeedUrls>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<FeedUrls>>(`${this.environment.appApiHost}/api/${tenantId}/admin/news/newsImportDescriptor`, { params })
    }

    /**
     * Make url enabled.
     *
     * @param tenantId id of the current tenant
     * @param urlId feed url id
     */
    urlStatus(tenantId: string, urlId: string, status: string): Observable<FeedUrls> {
        return this.http.post<FeedUrls>(`${this.environment.appApiHost}/api/${tenantId}/admin/news/${urlId}/${status}`, {})
    }
}
