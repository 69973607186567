import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { MixedPlayCountViewModel, MixedPlaySummaryViewModel, PaginatedViewModel, RewindCategoryPlayCountViewModel, RewindPlayCountViewModel, RewindPlaySummaryViewModel } from '../models/Metrics'

import { TimeUtils } from '../utils/time-utils'

export class RewindDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    plays(tenantId: string, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date, page: number): Observable<PaginatedViewModel<RewindPlayCountViewModel>> {
        let params: { [param: string]: string | string[] } = {
            dimensionName,
            dimensionValue,
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString(),
            page: page.toString()
        }

        return this.http.get<PaginatedViewModel<RewindPlayCountViewModel>>(`${this.environment.metricsApiHost}/rewind/${tenantId}`, { params })
    }

    playsByCategory(tenantId: string, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<RewindCategoryPlayCountViewModel[]> {
        let params: { [param: string]: string | string[] } = {
            dimensionName,
            dimensionValue,
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<RewindCategoryPlayCountViewModel[]>(`${this.environment.metricsApiHost}/rewind/${tenantId}/playsbycategory`, { params })
    }

    summary(tenantId: string, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<RewindPlaySummaryViewModel> {
        let params: { [param: string]: string | string[] } = {
            dimensionName,
            dimensionValue,
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<RewindPlaySummaryViewModel>(`${this.environment.metricsApiHost}/rewind/${tenantId}/summary`, { params })
    }

    playsMixed(tenantId: string, startDate: Date, endDate: Date, page: number): Observable<PaginatedViewModel<MixedPlayCountViewModel>> {
        let params: { [param: string]: string | string[] } = {
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString(),
            page: page.toString()
        }

        return this.http.get<PaginatedViewModel<MixedPlayCountViewModel>>(`${this.environment.metricsApiHost}/mixed/${tenantId}`, { params })
    }

    summaryMixed(tenantId: string, startDate: Date, endDate: Date): Observable<MixedPlaySummaryViewModel> {
        let params: { [param: string]: string | string[] } = {
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<MixedPlaySummaryViewModel>(`${this.environment.metricsApiHost}/mixed/${tenantId}/summary`, { params })
    }

    getPodcast(tenantId: string) {
        return this.http.get(`${this.environment.appApiHost}/api/${tenantId}/rewind?api-version=1.20.0`)
    }   
}
