<div
    class="wrapper"
    [class.with-background]="!invert && colorFrom"
    [style.backgroundColor]="!invert && (colorFrom | stringToColor)"
    [style.color]="invert && (colorFrom | stringToColor)"
    [style.borderColor]="invert && (colorFrom | stringToColor)"
    [ngClass]="className || colorType"
>
    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>

    <div class="chip-label" *ngIf="!tooltip">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

    <clr-tooltip *ngIf="tooltip">
        <div class="chip-label" clrTooltipTrigger>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
        <clr-tooltip-content clrSize="md" *clrIfOpen>
            <span>{{ tooltip }}</span>
        </clr-tooltip-content>
    </clr-tooltip>

    <div class="chip-icon" *ngIf="icon">
        <button (click)="iconClick.emit($event)">
            <clr-icon [attr.shape]="icon" [style.color]="invert && (colorFrom | stringToColor)" [class.is-inverse]="!invert && colorFrom"></clr-icon>
        </button>
    </div>
</div>
