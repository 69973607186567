import { BehaviorSubject, Observable, Subject, zip } from 'rxjs'
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators'

import { Directive, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Environment, PaginatedList } from '@fabrik/common'

import { LoadingService } from '../data/providers/loading.service'
import { InjectorService } from '../providers/injector.service'
import { GridDataFetchResult, GridSelectionType, GridState, PaginationConfiguration } from '../shared/components/data-grid/data-grid.component'

export type ListQueryFn<T> = (args: any) => Observable<PaginatedList<T>>
export type MappingFn<T> = (result: PaginatedList<T>) => PaginatedList<T>

@Directive()
export class BaseListComponent<ItemType> implements OnInit, OnDestroy {
    environment: Environment | undefined
    loadingService: LoadingService | undefined

    gridData: GridDataFetchResult<ItemType>
    items$: Observable<ItemType[]>
    paginationInfo: PaginationConfiguration
    result$: Observable<PaginatedList<ItemType>>
    selectionType: GridSelectionType = GridSelectionType.None

    protected destroy$ = new Subject<void>()
    private listQuery: Observable<PaginatedList<ItemType>>
    private listQueryFn: ListQueryFn<ItemType>
    loading: boolean = false
    private mappingFn: MappingFn<ItemType>
    protected ready: boolean = false
    private refresh$ = new BehaviorSubject<any>(undefined)
    private useQuerystring = true

    // pagination observables
    currentPage$: Observable<number>
    itemsPerPage$: Observable<number>
    sortField$: Observable<string | undefined>
    sortDirection$: Observable<string | undefined>
    totalCount$: Observable<number>

    // pagination locals (used when not in querystring mode)
    private _currentPage: number = 1
    private _itemsPerPage: number = 25
    private _sortField: string
    private _sortDirection: string

    constructor(protected router: Router, protected route: ActivatedRoute) {
        const injector = InjectorService.getInjector()

        this.environment = injector?.get(Environment)
        this.loadingService = injector?.get(LoadingService)

        if (this.loadingService) {
            this.loadingService.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
                setTimeout(() => {
                    this.loading = loading
                })
            })
        }

        this.paginationInfo = {
            pageSize: this.environment?.defaultPageSize || 25,
            pageSizeOptions: this.environment?.defaultPageSizeOptions || [25, 50, 75, 100],
            shouldShowPageNumberInput: false,
            shouldShowPageSizeSelector: true
        }
    }

    setQueryFn(listQueryFn: ListQueryFn<ItemType>, mappingFn: MappingFn<ItemType> = data => data) {
        this.listQueryFn = listQueryFn
        this.mappingFn = mappingFn
    }

    ngOnInit(useQuerystring: boolean = true, queryResultParser?: Function) {
        if (!this.listQueryFn) {
            throw new Error(`No listQueryFn has been defined. Please call super.setQueryFn() in the constructor.`)
        }

        this.listQuery = this.refresh$.pipe(
            switchMap(params =>
                this.listQueryFn({ page: params?.currentPage || 1, limit: params?.itemsPerPage || this.environment?.defaultPageSize || 25, sortField: params?.sortField, sortDirection: params?.sortDirection })
            ),
            tap(data => {
                if (data) {
                    if (queryResultParser) {
                        this.gridData = queryResultParser(data)
                    } else {
                        this.gridData = { items: data.result, totalItems: data.totalCount }
                    }
                }
            })
        )

        // pagination driven using querystring params?
        this.useQuerystring = useQuerystring

        this.result$ = this.listQuery.pipe(shareReplay(1))
        this.items$ = this.result$.pipe(map(data => this.mappingFn(data).result))
        this.totalCount$ = this.result$.pipe(map(data => this.mappingFn(data).totalCount))

        if (this.useQuerystring) {
            this.currentPage$ = this.route.queryParamMap.pipe(
                map(qpm => qpm.get('page')),
                map(page => (!page ? 1 : +page))
            )

            this.itemsPerPage$ = this.route.queryParamMap.pipe(
                map(qpm => qpm.get('limit')),
                map(perPage => (!perPage ? this.environment?.defaultPageSize || 25 : +perPage))
            )

            this.sortDirection$ = this.route.queryParamMap.pipe(
                map(qpm => qpm.get('sortDirection')),
                map(sortDirection => sortDirection || undefined)
            )

            this.sortField$ = this.route.queryParamMap.pipe(
                map(qpm => qpm.get('sortField')),
                map(sortField => sortField || undefined)
            )

            zip(this.currentPage$, this.itemsPerPage$, this.sortField$, this.sortDirection$)
                .pipe(takeUntil(this.destroy$))
                .subscribe(([currentPage, itemsPerPage, sortField, sortDirection]: [number, number, string | undefined, string | undefined]) => this.refresh$.next({ currentPage, itemsPerPage, sortField, sortDirection }))
        }

        this.listQuery.subscribe(() => (this.ready = true))
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }

    onGridRefresh(eventData: GridState<ItemType> | null): void {
        if (this.ready) {
            if (eventData && eventData.sortColumn) {
                this.setSort(eventData.sortColumn.name, eventData.sortColumn.reverse ? 'Descending' : 'Ascending')
            } else if (eventData && eventData.pagination) {
                const { pageNumber, itemsPerPage } = eventData.pagination
                this.setPagination(pageNumber, itemsPerPage)
            } else {
                this.refresh()
            }
        }
    }

    refresh() {
        this.refresh$.next(undefined)
    }

    private _refresh() {
        this.refresh$.next({ currentPage: this._currentPage, itemsPerPage: this._itemsPerPage, sortField: this._sortField, sortDirection: this._sortDirection })
    }

    setLimit(limit: number) {
        if (this.useQuerystring) {
            this.setQueryParam('limit', limit)
        } else {
            this._itemsPerPage = limit
            this._refresh()
        }
    }

    setPage(page: number) {
        if (this.useQuerystring) {
            this.setQueryParam('page', page)
        } else {
            this._currentPage = page
            this._refresh()
        }
    }

    setPagination(page: number, limit: number) {
        if (this.useQuerystring) {
            this.setQueryParam({ page, limit })
        } else {
            this._currentPage = page
            this._itemsPerPage = limit
            this._refresh()
        }
    }

    setSort(sortField: string, sortDirection: string) {
        if (this.useQuerystring) {
            this.setQueryParam({ sortField, sortDirection })
        } else {
            this._sortField = sortField
            this._sortDirection = sortDirection
            this._refresh()
        }
    }

    protected setQueryParam(hash: { [key: string]: any })
    protected setQueryParam(key: string, value: any)
    protected setQueryParam(keyOrHash: string | { [key: string]: any }, value?: any) {
        this.router.navigate(['./'], {
            queryParams: typeof keyOrHash === 'string' ? { [keyOrHash]: value } : keyOrHash,
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        })
    }
}
