import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http'
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ClrLoadingState } from '@clr/angular'
import { BehaviorSubject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { InventoryMetaTypes, InventoryUploadFile } from '../../../data/models/SmartInventory'
import { DataService } from '../../../data/providers/data.service'
import { NotificationService } from '../../../providers/notification/notification.service'

@Component({
    selector: 'fbr-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit {
    @Input('title') title?: string
    @Input('description') description?: string
    @Input('existingImage') existingImage?: {
        id: string
        url: string
    }
    @Input('maxMb') maxMb?: number
    @Input('metaType') metaType?: InventoryMetaTypes
    @Input('dimensions') dimensionsRules?: { x: number; y: number; a: number }
    @Input('accept') accept?: string
    @Output('upload') uploadEmitter = new EventEmitter<InventoryUploadFile[]>()

    @ViewChild('preview') private preview: ElementRef

    private tenantId: string

    private file?: File

    public state = ClrLoadingState.DEFAULT

    public formData = new FormData()

    public control = new FormControl()

    public uploaded = new Array<InventoryUploadFile>()

    public progress = new BehaviorSubject(0)

    private localImage = new Image()

    public replacing = false

    public get dirty() {
        return this.control.dirty
    }

    public get max() {
        return this.maxMb ? this.maxMb * 1000000 : undefined
    }

    public get image() {
        if (this.uploaded.length) {
            const file = this.uploaded[0]
            if (file.contentType.includes('image/')) {
                return file
            }
        }

        return undefined
    }

    public get dimensionErrors():
        | {
              x?: boolean
              y?: boolean
              a?: boolean
          }
        | undefined {
        let errors = {}

        if (this.dimensionsRules) {
            if (this.dimensionsRules.x) {
                let x = this.dimensions.x > this.dimensionsRules.x

                if (x) {
                    errors = {
                        ...errors,
                        x
                    }
                }
            }

            if (this.dimensionsRules.y) {
                let y = this.dimensions.y > this.dimensionsRules.y

                if (y) {
                    errors = {
                        ...errors,
                        y
                    }
                }
            }

            if (this.dimensionsRules.a) {
                let a = this.dimensions.a !== this.dimensionsRules.a

                if (a) {
                    errors = {
                        ...errors,
                        a
                    }
                }
            }

            return errors
        }
    }

    public get sizeValid() {
        if (this.max && this.file) {
            if (this.file.size > this.max) {
                return false
            }
        }

        return true
    }

    public get dimensionsValid() {
        if (this.control.dirty) {
            if (this.dimensionErrors && Object.keys(this.dimensionErrors).length) {
                return false
            }
        }

        return true
    }

    public get valid() {
        return this.sizeValid && this.dimensionsValid
    }

    public get previewImage() {
        try {
            return this.preview.nativeElement as HTMLImageElement
        } catch {
            return this.localImage
        }
    }

    public get dimensions() {
        let a = this.previewImage.naturalWidth / this.previewImage.naturalHeight

        return {
            x: this.previewImage.naturalWidth,
            y: this.previewImage.naturalHeight,
            a: isNaN(a) ? 0 : a
        }
    }

    constructor(private dataService: DataService, private notificationService: NotificationService, private changeDetector: ChangeDetectorRef) {
        this.tenantId = this.dataService.auth.tenantId
    }

    ngOnInit(): void {}

    onChange(event: Event) {
        const target = event.target as HTMLInputElement
        const files = target.files
        const file = files?.item(0) // Handles one file only
        this.update(file)
        this.changeDetector.detectChanges()
    }

    onError(error: unknown) {
        this.reset()
        console.error(error)
        this.notificationService.error('There was a problem uploading this file')
    }

    update(file: File | undefined | null) {
        if (file) {
            this.setLocalImage(file)
            this.file = file
            this.formData.append('file', file)
            setTimeout(() => {
                this.changeDetector.detectChanges()
            }, 10)
        }
        this.changeDetector.detectChanges()
    }

    reset() {
        this.formData = new FormData()
        this.control.reset()
        this.uploaded = []
        this.file = undefined
        this.replacing = false
        this.progress.next(0)
        this.uploadEmitter.emit(undefined)
    }

    replace() {
        this.replacing = true
    }

    getProgress(event: HttpProgressEvent, total?: number) {
        total = total || event.total || Infinity
        return (event.loaded / total) * 100 - 10
    }

    complete(uploadedFile: InventoryUploadFile | null) {
        if (uploadedFile) {
            uploadedFile.attachmentType = this.metaType || InventoryMetaTypes.FullScreenImage
            this.uploaded.push(uploadedFile)
            this.progress.next(100)
            this.control.reset()
            this.notificationService.success('File uploaded')
            this.uploadEmitter.emit(this.uploaded)
            this.state = ClrLoadingState.SUCCESS
        }
    }

    upload() {
        this.state = ClrLoadingState.LOADING

        this.dataService.smartCampaign
            .uploadFileWithProgress(this.tenantId, this.formData)
            .pipe(
                filter((httpEvent: HttpEvent<InventoryUploadFile>) => {
                    if (httpEvent.type === HttpEventType.UploadProgress) {
                        this.progress.next(this.getProgress(httpEvent))
                        return false
                    }

                    return httpEvent.type === HttpEventType.Response
                }),
                map(httpEvent => {
                    return httpEvent as HttpResponse<InventoryUploadFile>
                })
            )
            .subscribe(
                response => this.complete(response.body),
                error => this.onError(error)
            )
    }

    setLocalImage(file: File) {
        const url = URL.createObjectURL(file)
        this.localImage = new Image()
        this.localImage.addEventListener('load', event => {
            // Validate
        })
        this.localImage.src = url
        this.changeDetector.detectChanges()
    }
}
