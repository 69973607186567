import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { Observable } from 'rxjs'

import { Acquisitions, AcquisitionsSummaries } from '../models/Metrics'
import { TimeUtils } from '../utils/time-utils'

export class AcquisitionsDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    acquisitionsSummary(tenantId: string, startDate: Date, endDate: Date): Observable<AcquisitionsSummaries> {
        return this.http.get<AcquisitionsSummaries>(`${this.environment.metricsApiHost}/acquisitions/${tenantId}/summary?startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`)
    }

    acquisitions(tenantId: string, startDate: Date, endDate: Date): Observable<Acquisitions> {
        return this.http.get<Acquisitions>(`${this.environment.metricsApiHost}/acquisitions/${tenantId}?startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`)
    }
}
