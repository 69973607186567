import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http'
import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { FormControl,Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { ClrLoadingState } from '@clr/angular'
import { BehaviorSubject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import CryptoJS from 'crypto-js'
import { BlobServiceClient } from '@azure/storage-blob'
import { ContentUploadFile } from '../../../data/models/ContentFeed'
import { DataService } from '../../../data/providers/data.service'
import { NotificationService } from '../../../providers/notification/notification.service'

@Component({
    selector: 'fbr-file-upload-modal',
    templateUrl: './file-upload-modal.component.html',
    styleUrls: ['./file-upload-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentFileUploadComponent implements OnInit {
    @Input('title') title?: string
    @Input('existingImage') existingImage?: string
    @Input('description') description?: string
    @Input('maxKb') maxKb?: number
    @Input('imageShow') imageShow?: boolean
    @Input('uploadContent') uploadContent?: boolean
    @Input('accept') accept?: string
    @Input('sendBlob') sendBlob?: boolean
    @Output('upload') uploadEmitter = new EventEmitter<ContentUploadFile[]>()
    @Input('isRequired') isRequired?: boolean

    private tenantId: string

    private fileInfo: any

    private file?: File

    public state = ClrLoadingState.DEFAULT

    public formData = new FormData()

    public control = new FormControl()

    public uploaded = new Array<ContentUploadFile>()

    public progress = new BehaviorSubject(0)

    public failed: boolean = false

    public hash: string

    private localImage = new Image()

    public replacing = false

    public get dirty() {
        return this.control.dirty
    }

    public get max() {
        return this.maxKb ? this.maxKb * 1000 : undefined
    }    

    public get image() {
        if (this.uploaded.length) {
            const file = this.uploaded[0]
            if (file.mimeType.includes('image/')) {
                return file
            }
        }

        return undefined
    }

    public get sizeValid() {
        if (this.max && this.file) {
            if (this.file.size > this.max) {
                return false
            }
        }

        return true
    }

    public get valid() {
        return this.sizeValid
    }

    constructor(private dataService: DataService, private notificationService: NotificationService, private changeDetector: ChangeDetectorRef, protected router: Router) {
        this.tenantId = this.dataService.auth.tenantId
    }

    ngOnInit(): void {
        this.control.markAllAsTouched();
    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement
        const files = target.files
        const file = files?.item(0) // Handles one file only
        this.update(file)
    }

    onError(error: unknown) {
        this.reset()
        console.error(error)
        this.failed = true
        this.state = ClrLoadingState.ERROR
        this.notificationService.error('There was a problem uploading this file')
    }

    update(file: File | undefined | null) {
        if (file) {
            if (!this.sendBlob) {
                this.setLocalImage(file)
                this.file = file
                this.formData.append('file', file)
                setTimeout(() => {
                    this.changeDetector.detectChanges()
                }, 10)
            } else {
                this.complete(file)
            }
        }
    }

    reset() {
        this.formData = new FormData()
        this.control.reset()
        this.uploaded = []
        this.file = undefined
        this.replacing = false
        this.progress.next(0)
        this.failed = false
        this.uploadEmitter.emit(undefined)
    }

    replace() {
        this.replacing = true
        this.existingImage = ''
    }

    getProgress(progress: number, total?: number) {
        total = total || total || Infinity
        return (progress / total) * 100 - 10
    }

    complete(uploadedFile: any | null) {
        if (uploadedFile) {
            this.uploaded.push(uploadedFile)
            this.progress.next(100)
            this.control.reset()
            this.notificationService.success('File uploaded')
            this.state = ClrLoadingState.SUCCESS
            this.uploadEmitter.emit(this.uploaded)
        } else {
            this.state = ClrLoadingState.ERROR
        }
    }

    async uploadHelper(hashedDataFile: string) {
        let finalFile = {
            hash: hashedDataFile,
            mimeType: this.file?.type,
            originalFileName: this.file?.name,
            size: this.file?.size,
            duration: 0
        }

        this.dataService.contentFeed
            .upload(this.tenantId, finalFile)
            .pipe(
                filter((httpEvent: HttpEvent<ContentUploadFile>) => {
                    return httpEvent.type === HttpEventType.Response
                }),
                map(httpEvent => {
                    return httpEvent as HttpResponse<ContentUploadFile>
                })
            )
            .subscribe(
                response => {
                    this.fileInfo = response.body
                    if (this.fileInfo) {
                        this.azureBlobBlockUpload(this.file)
                    }
                },
                error => this.onError(error)
            )
    }

    async upload() {
        this.state = ClrLoadingState.LOADING
        var hashdata = CryptoJS.algo.SHA256.create()

        if (this.file) {
            var reader = new FileReader()
            var size = this.file.size
            var chunk_size = Math.pow(2, 22)
            var chunks: any[] = []

            var offset = 0
            var bytes = 0
            reader.onloadend = async e => {
                if (reader.readyState == FileReader.DONE) {
                    //every chunk read updating hash
                    hashdata.update(this.arrayBufferToWordArray(reader.result))

                    let chunk: any = reader.result
                    bytes += chunk.length
                    chunks.push(chunk)
                    if (offset < size) {
                        offset += chunk_size
                        var blob: any = this.file?.slice(offset, offset + chunk_size)
                        reader.readAsArrayBuffer(blob)
                    } else {
                        // use below hash for result
                        // finaly generating hash
                        this.hash = hashdata.finalize().toString()
                        await this.uploadHelper(this.hash)
                    }
                }
            }
            var blob = this.file.slice(offset, offset + chunk_size)
            reader.readAsArrayBuffer(blob)
        }
    }

    async azureBlobBlockUpload(File: any) {
        await this.fileInfo
        if (!this.fileInfo.alreadyExist) {
            const blobServiceClient = new BlobServiceClient(`https://${this.fileInfo.accountName}.blob.core.windows.net?${this.fileInfo.sasToken}`)
            const containerClient = blobServiceClient.getContainerClient(this.fileInfo.containerName)

            const client = containerClient.getBlockBlobClient(this.fileInfo.blobName)
            try {
                const response = await client.uploadData(File, {
                    blockSize: 4 * 1024 * 1024, // 4MB block size
                    concurrency: 20, // 20 concurrency
                    onProgress: ev => {
                        console.log(ev)
                        this.progress.next(this.getProgress(ev.loadedBytes, File.size))
                    },
                    blobHTTPHeaders: { blobContentType: File.type }
                })
                if (response._response.status === 201 || response._response.status === 200) {
                    this.complete(this.fileInfo)
                }
            } catch {
                this.onError('File uploading failed')
            }
        } else {
            this.progress.next(this.getProgress(100, 100))
            this.complete(this.fileInfo)
        }
    }

    setLocalImage(file: File) {
        const url = URL.createObjectURL(file)
        this.localImage = new Image()
        this.localImage.addEventListener('load', event => {})
        this.localImage.src = url
    }

    arrayBufferToWordArray(fileResult) {
        return CryptoJS.lib.WordArray.create(fileResult, fileResult.length)
    }
}
