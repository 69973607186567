import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList } from '@fabrik/common'

import { SmartBookingStatus, SmartCampaignBookingRequest, SmartCampaignType, TenantInventoryType } from '../models/SmartCampaignBooking'

export class CampaignBookingRequestService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a Smart Campaign Booking Request item by id.
     *
     * @param tenantId id of the current tenant
     * @param bookingId id of the Smart Campaign Booking Request item to retrieve
     */
    byId(tenantId: string, bookingId: string): Observable<SmartCampaignBookingRequest> {
        return this.http.get<SmartCampaignBookingRequest>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartbookings/${bookingId}/?api-version=1.18`)
    }

    /**
     * Create a Smart Campaign Booking Request item
     *
     * @param tenantId id of the current tenant
     * @param data for a new Smart Campaign Booking Request object
     */
    create(tenantId: string, data: SmartCampaignBookingRequest): Observable<SmartCampaignBookingRequest> {
        return this.http.post<SmartCampaignBookingRequest>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartbookings?api-version=1.18`, data)
    }

    /**
     * Update a Smart Campaign Booking Request item
     *
     * @param tenantId id of the current tenant
     * @param data for a existing Smart Campaign Booking Request object
     * @param bookingId id of the Smart Campaign Booking Request object to update
     */
    update(tenantId: string, data: SmartCampaignBookingRequest, bookingId: string): Observable<SmartCampaignBookingRequest> {
        return this.http.put<SmartCampaignBookingRequest>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartbookings/${bookingId}?api-version=1.18`, data)
    }

    /**
     * Delete a Smart Campaign Booking Request item
     *
     * @param tenantId id of the current tenant
     * @param bookingId id of the booking item to delete
     */
    delete(tenantId: string, bookingId: string): Observable<SmartCampaignBookingRequest> {
        return this.http.delete<SmartCampaignBookingRequest>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartbookings/${bookingId}?api-version=1.18`)
    }

    /**
     * Set Status for a Smart Campaign Booking Request item
     *
     * @param tenantId id of the current tenant
     * @param bookingId id of the booking item to change status
     */
    setStatus(tenantId: string, bookingId: string, action: number): Observable<SmartBookingStatus> {
        return this.http.put<SmartBookingStatus>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartbookings/${bookingId}/status?api-version=1.18`, { action: action })
    }

    /**
     * List Smart Campaign Booking Request items
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<SmartCampaignBookingRequest>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (args.page) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (args[key]) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<SmartCampaignBookingRequest>>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartbookings?api-version=1.18`, { params })
    }

    /**
     * List Smart Campaign Booking Request Types
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    listTypes(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<SmartCampaignType>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (args.page) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (args[key]) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<SmartCampaignType>>(`${this.environment.appApiHost}/api/${tenantId}/admin/campaigntypes?api-version=1.18`, { params })
    }

    /**
     * List Tenant Enabled Inventory Types
     *
     * @param tenantId id of the current tenant
     */

    listTenantInventoryTypes(tenantId: string): Observable<PaginatedList<TenantInventoryType>> {
        return this.http.get<PaginatedList<TenantInventoryType>>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenant-inventory-types?api-version=1.18`)
    }

    /**
     * Get price calculation for a booking request
     *
     * @param tenantId id of the current tenant
     * @param data for a new Smart Campaign Booking Request object
     */

    getCampaignPrice(tenantId: string, data: SmartCampaignBookingRequest): Observable<SmartCampaignBookingRequest> {
        return this.http.post<SmartCampaignBookingRequest>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartbookings/preview?api-version=1.18`, data)
    }
}
