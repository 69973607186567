import { Observable } from 'rxjs'

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'
import { ArchiveRequest, Category, Preset, Recording } from '../models/Echocast'

export class EchocastDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * List batch listing
     * @param tenantId id of the current tenant
     */
    batch(tenantId: string, body) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/batch`, { body, headers })
    }

    /**
     * List category listing
     * @param tenantId id of the current tenant
     */

    categoryList(tenantId: string): Observable<any> {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('get', `${this.environment.echocastHost}/api/${tenantId}/category`, { headers })
    }

    /**
     * Delete recording
     * @param tenantId id of the current tenant
     * @param id id of recording
     */
    deleteRecording(tenantId: string, id: string): Observable<any> {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('delete', `${this.environment.echocastHost}/api/${tenantId}/recording/delete?recordingId=${id}`, { headers })
    }

    /**
     * Delete bulk recording/s
     * @param tenantId id of the current tenant
     * @param recordings recordings to delete based on checked
     */
    async bulkDeleteRecordings(tenantId: string, recordings: any) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        recordings.forEach(element => {
            if (element.checked) {
                return this.http.request('delete', `${this.environment.echocastHost}/api/${tenantId}/recording/delete?recordingId=${element.id}`, { headers }).subscribe()
            }
        })
    }

    /**
     * Update recording
     * @param tenantId id of the current tenant
     * @param data recording data
     */
    edit(tenantId: string, body: Recording) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('put', `${this.environment.echocastHost}/api/${tenantId}/recording/${body.id}}`, { body, headers })
    }

    /**
     * Update unpublish state
     * @param tenantId id of the current tenant
     * @param id record id
     */
    unpublish(tenantId: string, id: string) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('delete', `${this.environment.echocastHost}/api/${tenantId}/recording/publish/${id}`, { headers })
    }

    /**
     * Update publish state
     * @param tenantId id of the current tenant
     * @param id record id
     */
    publish(tenantId: string, id: string) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/publish/${id}`, { headers })
    }

    /**
     * Update recording
     * @param tenantId id of the current tenant
     * @param recordId id of recording to update
     * @param data recording data
     */
    updateRecording(tenantId: string, recordId: string, body: Recording) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('put', `${this.environment.echocastHost}/api/${tenantId}/recording/${recordId}`, { body, headers })
    }

    /**
     * Manual creation of audio podcast
     * @param tenantId id of the current tenant
     * @param data form data for posting audio
     */
    manualUpload(tenantId: string, body: FormData) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/manual`, { body, headers })
    }

    /**
     * Request audio archive
     * @param tenantId id of the current tenant
     * @param body form data for archive request
     */
    archiveRequest(tenantId: string, body: ArchiveRequest) {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/archive`, { body, headers })
    }

    /**
     * Create a Category
     *
     * @param tenantId id of the current tenant
     */
    createCategory(tenantId: string, payload: Category): Observable<Category> {
        return this.http.post<Category>(`${this.environment.echocastHost}/api/${tenantId}/category`, payload)
    }

    /**
     * Delete Category
     *
     * @param tenantId id of the current tenant
     * @param categoryId id of the Category to delete
     */
    deleteCategory(tenantId: string, categoryId: string): Observable<Category> {
        let params = new HttpParams()
        params = params.set('categoryId', categoryId)
        return this.http.delete<Category>(`${this.environment.echocastHost}/api/${tenantId}/category`, { params })
    }

    /**
     * Create a Preset
     *
     * @param tenantId id of the current tenant
     */
    create(tenantId: string, payload: Preset): Observable<Preset> {
        return this.http.post<Preset>(`${this.environment.echocastHost}/api/${tenantId}/preset`, payload)
    }

    /**
     * Delete Preset
     *
     * @param tenantId id of the current tenant
     * @param presetId id of the Preset to delete
     */
    delete(tenantId: string, presetId: string): Observable<Preset> {
        let params = new HttpParams()
        params = params.set('presetId', presetId)
        return this.http.delete<Preset>(`${this.environment.echocastHost}/api/${tenantId}/preset`, { params })
    }

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Preset>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Preset>>(`${this.environment.echocastHost}/api/${tenantId}/preset`, { params })
    }

    /**
    * Update a Preset
    
    * @param tenantId id of the current tenant
    * @param payload preset data to update
    */
    update(tenantId: string, payload: Preset): Observable<Preset> {
        return this.http.put<Preset>(`${this.environment.echocastHost}/api/${tenantId}/preset`, payload)
    }

    /**
     * Update a Category
     * @param tenantId id of the current tenant
     * @param payload category data to update
     */
    updateCategory(tenantId: string, payload: Category): Observable<Category> {
        return this.http.put<Category>(`${this.environment.echocastHost}/api/${tenantId}/category`, payload)
    }

    /**
     * Get presets
     * @param tenantId id of the current tenant
     */
    getPreset(tenantId: string): Observable<Preset> {
        return this.http.get<Preset>(`${this.environment.echocastHost}/api/${tenantId}/preset`)
    }

    /**
     * Start recording
     *
     * @param tenantId id of the current tenant
     * @param presetId selected preset id
     */
    start(tenantId: string, presetId: string): Observable<any> {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/start?presetId=${presetId}`, { headers })
    }

    /**
     * Pause recording
     *
     * @param tenantId id of the current tenant
     * @param recordingId selected preset id
     */
    pause(tenantId: string, recordingId: string): Observable<any> {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/pause/${recordingId}`, { headers })
    }

    /**
     * Resume recording
     *
     * @param tenantId id of the current tenant
     * @param recordingId selected preset id
     */
    resume(tenantId: string, recordingId: string): Observable<any> {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/resume/${recordingId}`, { headers })
    }

    /**
     * Stop recording
     *
     * @param tenantId id of the current tenant
     * @param presetId selected preset id
     */
    stop(tenantId: string, recordId: string): Observable<any> {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.1')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/stop/${recordId}`, { headers })
    }

    /**
     * Save recording
     *
     * @param tenantId id of the current tenant
     * @param id selected preset id
     * @param body payload
     */
    save(tenantId: string, id: string, body: FormData): Observable<any> {
        const headers = new HttpHeaders().set('accept', 'application/json;v=1.2')
        return this.http.request('post', `${this.environment.echocastHost}/api/${tenantId}/recording/confirm/${id}`, { headers, body })
    }
}
