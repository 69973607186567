import { Environment, PaginatedList, utils } from '@fabrik/common'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Member, MemberSummary } from '../models/Member'

import { ChatGroup } from '../models/ChatGroup'
import { Observable } from 'rxjs'
import { PanicButton } from '../models/PanicButton'

export class PanicButtonDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    getPanicButtonChatGroups(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<ChatGroup>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break
                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<ChatGroup>>(`${this.environment.appApiHost}/api/${tenantId}/admin/chatgroups`, { params })
    }

    getPanicButton(tenantId: string): Observable<PanicButton> {
        return this.http.get<PanicButton>(`${this.environment.appApiHost}/api/${tenantId}/admin/panic`)
    }

    updatePanicButton(tenantId: string, panicButton: PanicButton): Observable<PanicButton> {
        return this.http.put<PanicButton>(`${this.environment.appApiHost}/api/${tenantId}/admin/panic`, panicButton)
    }
}
