import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { YoutubeChannel, YoutubeChannelPost } from '../models/YouTubeChannel'

export class YoutubeChannelDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a Youtube Channel item by id.
     *
     * @param tenantId id of the current tenant
     * @param channelId id of the Youtube Channel item to retrieve
     */
    byId(tenantId: string, channelId: string): Observable<YoutubeChannel> {
        return this.http.get<YoutubeChannel>(`${this.environment.appApiHost}/api/${tenantId}/admin/YoutubeChannels/${channelId}`)
    }

    /**
     * Create a Youtube Channel
     *
     * @param tenantId id of the current tenant
     * @param data new Youtube Channel object
     */
    create(tenantId: string, payload: YoutubeChannelPost): Observable<YoutubeChannelPost> {
        return this.http.post<YoutubeChannelPost>(`${this.environment.appApiHost}/api/${tenantId}/admin/YoutubeChannels?`, payload)
    }

    /**
     * Delete a Youtube Channel item
     *
     * @param tenantId id of the current tenant
     * @param channelId id of the Youtube Channel item to delete
     */
    delete(tenantId: string, id: string): Observable<YoutubeChannel> {
        return this.http.delete<YoutubeChannel>(`${this.environment.appApiHost}/api/${tenantId}/admin/YoutubeChannels/${id}`)
    }

    /**
     * List Youtube Channel
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<YoutubeChannel>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<YoutubeChannel>>(`${this.environment.appApiHost}/api/${tenantId}/admin/YoutubeChannels?`, { params })
    }

    /**
     * Update a Youtube Channel
     *
     * @param tenantId id of the current tenant
     * @param channelId id of the channel to update
     * @param support stream item object
     */
    update(tenantId: string, id: string, youtubechannelData: YoutubeChannel): Observable<YoutubeChannel> {
        return this.http.put<YoutubeChannel>(`${this.environment.appApiHost}/api/${tenantId}/admin/YoutubeChannels/${id}`, youtubechannelData)
    }

    /**
     * Order youtube channels
     * @param tenantId id of the current tenant
     * @param youtubechannelData category item object
     */
    order(tenantId: string, youtubechannelData: any): Observable<YoutubeChannel> {
        return this.http.put<YoutubeChannel>(`${this.environment.appApiHost}/api/${tenantId}/admin/YoutubeChannels/order`, youtubechannelData)
    }
}
