import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { SupportCategory } from '../models/SupportCategories'

export class SupportCategoryDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a support category item by id.
     *
     * @param tenantId id of the current tenant
     * @param streamId id of the support category item to retrieve
     */
    byId(tenantId: string, streamId: string): Observable<SupportCategory> {
        return this.http.get<SupportCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantSupportCategories/${streamId}`)
    }

    /**
     * Create a support category
     *
     * @param tenantId id of the current tenant
     * @param data new live stream object
     */
    create(tenantId: string, payload: SupportCategory): Observable<SupportCategory> {
        return this.http.post<SupportCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantSupportCategories`, payload)
    }

    /**
     * Delete a support category item
     *
     * @param tenantId id of the current tenant
     * @param streamId id of the stream item to delete
     */
    delete(tenantId: string, streamId: string): Observable<SupportCategory> {
        return this.http.delete<SupportCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantSupportCategories?tenantSupportCategoryId=${streamId}`)
    }

    /**
     * List Support categories
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<SupportCategory>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<SupportCategory>>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantSupportCategories/find`, { params })
    }

    /**
     * Update a support category
     *
     * @param tenantId id of the current tenant
     * @param tenantSupportId id of the stream item to update
     * @param support stream item object
     */
    update(tenantId: string, tenantSupportId: string, supportcategoryData: SupportCategory): Observable<SupportCategory> {
        return this.http.put<SupportCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantSupportCategories?tenantSupportCategoryId=${tenantSupportId}`, supportcategoryData)
    }

    /**
     * Order support categories
     * @param tenantId id of the current tenant
     * @param supportcategoryData category item object
     */
    order(tenantId: string, supportcategoryData: any): Observable<SupportCategory> {
        return this.http.put<SupportCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenantSupportCategories/order`, supportcategoryData)
    }

    /**
     * Upload a category image
     *
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    /* uploadImage(tenantId: string, file: File): Observable<LiveStreamUploadImage> {
     return this.http.post<LiveStreamUploadImage>(`${this.environment.appApiHost}/api/${tenantId}/admin/image/livestream/upload`, file)
   }*/
}
