<fbr-dropdown>
    <button class="btn btn-link trigger" fbrDropdownTrigger>
        <span class="user-name">{{ userName }}</span>
        <clr-icon shape="user" size="24"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
    </button>
    <fbr-dropdown-menu fbrPosition="bottom-right">
        <ng-container *ngIf="1 < availableLanguages.length">
            <button type="button" fbrDropdownItem (click)="selectUiLanguage.emit()" [title]="'common.select-display-language' | translate">
                <clr-icon shape="language"></clr-icon>
                {{ 'lang.' + uiLanguage | translate }}
            </button>
            <div class="dropdown-divider"></div>
        </ng-container>
        <button type="button" fbrDropdownItem (click)="logOut.emit()">
            <clr-icon shape="logout"></clr-icon>
            {{ 'common.log-out' | translate }}
        </button>
    </fbr-dropdown-menu>
</fbr-dropdown>
