import { assign } from 'lodash'
import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment, PaginatedList } from '@fabrik/common'

import { SmashboardAnalyticsResponse } from '../models/Metrics'
import { Message, MessageQuery, Provider } from '../models/Smashboard'
import { TimeUtils } from '../utils/time-utils'

export class SmashboardDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    queryDefaults: MessageQuery = {
        anyTags: [],
        before: '',
        groupMessages: true,
        limit: 50,
        loadMessagesAtEndOfArray: true,
        matchTerm: false,
        resetMessages: false,
        tags: ''
    }

    addTag(tenantId: string, listName: string, messageId: string): Observable<any> {
        return this.http.request<any>('put', `${this.environment.smashboardAPI}/api/${tenantId}/tags/list/${listName}`, {
            body: { id: messageId },
            headers: { Accept: 'application/vnd.smashboard.api.v2.0+json, application/json' }
        })
    }

    deleteTag(tenantId: string, listName: string, messageId: string): Observable<any> {
        return this.http.request<any>('delete', `${this.environment.smashboardAPI}/api/${tenantId}/tags/list/${listName}`, {
            body: { id: messageId },
            headers: { Accept: 'application/vnd.smashboard.api.v2.0+json, application/json' }
        })
    }

    export(tenantId: string, email: string, payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment.smashboardAPI}/api/${tenantId}/messages/export?email=${encodeURIComponent(email)}`, assign({}, this.queryDefaults, payload), {
            headers: { Accept: 'application/vnd.smashboard.api.v2.0+json, application/json' }
        })
    }

    messages(tenantId: string, payload: any): Observable<PaginatedList<Message>> {
        return this.http.post<PaginatedList<Message>>(`${this.environment.smashboardAPI}/api/${tenantId}/messages`, assign({}, this.queryDefaults, payload), {
            headers: { Accept: 'application/vnd.smashboard.api.v2.0+json, application/json' }
        })
    }

    metrics(tenantId: string, startDate: Date, endDate: Date): Observable<SmashboardAnalyticsResponse> {
        return this.http.get<SmashboardAnalyticsResponse>(`${this.environment.metricsApiHost}/smashboard/${tenantId}?startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`)
    }

    providers(tenantId: string): Observable<Provider[]> {
        return this.http.get<Provider[]>(`${this.environment.smashboardAPI}/api/${tenantId}/providers?includeDisabled=false`)
    }

    reply(tenantId: string, messageId: string, text: string): Observable<any> {
        return this.http.post<any>(
            `${this.environment.smashboardAPI}/api/${tenantId}/messages/${messageId}/reply`,
            { text },
            {
                headers: { Accept: 'application/vnd.smashboard.api.v2.0+json, application/json' }
            }
        )
    }   
}
