import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { RollupPeriod } from '../enums/rollup-period.enum'
import { RollupType } from '../enums/rollup-type.enum'
import { SessionChartingDataSpan } from '../enums/session-charting-data-span.enum'
import { DimensionSummaries, HourlyBreakdown, SessionLengthCount, StreamingNumbers, Summary } from '../models/Metrics'
import { TimeUtils } from '../utils/time-utils'

export class SessionDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    chartingData(tenantId: string, timespan: SessionChartingDataSpan, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<StreamingNumbers[]> {
        let params: { [param: string]: string | string[] } = {
            tenantId,
            timespan: timespan.toString(),
            dimensionName,
            dimensionValue,
            startDate:  TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate:  TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }
        return this.http.get<StreamingNumbers[]>(`${this.environment.metricsApiHost}/sessions/${tenantId}/getsessionchartingdata`, { params })
    }

    dimensionSummaries(tenantId: string, period: RollupPeriod, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<DimensionSummaries> {
        let params: { [param: string]: string | string[] } = {
            tenantId,
            period: period.toString(),
            rollupType: RollupType.DimensionSummaries.toString(),
            dimensionName,
            dimensionValue,
            startDate:  TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate:  TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<DimensionSummaries>(`${this.environment.metricsApiHost}/sessions/${tenantId}/retrieverollup`, { params })
    }

    hourlyBreakdown(tenantId: string, period: RollupPeriod, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<HourlyBreakdown[]> {
        let params: { [param: string]: string | string[] } = {
            tenantId,
            period: period.toString(),
            rollupType: RollupType.HourlyBreakdown.toString(),
            dimensionName,
            dimensionValue,
            startDate:  TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate:  TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<HourlyBreakdown[]>(`${this.environment.metricsApiHost}/sessions/${tenantId}/retrieverollup`, { params })
    }

    sessionLengthBreakdown(tenantId: string, period: RollupPeriod, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<SessionLengthCount[]> {
        let params: { [param: string]: string | string[] } = {
            tenantId,
            period: period.toString(),
            rollupType: RollupType.SessionLengthBreakdown.toString(),
            dimensionName,
            dimensionValue,
            startDate:  TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate:  TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<SessionLengthCount[]>(`${this.environment.metricsApiHost}/sessions/${tenantId}/retrieverollup`, { params })
    }

    summary(tenantId: string, period: RollupPeriod, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<Summary> {
        let params: { [param: string]: string | string[] } = {
            tenantId,
            period: period.toString(),
            rollupType: RollupType.Summary.toString(),
            dimensionName,
            dimensionValue,
            startDate:  TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate:  TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<Summary>(`${this.environment.metricsApiHost}/sessions/${tenantId}/retrieverollup`, { params })
    }
}
