<ng-container *ngIf="!imageShow && uploadContent; else display">
    <div class="card">
        <div class="card-block" *ngIf="title">
            <h4 class="card-title" *ngIf="title">Content File Uploaded</h4>
            <ng-container *ngIf="description">
                <div class="alert-item ratio-warning static">
                    <span class="alert-text ratio-text text-center">
                        <clr-icon class="alert-icon" shape="exclamation-triangle" solid></clr-icon>
                        Content files cant be edited, we suggest you create a new content item if your wish was to upload a new content file .
                    </span>
                </div>
                <br />
            </ng-container>
        </div>
        <div class="card-footer">
            <div class="btn-row mt-0">
                <button class="btn btn-outline-warning" disabled>
                    <clr-icon shape="sync"></clr-icon>
                    Uploaded
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #display>
    <ng-container *ngIf="existingImage && !replacing && imageShow; else uploadControl">
        <div class="card">
            <div class="card-block" *ngIf="title && description">
                <h4 class="card-title" *ngIf="title">{{ title }}</h4>
                <p class="card-text" *ngIf="description">{{ description }}</p>
            </div>
            <div class="card-img">
                <img [src]="existingImage" />
            </div>
            <div class="card-footer">
                <div class="btn-row mt-0">
                    <button class="btn btn-outline-warning" (click)="replace()">
                        <clr-icon shape="sync"></clr-icon>
                        Replace
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #uploadControl>
        <form class="fbr-file pt-0">
            <div class="card">
                <div class="card-block" *ngIf="title">
                    <h4 class="card-title" *ngIf="title">{{ title }}</h4>
                    <ng-container *ngIf="description">
                        <div class="alert-item ratio-warning static">
                            <span class="alert-text ratio-text text-center">
                                <clr-icon class="alert-icon" shape="exclamation-triangle" solid></clr-icon>
                                {{ description }}
                            </span>
                        </div>
                        <br />
                    </ng-container>
                </div>
                <ul class="list-group">
                    <li class="list-group-item" [ngClass]="{ warning: dirty && !sizeValid }" *ngIf="maxKb">
                        Maximum file size:
                        <b>{{ maxKb }}Kb</b>
                    </li>
                    <li class="list-group-item" *ngIf="!uploaded.length">
                        <input type="file" [required]="isRequired"  [formControl]="control" [accept]="accept" (change)="onChange($event)"  />                       
                    </li>                   
                    
                    <li class="list-group-item" *ngFor="let file of uploaded">
                        {{ file.originalFileName }}
                    </li>
                </ul>
                <div class="card-footer">
                    <div class="progress">
                        <progress [value]="progress | async" max="100"></progress>
                    </div>

                    <div class="card-img">
                        <img [src]="existingImage" />
                    </div>

                    <div class="btn-row mt-0">
                        <button class="btn btn-outline-warning" (click)="reset()" *ngIf="control.value || uploaded.length || failed">
                            <clr-icon shape="sync"></clr-icon>
                            Reset
                        </button>

                        <button class="btn btn-outline-warning" *ngIf="failed" disabled>
                            <clr-icon shape="times"></clr-icon>
                            Failed
                        </button>

                        <button class="btn btn-primary" (click)="upload()" [clrLoading]="state" [disabled]="!control.value || uploaded.length || !sizeValid" *ngIf="!failed">
                            <clr-icon shape="upload-cloud"></clr-icon>
                            Upload{{ uploaded.length ? 'ed' : '' }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</ng-template>
