export enum ContentTypes {
    Audio = 0,
    Video = 1,
    File = 2,
    Web = 3,
    Image = 4,
    GIF = 5
}

export enum MixedContentTypes {
    Audio = 0,
    Video = 1,
    File = 2,
    Web = 3,
    Image = 4,
    GIF = 5
}

export enum DownloadEligibility {
    NotEligible = 0,
    DownloadPublic = 1,
    DownloadPrivate = 2
}

export enum StreamType {
    Youtube = 0,
    HLS = 1
}

export enum MixedFeedType {
    Public = 0,
    Registered = 1,
    Subscriber = 2
}

export enum MixedFeedStatus {
    All = '',
    Processing = 1,
    Ready = 2,
    Published = 3,
    Failed = 4
}

export enum ImportType {
    Import = 1,
    Sync = 2
}

export enum ExternalSource {
    Echocast = 1,
    Videos = 2,
    News = 3,
    MixedFeed = 4
}

export enum FeaturedConfig {
    UseSource = 0,
    Feature = 1,
    NotFeatured = 2
}

export enum MixedFeedItemBulkStatusAction {
    Publish,
    UnPublish,
    Feature,
    UnFeature,
    Delete,
    removeCategory, 
    GoLive,
    RemoveLive
}

export enum AppsTabsAspectRatio {
    Inherited,
    Aspect16x9,
    Aspect9x16,
    AspectSquare,
    Aspect10x16
}

export enum DisplayType {
    Default,
    Carousel,
    Split,
    Mosaic,
    SplitRow
}

export enum Height {
    Small,
    Medium,
    Large,
    XLarge
}

export enum MixedFeedCategoryAction {
    List,
    Detail
}
export enum LiveBroadcastStatusType{
    None,
    Upcoming,
    Live
}
