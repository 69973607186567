import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { ProfileField } from '../models/ProfileField'

export class ProfileFieldDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a profile field by id.
     *
     * @param tenantId id of the current tenant
     * @param profileFieldId id of the profile field to retrieve
     */
    byId(tenantId: string, profileFieldId: string): Observable<ProfileField> {
        return this.http.get<ProfileField>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile/${profileFieldId}`)
    }

    /**
     * Create a profile field.
     *
     * @param tenantId id of the current tenant
     * @param payload profile field data to save
     */
    create(tenantId: string, payload: ProfileField): Observable<ProfileField> {
        return this.http.post<ProfileField>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile`, payload)
    }

    /**
     * Disable a profile field
     *
     * @param tenantId id of the current tenant
     * @param profileFieldId id of the profile field to disable
     */
    disable(tenantId: string, profileFieldId: string): Observable<ProfileField> {
        return this.http.delete<ProfileField>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile/${profileFieldId}/disable`)
    }

    /**
     * Enable a profile field
     *
     * @param tenantId id of the current tenant
     * @param profileFieldId id of the profile field to enable
     */
    enable(tenantId: string, profileFieldId: string): Observable<ProfileField> {
        return this.http.post<ProfileField>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile/${profileFieldId}/enable`, {})
    }

    /**
     * Retrieve valid profile field types for the specified tenant
     *
     * @param tenantId The id of the tenant
     */
    fieldTypes(tenantId: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile/fieldtypes`).pipe(map(data => data.sort()))
    }

    /**
     * List profile fields
     *
     * @param tenantId id of the current tenant
     * @param sortField member field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */
    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<ProfileField>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<ProfileField>>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile`, { params })
    }

    /**
     * List all profile fields
     *
     * @param tenantId id of the current tenant
     */
    listAllActive(tenantId: string): Observable<ProfileField[]> {
        const params = new HttpParams()

        return this.http
            .get<PaginatedList<ProfileField>>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile`, { params })
            .pipe(map(data => (data.result ? data.result.filter(item => item.active) : [])))
    }

    /**
     * Update profile fields sort order
     *
     * @param tenantId id of the current tenant
     * @param payload profile field data to update
     */
    saveOrder(tenantId: string, profileFieldIds: string[]): Observable<ProfileField> {
        return this.http.put<ProfileField>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile/order`, profileFieldIds)
    }

    /**
     * Update a profile field.
     *
     * @param tenantId id of the current tenant
     * @param payload profile field data to update
     */
    update(tenantId: string, payload: ProfileField): Observable<ProfileField> {
        return this.http.put<ProfileField>(`${this.environment.appApiHost}/api/${tenantId}/admin/profile`, payload)
    }
}
