import { of } from 'rxjs'

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

import { DataService } from '../../data/providers/data.service'
import { IfDirectiveBase } from './if-directive-base'

@Directive({
    selector: '[fbrIfMultiTenant]'
})
export class IfMultiTenantDirective extends IfDirectiveBase<[]> {
    constructor(_viewContainer: ViewContainerRef, templateRef: TemplateRef<any>, private dataService: DataService) {
        super(_viewContainer, templateRef, () => {
            return of(this.dataService.auth.tenants.length > 1)
        })
    }

    /**
     * A template to show if the current user does not have the specified permission.
     */
    @Input()
    set fbrIfMultiTenantElse(templateRef: TemplateRef<any> | null) {
        this.setElseTemplate(templateRef)
    }
}
