import '@clr/icons'
import '@clr/icons/shapes/all-shapes'
import '@webcomponents/custom-elements/custom-elements.min.js'
import 'mediaelement'

import { LazyLoadImageModule } from 'ng-lazyload-image'
import { NgxPaginationModule } from 'ngx-pagination'
import { SortablejsModule } from 'ngx-sortablejs'

import { DragDropModule } from '@angular/cdk/drag-drop'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ClarityModule } from '@clr/angular'
import { NgSelectModule } from '@ng-select/ng-select'
import { TranslateModule } from '@ngx-translate/core'

import { ModalService } from '../providers/modal/modal.service'
import { ActionBarItemsComponent } from './components/action-bar-items/action-bar-items.component'
import { ActionBarComponent, ActionBarLeftComponent, ActionBarRightComponent } from './components/action-bar/action-bar.component'
import { AffixedInputComponent } from './components/affixed-input/affixed-input.component'
import { PercentageSuffixInputComponent } from './components/affixed-input/percentage-suffix-input.component'
import { ChipComponent } from './components/chip/chip.component'
import { CurrencyInputComponent } from './components/currency-input/currency-input.component'
import { DataGridComponent } from './components/data-grid/data-grid.component'
import { ComponentRendererOutletDirective } from './components/data-grid/directives/component-renderer-outlet.directive'
import { FunctionRendererPipe } from './components/data-grid/pipes/function-renderer.pipe'
import { NestedPropertyPipe } from './components/data-grid/pipes/nested-property.pipe'
import { AudioRenderedComponent } from './components/data-grid/renderers/audio-renderer.component'
import { BoldTextRendererComponent } from './components/data-grid/renderers/bold-text-renderer.component'
import { BoolIconRendererComponent } from './components/data-grid/renderers/bool-icon-renderer.component'
import { ImgRendererComponent } from './components/data-grid/renderers/img-renderer.component'
import { LabelRendererComponent } from './components/data-grid/renderers/label-renderer/label-renderer.component'
import { DataTableColumnComponent } from './components/data-table/data-table-column.component'
import { DataTableComponent } from './components/data-table/data-table.component'
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component'
import { DropdownItemDirective } from './components/dropdown/dropdown-item.directive'
import { DropdownMenuComponent } from './components/dropdown/dropdown-menu.component'
import { DropdownTriggerDirective } from './components/dropdown/dropdown-trigger.directive'
import { DropdownComponent } from './components/dropdown/dropdown.component'
import { EditNoteDialogComponent } from './components/edit-note-dialog/edit-note-dialog.component'
import { EmptyPlaceholderComponent } from './components/empty-placeholder/empty-placeholder.component'
import { EntityInfoComponent } from './components/entity-info/entity-info.component'
import { FormFieldControlDirective } from './components/form-field/form-field-control.directive'
import { FormFieldComponent } from './components/form-field/form-field.component'
import { FormItemComponent } from './components/form-item/form-item.component'
import { HistoryEntryDetailComponent } from './components/history-entry-detail/history-entry-detail.component'
import { InfiniteScrollModule } from './components/infinite-scroll/infinite-scroll'
import { ItemsPerPageControlsComponent } from './components/items-per-page-controls/items-per-page-controls.component'
import { LabeledDataComponent } from './components/labeled-data/labeled-data.component'
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component'
import { DialogButtonsDirective } from './components/modal-dialog/dialog-buttons.directive'
import { DialogComponentOutletComponent } from './components/modal-dialog/dialog-component-outlet.component'
import { DialogTitleDirective } from './components/modal-dialog/dialog-title.directive'
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component'
import { ObjectTreeComponent } from './components/object-tree/object-tree.component'
import { PaginationControlsComponent } from './components/pagination-controls/pagination-controls.component'
import { ExternalImageDialogComponent } from './components/rich-text-editor/external-image-dialog/external-image-dialog.component'
import { LinkDialogComponent } from './components/rich-text-editor/link-dialog/link-dialog.component'
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component'
import { SelectToggleComponent } from './components/select-toggle/select-toggle.component'
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component'
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component'
import { TableRowActionComponent } from './components/table-row-action/table-row-action.component'
import { TenantBadgeComponent } from './components/tenant-badge/tenant-badge.component'
import { TimelineEntryComponent } from './components/timeline-entry/timeline-entry.component'
import { TitleInputComponent } from './components/title-input/title-input.component'
import { DisabledDirective } from './directives/disabled.directive'
import { IfMultiTenantDirective } from './directives/if-multitenant.directive'
import { ShowClippedTextDirective } from './directives/show-clipped-text.directive'
import { CurrencyNamePipe } from './pipes/currency-name.pipe'
import { DurationPipe } from './pipes/duration.pipe'
import { FormatNumberPipe } from './pipes/format-number.pipe'
import { SentenceCasePipe } from './pipes/sentence-case.pipe'
import { SortPipe } from './pipes/sort.pipe'
import { StringToColorPipe } from './pipes/string-to-color.pipe'
import { TimeAgoPipe } from './pipes/time-ago.pipe'
import { WithLoadingPipe } from './pipes/with-loading.pipe'
import { CanDeactivateDetailGuard } from './providers/routing/can-deactivate-detail-guard'
import { LikeDislikeComponent } from './components/like-dislike-feedback/like-dislike.component'
import { ColorPickerModule } from 'ngx-color-picker'
import { OpenPlayer } from './components/open-player/open-player.component'
import { FilterUniquePipe } from './pipes/filterUnique.pipe'
import { FileUploadComponent } from './components/file-upload/file-upload.component'
import { SafePipe } from './pipes/safe-url.pipe'
import { AngularEditorModule } from '@kolkov/angular-editor'
import { ContentFileUploadComponent } from './components/file-upload-modal/file-upload-modal.component'
import {DataDownloadButtonComponent} from './components/data-download-button/data-download-button.component'

const IMPORTS = [
    ClarityModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    InfiniteScrollModule,
    LazyLoadImageModule,
    NgSelectModule,
    NgxPaginationModule,
    OverlayModule,
    ReactiveFormsModule,
    RouterModule,
    SortablejsModule,
    TranslateModule,
    ColorPickerModule,
    AngularEditorModule
]

const DECLARATIONS = [
    ActionBarComponent,
    ActionBarItemsComponent,
    ActionBarLeftComponent,
    ActionBarRightComponent,
    AffixedInputComponent,
    AudioRenderedComponent,
    BoldTextRendererComponent,
    BoolIconRendererComponent,
    ChipComponent,
    ComponentRendererOutletDirective,
    CurrencyInputComponent,
    CurrencyNamePipe,
    DataGridComponent,
    DataTableColumnComponent,
    DataTableComponent,
    DatetimePickerComponent,
    DialogButtonsDirective,
    DialogComponentOutletComponent,
    DialogTitleDirective,
    DisabledDirective,
    DropdownComponent,
    DropdownItemDirective,
    DropdownMenuComponent,
    DropdownTriggerDirective,
    DurationPipe,
    EditNoteDialogComponent,
    EmptyPlaceholderComponent,
    EntityInfoComponent,
    ExternalImageDialogComponent,
    FormatNumberPipe,
    FormFieldComponent,
    FormFieldControlDirective,
    FormItemComponent,
    FunctionRendererPipe,
    HistoryEntryDetailComponent,
    IfMultiTenantDirective,
    ImgRendererComponent,
    ItemsPerPageControlsComponent,
    LabeledDataComponent,
    LabelRendererComponent,
    LanguageSelectorComponent,
    LinkDialogComponent,
    ModalDialogComponent,
    NestedPropertyPipe,
    ObjectTreeComponent,
    OpenPlayer,
    PaginationControlsComponent,
    PercentageSuffixInputComponent,
    RichTextEditorComponent,
    SafePipe,
    SelectToggleComponent,
    SentenceCasePipe,
    ShowClippedTextDirective,
    SimpleDialogComponent,
    ImageDialogComponent,
    SortPipe,
    StringToColorPipe,
    TableRowActionComponent,
    TenantBadgeComponent,
    TimeAgoPipe,
    TimelineEntryComponent,
    TitleInputComponent,
    WithLoadingPipe,
    LikeDislikeComponent,
    FilterUniquePipe,
    FileUploadComponent,
    ContentFileUploadComponent,
    DataDownloadButtonComponent
]

@NgModule({
    imports: IMPORTS,
    exports: [...IMPORTS, ...DECLARATIONS],
    declarations: DECLARATIONS,
    providers: [
        // This needs to be shared, since lazy-loaded
        // modules have their own entryComponents which
        // are unknown to the CoreModule instance of ModalService.
        // See https://github.com/angular/angular/issues/14324#issuecomment-305650763
        ModalService,
        CanDeactivateDetailGuard
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
