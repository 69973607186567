import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { MatomoPeriod } from '../enums/matomo-period.enum'
import { AppConcurrencySummary, MatomoPeriodSummary, MatomoVisitsSummaryViewModel } from '../models/Metrics'
import { RollupPeriod } from '../enums/rollup-period.enum'
import { RollupType } from '../enums/rollup-type.enum'
import { TimeUtils } from '../utils/time-utils'

export class MatomoDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    visitsSummary(tenantId: string, matomoPeriod: MatomoPeriod, year: number, month: number | null = null, day: number | null = null): Observable<MatomoVisitsSummaryViewModel> {
        let params = `&year=${year}`

        if (month) {
            params += `&month=${month}`
        }

        if (day) {
            params += `&day=${day}`
        }

        return this.http.get<MatomoVisitsSummaryViewModel>(`${this.environment.metricsApiHost}/matomo/${tenantId}/visitssummary?matomoPeriod=${matomoPeriod}${params}`)
    }

    //Admin calls for test page
    visitsSummaryTelemetry(tenantId: string, matomoPeriod: MatomoPeriod, year: number, month: number | null = null, day: number | null = null): Observable<MatomoVisitsSummaryViewModel> {
        let params = `&year=${year}`

        if (month) {
            params += `&month=${month}`
        }

        if (day) {
            params += `&day=${day}`
        }

        return this.http.get<MatomoVisitsSummaryViewModel>(`${this.environment.metricsApiHost}/matomo/${tenantId}/visitssummarytelemetry?matomoPeriod=${matomoPeriod}${params}`)
    }

    visitsSummaryForPeriod(tenantId: string, matomoPeriod: MatomoPeriod, start: Date, end: Date): Observable<{ [label: string]: MatomoPeriodSummary }> {
        return this.http.get<{ [label: string]: MatomoPeriodSummary }>(
            `${this.environment.metricsApiHost}/matomo/${tenantId}/visitssummaryforperiod?matomoPeriod=${matomoPeriod}&startDate=${TimeUtils.recreateDateAsUTC(start).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(end).toISOString()}`
        )
    }

    //Admin calls for test page
    // Observable<{ [label: string]: MatomoPeriodSummary }>
    visitsSummaryForPeriodTelemetry(tenantId: string, matomoPeriod: any, start: Date, end: Date): Observable<{ [label: string]: MatomoPeriodSummary }> {
        return this.http.get<{ [label: string]: MatomoPeriodSummary }>(
            `${this.environment.metricsApiHost}/matomo/${tenantId}/visitssummaryforperiodtelemetry?matomoPeriod=${matomoPeriod}&startDate=${TimeUtils.recreateDateAsUTC(start).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(end).toISOString()}`
        )
    }
    appConcurrencySummaryRollupData(tenantId: string, period: RollupPeriod, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<{ [label: string]: any }> {
        let params: { [param: string]: string | string[] } = {
            tenantId,
            period: period.toString(),
            rollupType: RollupType.AppConcurrentSummary.toString(),
            dimensionName,
            dimensionValue,
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }        
        return this.http.get<AppConcurrencySummary[]>(`${this.environment.metricsApiHost}/matomo/${tenantId}/RetrieveRollup`, { params })
    }

    rollupData(tenantId: string, period: RollupPeriod, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): any {
        let params: { [param: string]: string | string[] } = {
            tenantId,
            period: period.toString(),
            rollupType: RollupType.RollupData.toString(),
            dimensionName,
            dimensionValue,
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }        
        return this.http.get<{ [label: string]: MatomoPeriodSummary }>(`${this.environment.metricsApiHost}/matomo/${tenantId}/RetrieveRollup`, { params })
    }  
}

// api / matomo / 6d2fc469 - 1aa3 - 455c - 8e71 - 36fc65de6156 / RetrieveRollup ? tenantId = 6d2fc469 - 1aa3 - 455c - 8e71 - 36fc65de6156 & period=3 & rollupType=4 & dimensionName=All & dimensionValue=All & startDate=2023 -08-01T00:00:00.000Z & endDate=2023 -08-07T00:00:00.000Z
