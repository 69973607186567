<ng-template fbrDialogTitle></ng-template>

<div class="clr-control-container">
    <h1>Trust Centre</h1>
    <section>
        From our first line of code, Fabrik has been developed with Privacy at the heart of what we do. We pride ourselves on being ethical, respectful, and non-discriminatory to your information. We are committed to
        protecting the security, privacy, and availability of your data, systems, and products, all in the endeavour to ensure that you can deliver trusted experience to your community every day.
    </section>
    <h1>Our commitment to you</h1>
    <section>
        We strive to be transparent about our policies, practices, and technologies that help you ensure the security, compliance, and privacy of your community’s data.
        <br />
        <br />

        <ol>
            <li>We store your and your community’s information. You own and control this information.</li>
            <li>We are transparent about where data is located and how it is used.</li>
            <li>We keep all personal data private & secure.</li>
        </ol>

        <h3>Privacy</h3>

        Fabrik employs privacy and security standards that enable our customers to comply with the most stringent legal and regulatory requirements. Policies and products are compliant with GDPR and POPI privacy
        regulations.
        <br />
        <br />
        Find out more in our publicly available
        <a href="https://www.fabrik.cloud/privacy-policy" target="_blank">Privacy Policy</a>
        .

        <h3>Security</h3>

        We have the following organisational policies and measures in place:
        <br />
        <br />
        <ol>
            <li>Access Control</li>
            <li>Physical Security</li>
            <li>Anti-Virus</li>
            <li>Surveillance and Monitoring</li>
            <li>Risk Management</li>
        </ol>

        <h3>Services & Support</h3>

        We strive to provide the best level of support for our customers and your members at all times. Technical assistance is delivered via our Service Desk. We use commercially reasonable efforts to meet SLAs as
        stipulated by your Services Order Form. We offer competitive response times based on the level of urgency and required support.
        <br />
        <br />

        <a href="mailto:support@fabrik.cloud">Request Technical Assistance</a>
        <br />
        <a href="mailto:advertise@fabrik.cloud">Request Advertising Assistance</a>
        <br />
        <a href="mailto:accounts@immedia.co.za">Request Billing Assistance</a>

        <h3>Queries & Complaints</h3>

        Should you wish to raise a complaint regarding our services, please email
        <a href="mailto:info@fabrik.cloud">info@fabrik.cloud</a>
        . A senior representative of the company will be in touch to address your issue.
    </section>
    <h1>Your commitment to your community</h1>
    <section>
        Within the Fabrik platform, you are responsible for the following activities:
        <br />
        <br />
        <ul>
            <li>Moderating content and messages added by your community members.</li>
            <li>Approving and/or suspending members.</li>
            <li>Ensuring that only delegated members of your team have authorised access to sensitive information contained within the Fabrik platform.</li>
            <li>Legal documents such as Terms and Conditions of Use, Privacy Policy and Community Standards.</li>
        </ul>

        In your app, you need to make available three types of policy documents. These describe your obligations to your app community, and theirs to you. These documents are required by the app stores from which people
        download your app, and cannot be skipped.

        <h3>1. Terms and Conditions of Use</h3>
        A Terms and Conditions of Use agreement includes the rules and the guidelines and other useful sections to which members must agree in order to use or access your mobile app and related services.
        <br />
        <br />
        <a
            href="https://mixedcontentdev.blob.core.windows.net/a309d646-4eb4-472a-b548-189228ee5ae5/1632c885-56f4-462e-ed0d-08d89c505f9a/attachments/f4aadfcafb15c0a44f7b0f664e0001ec1f5544760f933ecd6f3a0e4239292234/f4aadfcafb15c0a44f7b0f664e0001ec1f5544760f933ecd6f3a0e4239292234.docx"
            download="Sample-Community-Standards.docx"
        >
            Download a sample document
        </a>
        .

        <h3>2. Privacy Policy</h3>
        A Privacy Policy is a statement or legal document that discloses some or all of the ways a party gathers, uses, discloses, and manages a customer or client's data. A Privacy Policy is also a great way to show app
        members that you can be trusted, and that you have procedures in place to handle their personal information with care. For your document, you are encouraged to include information about the Fabrik services.
        <br />
        <br />
        <a
            href="https://mixedcontentdev.blob.core.windows.net/a309d646-4eb4-472a-b548-189228ee5ae5/1632c885-56f4-462e-ed0d-08d89c505f9a/attachments/c4b948c01404d08c4392f2d5d47a6710ac0aecffabc2a7ddfea08cf14bd144a4/c4b948c01404d08c4392f2d5d47a6710ac0aecffabc2a7ddfea08cf14bd144a4.docx"
            download="Sample-Privacy-Policy.docx"
        >
            Download a sample document
        </a>
        .

        <h3>3. Community Standards</h3>
        Your app creates a space for likeminded people to share their thoughts and content. Your Community Standards is a set of rules and guidelines on what is and is not acceptable to post within your app, and prompts
        each member to acknowledge, accept and abide by their social responsibility towards other members.
        <br />
        <br />
        <a
            href="https://mixedcontentdev.blob.core.windows.net/a309d646-4eb4-472a-b548-189228ee5ae5/1632c885-56f4-462e-ed0d-08d89c505f9a/attachments/70e6d3ac472877dbc18b6b107a828fa1ecbc65a86038bb7321311f0396ca9a6c/70e6d3ac472877dbc18b6b107a828fa1ecbc65a86038bb7321311f0396ca9a6c.docx"
            download="Sample-Terms-and-Conditions-of-Use.docx"
        >
            Download a sample document
        </a>
        .
        <br />
        <br />

        You can
        <a href="mailto:support@fabrik.cloud?subject=Please update our Terms of Service documents">update your documents</a>
        at any time and, as soon as you do, your app members will receive a pop-up alerting them to read & accept the new policies.
    </section>
    <h2>Your usage of our platform</h2>
    <section>
        By using our services, you agree to these
        <a href="https://www.fabrik.cloud/terms" target="_blank">Terms & Conditions</a>
        and our
        <a href="https://www.fabrik.cloud/aup" target="_blank">Acceptable Use Policy</a>
        .
    </section>
</div>

<ng-template fbrDialogButtons>
    <button type="button" class="btn" (click)="cancel()">OK</button>
</ng-template>
