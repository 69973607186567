export enum CampaignInventoryPurposes {
    internal,
    billable
}

export enum DeviceTarget {
    All = 0,
    Andriod = 1,
    IOS = 2
}

export enum MemberTarget {
    All,
    UnRegistered,
    Registered
}

export enum TargetOperationGroup {
    And,
    Or,
    Neither
}

export enum TargetOperation {
    All,
    EqualTo,
    NotEqualTo,
    GreaterThan,
    LessThan,
    GreaterOrEqualTo,
    LessOrEqualTo
}
