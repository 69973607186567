import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'

import { AuthService } from '../auth/auth.service'

/**
 * This guard prevents unauthorized users from accessing any routes which require
 * authorization.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(_route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.checkAuthenticatedStatus().pipe(
            tap(_authenticated => {
                if (!this.authService.getToken()) {
                    this.router.navigate(['/authenticate'])
                }
            })
        )
    }
}
