<form [formGroup]="form">
    <fbr-form-field [label]="'editor.link-href' | translate" for="href">
        <input id="href" type="text" formControlName="href" />
    </fbr-form-field>
    <fbr-form-field [label]="'editor.link-title' | translate" for="title">
        <input id="title" type="text" formControlName="title" />
    </fbr-form-field>
</form>
<ng-template fbrDialogButtons>
    <button type="button" class="btn btn-secondary" (click)="remove()" *ngIf="existing">
        <clr-icon shape="unlink"></clr-icon>
        {{ 'editor.remove-link' | translate }}
    </button>
    <button type="submit" (click)="select()" class="btn btn-primary" [disabled]="form.invalid">
        {{ 'editor.set-link' | translate }}
    </button>
</ng-template>
