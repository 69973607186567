import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'

import { NavMenuSection } from '../../providers/nav-builder/nav-builder-types'

export const mainNavItems: NavMenuSection[] = [
    {
        id: 'home',
        label: _(''),
        hidden: false,
        items: [
            {
                id: 'home',
                label: _('nav.home'),
                routerLink: ['/'],
                icon: 'home',
                requiresPermission: ['']
            }
        ]
    },
    {
        id: 'esg',
        label: _('nav.esg'),
        hidden: false,        
        items: [
            {
                id: 'dashboard',
                label: _('nav.esg-dashboard'),
                routerLink: ['/esg', 'dashboard'],
                icon: 'form',
                requiresPermission: [],
                isInternalMenu: true
            }
        ]
    },
    {
        id: 'members',
        label: _('nav.member-management'),
        hidden: false,
        items: [
            {
                id: 'members',
                label: _('nav.members'),
                routerLink: ['/member', 'members'],
                icon: 'user',
                requiresPermission: ['ability-app-users-view']
            },
            {
                id: 'chat-groups',
                label: _('nav.chat-groups'),
                routerLink: ['/member', 'chat-groups'],
                icon: 'talk-bubbles',
                requiresPermission: ['ability-chat-group-users-view']
            },
            {
                id: 'profile-fields',
                label: _('nav.profile-fields'),
                routerLink: ['/member', 'profile-fields'],
                icon: 'id-badge',
                requiresPermission: ['ability-profile-fields-view']
            }
        ]
    },
    {
        id: 'settings',
        label: _('nav.settings'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'livestream',
                label: _('nav.livestream'),
                routerLink: ['/settings', 'livestream'],
                icon: 'volume-up',
                requiresPermission: ['ability-livestream-view']
            },
            {
                id: 'primary',
                label: _('nav.primary'),
                routerLink: ['/settings', 'primary'],
                icon: 'list',
                requiresPermission: ['ability-primary-feed-view']
            },
            {
                id: 'secondary',
                label: _('nav.secondary'),
                routerLink: ['/settings', 'secondary'],
                icon: 'list',
                requiresPermission: ['ability-secondary-feed-view']
            },
            {
                id: 'terms',
                label: _('nav.policies'),
                routerLink: ['/settings', 'policies'],
                icon: 'form',
                requiresPermission: ['ability-terms-and-conditions-modify']
            },
            {
                id: 'support-categories',
                label: _('nav.supportCategory'),
                routerLink: ['/settings', 'support-categories'],
                icon: 'list',
                requiresPermission: ['ability-tenant-support-category-modify']
            },
            {
                id: 'concierge',
                label: _('nav.concierge'),
                routerLink: ['/settings', 'concierge'],
                icon: 'bell',
                requiresPermission: ['ability-panic-button-view']
            },
            {
                id: 'video',
                label: _('nav.video'),
                routerLink: ['/settings', 'video'],
                icon: 'video-camera',
                requiresPermission: ['ability-youtube-videos-modify']
            },
            {
                id: 'embeds',
                label: _('nav.embeds'),
                routerLink: ['/settings', 'embeds'],
                icon: 'paste',
                requiresPermission: ['internal-identity-abilities-manage']
            },
            {
                id: 'app-display-name',
                label: _('nav.app-display-name'),
                routerLink: ['/settings', 'app-display-name'],
                icon: 'beta',
                requiresPermission: ['ability-message-header-settings-modify']
            }
        ]
    },
    {
        id: 'access',
        label: _('nav.access-control'),
        hidden: true,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'access-control',
                label: _('nav.access-control'),
                routerLink: ['/access-control', 'access-control'],
                icon: 'cursor-hand-open',
                requiresPermission: ['ability-access-control-metadata-view']
            }
        ]
    },
    {
        id: 'content-moderation',
        label: _('nav.content-moderation'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'reports',
                label: _('nav.reports'),
                routerLink: ['/content-moderation', 'reports'],
                icon: 'form',
                requiresPermission: ['ability-reports-view']
            },
            {
                id: 'categories-list',
                label: _('nav.report-categories'),
                routerLink: ['/content-moderation', 'report-categories'],
                icon: 'id-badge',
                requiresPermission: ['ability-reports-view']
            },
            {
                id: 'members-report',
                label: _('nav.report-members'),
                routerLink: ['/content-moderation', 'members-report'],
                icon: 'form',
                requiresPermission: ['ability-reports-view']
            }
        ]
    },
    {
        id: 'advertising',
        label: _('nav.advertising'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'advertiser',
                label: _('nav.advertiser'),
                routerLink: ['/advertising', 'advertisers'],
                icon: 'form',
                requiresPermission: ['ability-audio-adverts-view']
            },
            {
                id: 'campaigns',
                label: _('nav.campaigns'),
                routerLink: ['/advertising', 'campaigns'],
                icon: 'form',
                requiresPermission: ['ability-audio-adverts-view']
            }
        ]
    },
    {
        id: 'service-directory',
        label: _('nav.service-directory'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'listings',
                label: _('nav.manage-listings'),
                routerLink: ['/service-directory', 'listings'],
                icon: 'coin-bag',
                requiresPermission: ['ability-service-directory-modify']
            }
        ]
    },
    {
        id: 'metrics',
        label: _('nav.metrics'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'dashboard',
                label: _('metrics.dashboard'),
                routerLink: ['/metrics', 'now'],
                icon: 'clock',
                requiresPermission: ['ability-view-metrics-dashboard']
            },            
            {
                id: 'streaming',
                label: _('metrics.streaming'),
                routerLink: ['/metrics', 'streaming'],
                icon: 'wifi',
                requiresPermission: ['ability-view-metrics-dashboard']
            },
            {
                id: 'media',
                label: _('metrics.media'),
                routerLink: ['/metrics', 'media'],
                icon: 'video-gallery',
                requiresPermission: ['ability-view-metrics-dashboard']
            },
            {
                id: 'apps',
                label: _('metrics.apps'),
                routerLink: ['/metrics', 'apps'],
                icon: 'mobile',
                requiresPermission: ['ability-view-metrics-dashboard']
            },
            {
                id: 'smashboard',
                label: _('metrics.smashboard'),
                routerLink: ['/metrics', 'smashboard'],
                icon: 'talk-bubbles',
                requiresPermission: ['ability-view-metrics-dashboard']
            },
            {
                id: 'advertising',
                label: _('metrics.advertising'),
                routerLink: ['/metrics', 'advertising'],
                icon: 'node',
                requiresPermission: ['ability-view-metrics-dashboard']
            }
        ]
    },
    {
        id: 'smashboard',
        label: _('nav.smashboard'),
        hidden: true,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'messages',
                label: _('nav.smashboard-messages'),
                routerLink: ['/smashboard', 'messages'],
                icon: 'firewall',
                requiresPermission: [''] // add smashboard ability
            }
        ]
    },
    {
        id: 'echocast',
        label: _('nav.echocast'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'dashboard',
                label: _('nav.echocast-dashboard'),
                routerLink: ['/echocast', 'dashboard'],
                icon: 'microphone',
                requiresPermission: ['modify-recording'] 
            },
            {
                id: 'audio-upload',
                label: _('nav.audio-upload'),
                routerLink: ['/echocast', 'audio-upload'],
                icon: 'upload-cloud',
                requiresPermission: ['modify-recording','manual-upload-recording'] 
            },
            {
                id: 'audio-archive',
                label: _('nav.audio-archive'),
                routerLink: ['/echocast', 'audio-archive'],
                icon: 'download-cloud',
                requiresPermission: ['request-archive'] 
            },
            {
                id: 'category',
                label: _('nav.echocast-category'),
                routerLink: ['/echocast', 'category'],
                icon: 'view-list',
                requiresPermission: ['modify-category']
            },
            {
                id: 'presets',
                label: _('nav.echocast-presets'),
                routerLink: ['/echocast', 'presets'],
                icon: 'headphones',
                requiresPermission: ['modify-preset']
            }
        ]
    },
    {
        id: 'campaign-cast',
        label: _('nav.campaign-cast'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'smart-campaigns',
                label: _('nav.smart-campaigns'),
                routerLink: ['/campaign-cast', 'campaigns'],
                icon: 'calendar',
                requiresPermission: ['ability-smart-campaign-modify']
            },
            {
                id: 'smart-inventories',
                label: _('nav.smart-inventories'),
                routerLink: ['/campaign-cast', 'inventory'],
                icon: 'file-group',
                requiresPermission: ['ability-smart-campaign-modify']
            },
            {
                id: 'smart-campaigns-reports',
                label: _('nav.reports'),
                routerLink: ['/campaign-cast', 'reports'],
                icon: 'form',
                requiresPermission: ['ability-smart-campaign-view']
            },
            {
                id: 'smart-campaigns-booking',
                label: _('nav.campaign-cast-booking'),
                routerLink: ['/campaign-cast', 'booking'],
                icon: 'form',
                requiresPermission: ['bookings']
            },
            {
                id: 'smart-advertisers',
                label: _('nav.campaign-cast-advertisers'),
                routerLink: ['/campaign-cast', 'advertisers'],
                icon: 'rack-server',
                requiresPermission: ['smart-advertiser-modify']
            }
        ]
    },
    {
        id: 'content-feed',
        label: _('nav.content-feed'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'categories',
                label: _('nav.category-management'),
                routerLink: ['/content-feed', 'categories'],
                icon: 'view-list',
                requiresPermission: ['mixed-feeds-categories-modify']
            },
            {
                id: 'itemContent',
                label: _('nav.itemContent'),
                routerLink: ['/content-feed', 'itemContent'],
                icon: 'file-group',
                requiresPermission: ['mixed-feeds-content-modify']
            },
            {
                id: 'tabs',
                label: _('nav.tabs'),
                routerLink: ['/content-feed', 'tabs'],
                icon: 'organization',
                requiresPermission: ['mixed-feeds-categories-modify']
            },
            {
                id: 'configuration',
                label: _('nav.config-list'),
                routerLink: ['/content-feed', 'import'],
                icon: 'file-settings',
                requiresPermission: ['mixed-feeds-imports-config-modify']
            },
            {
                id: 'product-subscriptions',
                label: _('nav.product-subscriptions'),
                routerLink: ['/content-feed', 'product-subscriptions'],
                icon: 'shopping-cart',
                requiresPermission: ['subscription-product-modify']
            },
            {
                id: 'manual-subscriptions',
                label: _('nav.manual-subscriptions'),
                routerLink: ['/content-feed', 'manual-subscriptions'],
                icon: 'coin-bag',
                requiresPermission: ['subscription-product-modify']
            }
        ]
    },
    {
        id: 'forms',
        label: _('nav.forms'),
        hidden: false,
        collapsible: true,
        collapsedByDefault: true,
        items: [
            {
                id: 'forms-list',
                label: _('nav.forms-list'),
                routerLink: ['/forms', 'list'],
                icon: 'clipboard',
                requiresPermission: ['ability-forms-modify']
            }
        ]
    }
]
