import { Component, Input } from '@angular/core'

import { ComponentRenderer } from '../../interfaces/component-renderer.interface'
import { ColumnComponentRendererSpec, ColumnRendererSpec } from '../../interfaces/datagrid-column.interface'

export interface Label {
    labelKey: string
    tooltipKey?: string
    className?: string
}

export interface LabelRendererConfig {
    labels: Label | Label[]
}

@Component({
    selector: 'fbr-label-renderer',
    templateUrl: './label-renderer.component.html',
    styleUrls: ['./label-renderer.component.scss']
})
export class LabelRendererComponent implements ComponentRenderer<LabelRendererConfig> {
    @Input()
    config: LabelRendererConfig
}

/**
 * Creates a {@link ColumnRendererSpec} for rendering one or more labels (chips) in a column.
 */
export function LabelRenderer<R>(labelExtractor: (record: R | unknown) => Label | Label[]): ColumnRendererSpec<R, LabelRendererConfig> {
    return ColumnComponentRendererSpec({
        type: LabelRendererComponent,
        config(record): LabelRendererConfig {
            const label = labelExtractor(record)

            return {
                labels: Array.isArray(label) ? label : [label]
            }
        }
    })
}
