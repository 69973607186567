import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { MetricTimeSpan } from '../enums/metric-time-span.enum'
import { AppCurrentEntryViewModel, LiveViewModel } from '../models/Metrics'

export class LiveDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    events(tenantId: string, timespan: MetricTimeSpan, delayInMinutes: number = 1): Observable<LiveViewModel[]> {
        return this.http.get<LiveViewModel[]>(`${this.environment.metricsApiHost}/live/${tenantId}/events?metricTimeSpan=${timespan}&delayInMinutes=${delayInMinutes}`)
    }

    streams(tenantId: string, timespan: MetricTimeSpan, delayInMinutes: number = 1): Observable<LiveViewModel[]> {
        return this.http.get<LiveViewModel[]>(`${this.environment.metricsApiHost}/live/${tenantId}/streams?metricTimeSpan=${timespan}&delayInMinutes=${delayInMinutes}`)
    }

    visitors(tenantId: string, timespan: MetricTimeSpan): Observable<LiveViewModel[]> {
        return this.http.get<LiveViewModel[]>(`${this.environment.metricsApiHost}/live/${tenantId}/visits?metricTimeSpan=${timespan}`)
    }

    appDownload(tenantId: string, metricName: string, timespan: MetricTimeSpan = MetricTimeSpan.OneHour): Observable<number> {
        return this.http.get<number>(`${this.environment.metricsApiHost}/metrics/${tenantId}/count?metricName=${metricName}&metricTimeSpan=${timespan}`)
    }
    appCurrent(tenantId: string,  timespan: MetricTimeSpan = MetricTimeSpan.OneHour, delayInMinutes: number = 1): Observable<AppCurrentEntryViewModel[]>{
        return this.http.get<AppCurrentEntryViewModel[]>(`${this.environment.metricsApiHost}/TenantStats/${tenantId}/AppConcurrent?metricTimeSpan=${timespan}&delayInMinutes=${delayInMinutes}`)
    }
}
