<ng-template fbrDialogTitle>
    {{ 'feedback-form.title' | translate }}
</ng-template>

<fbr-form-field [label]="'feedback-form.name' | translate" for="feedback-name" [hidden]="anonymous">
    <input id="feedback-name" [(ngModel)]="ticketdata.name" type="text" />
</fbr-form-field>

<fbr-form-field [label]="'feedback-form.email' | translate" for="feedback-email" [hidden]="anonymous">
    <input id="feedback-email" [(ngModel)]="ticketdata.email" type="email" disabled />
</fbr-form-field>

<fbr-form-field id="description" [label]="'feedback-form.description' | translate" for="feedback-decription">
    <textarea id="feedback-decription" [(ngModel)]="ticketdata.description" placeholder="Share your feedback here"></textarea>
</fbr-form-field>

<fbr-form-field [label]="'feedback-form.contactnumber' | translate" for="feedback-contactnumber" [hidden]="anonymous">
    <input id="feedback-contactnumber" [(ngModel)]="ticketdata.contactNumber" type="tel" placeholder="" />
</fbr-form-field>

<div class="clr-control-container text-right">
    <clr-toggle-wrapper>
        <input type="checkbox" clrToggle name="anonymous" [(ngModel)]="anonymous" />
        <label>{{ 'feedback-form.anonymous' | translate }}</label>
    </clr-toggle-wrapper>
</div>

<ng-template fbrDialogButtons>
    <button type="button" class="btn" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
    <button type="submit" (click)="submit()" [disabled]="!ticketdata.description || (!anonymous && ticketdata.contactNumber == '')" class="btn btn-primary">
        {{ 'feedback-form.send' | translate }}
    </button>
</ng-template>
