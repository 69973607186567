<div class="form-group" [class.no-label]="!label" [class.clr-error]="formFieldControl?.formControlName?.invalid">
    <label *ngIf="label" [for]="for" class="clr-control-label">
        {{ label }}
        <clr-tooltip *ngIf="tooltip">
            <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
            <clr-tooltip-content clrSize="md">
                <span>{{ tooltip }}</span>
            </clr-tooltip-content>
        </clr-tooltip>
    </label>
    <label [for]="for" aria-haspopup="true" role="tooltip" [class.invalid]="formFieldControl?.touched && !formFieldControl?.valid" class="tooltip tooltip-validation tooltip-sm tooltip-top-left">
        <div class="input-row" [class.has-toggle]="readOnlyToggle">
            <ng-content></ng-content>
            <button *ngIf="readOnlyToggle" type="button" [disabled]="!isReadOnly" [title]="'common.edit-field' | translate" class="btn btn-icon edit-button" (click)="setReadOnly(false)">
                <clr-icon shape="edit"></clr-icon>
            </button>
        </div>
        <div class="clr-subtext help" *ngIf="help">{{ help }}</div>
        <div class="clr-subtext" *ngIf="getErrorMessage()">{{ getErrorMessage() }}</div>
        <span class="tooltip-content" *ngIf="formFieldControl?.formControlName?.invalid && formFieldControl?.formControlName?.touched">
            <span class="d-inline-block" *ngFor="let error of errorMessages">{{ error | translate }}</span>
        </span>
    </label>
</div>
