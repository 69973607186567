import { Observable } from 'rxjs'

import { HttpClient, HttpEvent } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { ServiceDirectory, uploadImage, Businesses, uploadCategoryImage, Categories } from '../models/ServiceDirectory'

export class DirectoryDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * List Categories
     * @param tenantId id of the current tenant
     */

    list(tenantId: string): Observable<ServiceDirectory> {
        return this.http.get<ServiceDirectory>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory/items`)
    }

    /**
     * List Categories Hierachy
     * @param tenantId id of the current tenant
     */

    listHierachy(tenantId: string): Observable<ServiceDirectory> {
        return this.http.get<ServiceDirectory>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories/hierarchy`)
    }

    /**
     * List Category Businesses
     * @param tenantId id of the current tenant
     * @param categoryId id of the category
     */
    listCategoryBusinesses(tenantId: string, categoryId: string | null): Observable<Businesses> {
        return this.http.get<Businesses>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory?categoryId=${categoryId}`)
    }

    /**
     * List Category Categories
     * @param tenantId id of the current tenant
     * @param categoryId id of the category
     */
    listCategoryCategories(tenantId: string, categoryId: string | null): Observable<Categories> {
        return this.http.get<Categories>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories?categoryId=${categoryId}`)
    }

    /**
     * Get Category
     * @param tenantId id of the current tenant
     * @param categoryId id of the category
     */
    categoryById(tenantId: string, categoryId: string): Observable<Categories> {
        return this.http.get<Categories>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories/${categoryId}`)
    }

    /**
     * Get Business
     * @param tenantId id of the current tenant
     * @param businessId id of the business
     */
    businessById(tenantId: string, businessId: string): Observable<Businesses> {
        return this.http.get<Businesses>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory/${businessId}`)
    }

    /**
     * Upload a directory Image
     *
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadImage(tenantId: string, file: any): Observable<HttpEvent<uploadImage>> {
        return this.http.post<uploadImage>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory/image`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }

    /**
     * Upload a Dialboard  Image
     *
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadImageDialboard(tenantId: string, file: any): Observable<HttpEvent<uploadImage>> {
        return this.http.post<uploadImage>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory/dialboard/image`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }

    /**
     * Upload a category image
     *
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadCategoryImage(tenantId: string, file: any): Observable<HttpEvent<uploadCategoryImage>> {
        return this.http.post<uploadCategoryImage>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories/image`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }

    /**
     * Update Business
     *
     * @param tenantId id of the current tenant
     * @param data business Object
     */
    updateBusiness(tenantId: string, data: any): Observable<Businesses> {
        return this.http.put<Businesses>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory`, data)
    }

    /**
     * Update Category
     *
     * @param tenantId id of the current tenant
     * @param data category Object
     */
    updateCategory(tenantId: string, data: any): Observable<Categories> {
        return this.http.put<Categories>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories`, data)
    }

    /**
     * Create Business
     *
     * @param tenantId id of the current tenant
     * @param data business Object
     */
    createBusiness(tenantId: string, data: any): Observable<Businesses> {
        return this.http.post<Businesses>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory`, data)
    }

    /**
     * Create Category
     *
     * @param tenantId id of the current tenant
     * @param data category Object
     */
    createCategory(tenantId: string, data: any): Observable<Categories> {
        return this.http.post<Categories>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories`, data)
    }

    /**
     * Delete business
     *
     * @param tenantId id of the current tenant
     * @param businessId id of the business
     */

    deleteBusiness(tenantId: string, businessId: string): Observable<Businesses> {
        return this.http.delete<Businesses>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory?directoryBusinessId=${businessId}`)
    }

    /**
     * Delete category
     *
     * @param tenantId id of the current tenant
     * @param categoryId id of the category
     */

    deleteCategory(tenantId: string, categoryId: string): Observable<Categories> {
        return this.http.delete<Categories>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories?directoryCategoryId=${categoryId}`)
    }

    /**
     * move business
     * @param tenantId id of the current tenant
     * @param businessId id of the business
     */
    moveBusiness(tenantId: string, businessId: string, sourceId: string | null, tergetId: string): Observable<Businesses> {
        var data: any = null
        return this.http.put<Businesses>(`${this.environment.appApiHost}/api/${tenantId}/admin/directory/${businessId}/move?sourceCategoryId=${sourceId}&targetCategoryId=${tergetId}`, data)
    }

    /**
     * Order Categories Hierachy
     * @param tenantId id of the current tenant
     * @param data ordered array
     */

    orderHierachy(tenantId: string, data): Observable<ServiceDirectory> {
        return this.http.put<ServiceDirectory>(`${this.environment.appApiHost}/api/${tenantId}/admin/categories/hierarchy/order`, data)
    }
}
