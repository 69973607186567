import { Observable } from 'rxjs'

import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { LiveStream, LiveStreamUploadImage } from '../models/LiveStream'

export class LiveStreamDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a live stream item by id.
     *
     * @param tenantId id of the current tenant
     * @param liveStreamId id of the live stream item to retrieve
     */
    byId(tenantId: string, liveStreamId: string): Observable<LiveStream> {
        return this.http.get<LiveStream>(`${this.environment.appApiHost}/api/${tenantId}/admin/livestream/${liveStreamId}`)
    }

    /**
     * Create a live stream item
     *
     * @param tenantId id of the current tenant
     * @param data new live stream object
     */
    create(tenantId: string, payload: LiveStream): Observable<LiveStream> {
        return this.http.post<LiveStream>(`${this.environment.appApiHost}/api/${tenantId}/admin/livestream`, payload)
    }

    /**
     * Delete a live stream item
     *
     * @param tenantId id of the current tenant
     * @param streamId id of the stream item to delete
     */
    delete(tenantId: string, streamId: string): Observable<LiveStream> {
        return this.http.delete<LiveStream>(`${this.environment.appApiHost}/api/${tenantId}/admin/livestream?liveStreamId=${streamId}`)
    }

    /**
     * List live stream items
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<LiveStream>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<LiveStream>>(`${this.environment.appApiHost}/api/${tenantId}/admin/livestream/find`, { params })
    }

    /**
     * Update a live stream
     *
     * @param tenantId id of the current tenant
     * @param streamId id of the stream item to update
     * @param stream stream item object
     */
    update(tenantId: string, streamId: string, livestreamData: LiveStream): Observable<LiveStream> {
        return this.http.put<LiveStream>(`${this.environment.appApiHost}/api/${tenantId}/admin/livestream?liveStreamId=${streamId}`, livestreamData)
    }

    /**
     * Order stream
     * @param tenantId id of the current tenant
     * @param livestreamData stream item object
     */
    order(tenantId: string, livestreamData: any): Observable<LiveStream> {
        return this.http.put<LiveStream>(`${this.environment.appApiHost}/api/${tenantId}/admin/livestream/order`, livestreamData)
    }

    /**
     * Upload a live stream image
     *
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadImage(tenantId: string, file: FormData): Observable<HttpEvent<LiveStreamUploadImage>> {
        return this.http.post<LiveStreamUploadImage>(`${this.environment.appApiHost}/api/${tenantId}/admin/image/livestream/upload`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }
}
