import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    selector: 'fbr-tenant-badge',
    templateUrl: './tenant-badge.component.html',
    styleUrls: ['./tenant-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantBadgeComponent {
    @Input() tenantName: string
}
