export interface EsgSummary {
    totalChatMessagesByGroup: EsgMessageStatistic[],
    totalChatMessagesByDate: EsgMessageStatistic[],
    nonTrustedChatMessagesByGroup: EsgMessageStatistic[],
    nonTrustedMessagesByDate: EsgMessageStatistic[],
    trustedChatMessagesByGroup: EsgMessageStatistic[],
    trustedMessagesByDate: EsgMessageStatistic[],
    trustedEngagementChatMessagesByGroup: EsgMessageStatistic[],
    trustedEngagementMessagesByDate: EsgMessageStatistic[],
    reportsSplit: EsgReportSplit,
    messageReportsBreakdown: EsgReportsBreakDown[],
    memberReportsBreakdown: EsgReportsBreakDown[],
    reportResolutionTime: EsgReportResolutionTime,
    unresolvedReportsSplit: EsgReportSplit,
    reportLogs: ReportLog[],
    reportMemberLogs: ReportMemberLog[],
    registrationsStatistics: RegistrationsStatistics
}
export interface EsgMessageStatistic {
    messageCount: number
    name: string
    created: string
}
export interface EsgReportSplit {
    messageReports: number,
    membersReports
}
export interface EsgReportsBreakDown {
    category: string,
    incidents: number,
    resolutionStatus: ReportResolutionStatus
}
export interface EsgReportResolutionTime {
    reportMaxHour?: number | null,
    reportMinHour?: number | null,
    reportMemberMaxHour?: number | null,
    reportMemberMinHour?: number | null

}
export interface ReportLog {
    createdAt: Date,
    reason: string,
    ResolutionStatus: ReportResolutionStatus,
    ResolvedAt: Date,
    ResolvedBy: string,
    ReportedBy: string,
    MessageSender: string,
    Message: string,
    Name: string
}
export interface ReportMemberLog {
    CreatedAt: Date,
    Reason: string,
    ResolutionStatus: ReportResolutionStatus,
    ResolvedAt?: Date | null,
    ResolvedBy: string,
    ReportedBy: string,
    ReportedMember: string
}
export interface RegistrationsStatistics{
    registrationsCount?: number 
}

export enum ReportResolutionStatus {
    Pending,
    Removed,
    NoAction,
    Purge,
    PurgeIncChildren
}
export interface EsgEngagmentSummaryViewModel{
    group: string,
    trustedMessagesCount: number,
    untrustedMessagesCount: number,
    totalCount: number    
}
export interface EsgEngagmentOverTimeViewModel{    
    trustedMessagesCount: number,
    untrustedMessagesCount: number,
    totalCount: number,
    date: string
}
export interface EsgIncidentSummaryViewModel{
    category: string,
    reportedMembersCount: number,
    reportedMessagesCount: number,
    totalIncidentsCount: number,
    resolved: string
}

export interface EsgEngagmentOverTimeWithDateViewModel  extends EsgEngagmentOverTimeViewModel {
    date_label: string
}
