export function lowcase(v: string): string {
    return v.toLowerCase()
}

export function upcase(v: string): string {
    return v.toUpperCase()
}

export function upfirst(v: string): string {
    return v.substr(0, 1).toUpperCase() + v.substr(1)
}
