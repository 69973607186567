import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { AccessControl } from '../models/AccessControl'

export class AccessControlDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    list(tenantId: string): Observable<AccessControl> {
        return this.http.get<AccessControl>(`${this.environment.appApiHost}/api/${tenantId}/access/backend/metadata`)
    }

    addOrUpdateMetadata(tenantId: string, data: any): Observable<AccessControl> {
        return this.http.put<AccessControl>(`${this.environment.appApiHost}/api/${tenantId}/access/backend/metadata`, data)
    }

    deleteMetadata(tenantId: string, data: AccessControl): Observable<AccessControl> {
        // need to do this because delete with a body is technically not RESTful so the client doesen't have a method for it
        return this.http.request<AccessControl>('delete', `${this.environment.appApiHost}/api/${tenantId}/access/backend/metadata`, { body: data })
    }
}
