<ng-container *ngIf="!isDemoMode; else demoMode">
    <fbr-dropdown>
        <button class="btn btn-link active-tenant" fbrDropdownTrigger>
            <!-- <fbr-tenant-badge [tenantName]="currentTenant?.name"></fbr-tenant-badge> -->
            <img class="tenant-logo" [src]="this.tenantLogo" [alt]="currentTenant?.name" />
            <span class="active-tenant">{{ currentTenant?.name }}</span>
            <span class="trigger">
                <clr-icon shape="caret down"></clr-icon>
            </span>
        </button>
        <fbr-dropdown-menu fbrPosition="bottom-right">
            <ng-container *ngFor="let tenant of tenants">
                <button *ngIf="tenant.id !== currentTenant?.id" type="button" fbrDropdownItem (click)="switchTenant(tenant.id)">
                    <fbr-tenant-badge [tenantName]="tenant.name"></fbr-tenant-badge>
                    {{ tenant.name }}
                </button>
            </ng-container>
        </fbr-dropdown-menu>
    </fbr-dropdown>
</ng-container>
<ng-template #demoMode>
    <button class="btn btn-link active-tenant">
        <img class="tenant-logo" [src]="this.tenantLogo" [alt]="'Fabrik Demo'" />
        <span class="active-tenant">Fabrik Demo</span>
    </button>
</ng-template>
