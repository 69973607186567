export async function validateImage(
    file: File,
    specification = {
        maxHeight: 2_560, // pixels
        maxWidth: 1_440 // pixels
    },
    errorCodes = {
        maxHeight: 'inventory.error-max-height',
        maxWidth: 'inventory.error-max-width'
    }
): Promise<string[]> {
    const errors: string[] = []

    return new Promise(resolve => {
        const reader = new FileReader()

        reader.onload = (event: any) => {
            const img = new Image()
            img.onload = () => {
                if (img.width > specification.maxWidth) {
                    errors.push(errorCodes.maxWidth)
                }

                if (img.height > specification.maxHeight) {
                    errors.push(errorCodes.maxHeight)
                }
                resolve(errors)
            }
            img.onerror = () => {
                resolve([...errors, 'Error loading image'])
            }
            img.src = event.target.result
        }

        reader.onerror = () => {
            resolve([...errors, 'Error reading file'])
        }

        reader.readAsDataURL(file)
    })
}
