<ng-container *ngIf="1 < availableLanguageCodes?.length">
    <fbr-dropdown>
        <button type="button" class="btn btn-sm btn-link" fbrDropdownTrigger [disabled]="disabled">
            <clr-icon shape="world"></clr-icon>
            {{ 'common.language' | translate }}: {{ 'lang.' + currentLanguageCode | translate | uppercase }}
            <clr-icon shape="caret down"></clr-icon>
        </button>
        <fbr-dropdown-menu fbrPosition="bottom-right">
            <button type="button" *ngFor="let code of availableLanguageCodes" (click)="languageCodeChange.emit(code)" fbrDropdownItem>
                {{ 'lang.' + code | translate }}
                <span class="code">{{ code }}</span>
            </button>
        </fbr-dropdown-menu>
    </fbr-dropdown>
</ng-container>
