import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable()
export class FetchAdapter {
    constructor(private httpClient: HttpClient) {}

    fetch = (input: Request | string, init: RequestInit): Promise<Response> => {
        const url = typeof input === 'string' ? input : input.url
        const method = typeof input === 'string' ? (init.method ? init.method : 'GET') : input.method

        return this.httpClient
            .request(method, url, {
                body: init.body,
                headers: init.headers as any,
                observe: 'response',
                responseType: 'json',
                withCredentials: true
            })
            .toPromise()
            .then(result => {
                return new Response(JSON.stringify(result.body), {
                    status: result.status,
                    statusText: result.statusText
                })
            })
    }
}
