<clr-main-container>
    <clr-header>
        <div class="branding">
            <a [routerLink]="['/']">
                <span class="d-inline"><img src="assets/fabrik-icon.png" class="icon" /></span>
                <span class="d-none d-inline-sm"><img src="assets/fabrik-text.png" class="logo" /></span>
            </a>
        </div>
        <div class="header-nav"></div>
        <div class="header-actions">
            <fbr-tenant-switcher *fbrIfMultiTenant></fbr-tenant-switcher>
            <fbr-user-menu [userName]="userName$ | async" [uiLanguage]="uiLanguage$ | async" [availableLanguages]="availableLanguages" (selectUiLanguage)="selectUiLanguage()" (logOut)="logOut()"></fbr-user-menu>
        </div>
    </clr-header>
    <div class="content-container">
        <div class="content-area">
            <nav class="subnav">
                <fbr-breadcrumb></fbr-breadcrumb>
            </nav>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </div>
        <fbr-main-nav></fbr-main-nav>
    </div>
</clr-main-container>
