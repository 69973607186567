export enum QuestionType {
    ThumbsUpDown,
    Scale,
    Emoji,
    Radio,
    Checkbox,
    Textbox,
    Textfield,
    Number,
    OptIn,
    Cascade
}
