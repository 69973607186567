import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { CampaignImpression } from '../models/Metrics'

export class AdvertisingDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    campaignImpressions(tenantId: string): Observable<CampaignImpression[]> {
        return this.http.get<CampaignImpression[]>(`${this.environment.metricsApiHost}/advertising/${tenantId}/campaignimpressions`)
    }
}
