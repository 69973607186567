import { ChangeDetectionStrategy, Component } from '@angular/core'

import { Dialog, DialogButtonConfig } from '../../../providers/modal/modal.service'

/**
 * Used by ModalService.dialog() to host an image modal dialog.
 */
@Component({
    selector: 'fbr-image-dialog',
    templateUrl: './image-dialog.component.html',
    styleUrls: ['./image-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageDialogComponent implements Dialog<any> {
    resolveWith: (result?: any) => void
    src = ''
    alt = ''
    buttons: Array<DialogButtonConfig<any>> = []
}
