import { fromEvent, Observable, of } from 'rxjs'
import { filter, map, mergeMap, sampleTime, tap } from 'rxjs/operators'

import * as Models from '../models'
import { AxisResolver } from './axis-resolver'
import { shouldTriggerEvents } from './event-trigger'
import { resolveContainerElement } from './ins-utils'
import { calculatePoints, createResolver } from './position-resolver'
import * as ScrollResolver from './scroll-resolver'
import { ScrollState } from './scroll-state'

export function createScroller(config: Models.IScroller) {
    const { scrollContainer, scrollWindow, element, fromRoot } = config

    const resolver = createResolver({
        axis: new AxisResolver(!config.horizontal),
        windowElement: resolveContainerElement(scrollContainer, scrollWindow, element, fromRoot)
    })

    const scrollState = new ScrollState({
        totalToScroll: calculatePoints(element, resolver)
    })

    const options: Models.IScrollRegisterConfig = {
        container: resolver.container,
        throttle: config.throttle
    }

    const distance = {
        up: config.upDistance,
        down: config.downDistance
    }

    return attachScrollEvent(options).pipe(
        mergeMap(() => of(calculatePoints(element, resolver))),
        map((positionStats: Models.IPositionStats) => toInfiniteScrollParams(scrollState.lastScrollPosition, positionStats, distance)),
        tap(({ stats }: Models.IScrollParams) => scrollState.updateScroll(stats.scrolled, stats.totalToScroll)),
        filter(({ fire, scrollDown, stats: { totalToScroll } }: Models.IScrollParams) => shouldTriggerEvents(config.alwaysCallback, fire, scrollState.isTriggeredScroll(totalToScroll, scrollDown))),
        tap((scrollParams: Models.IScrollParams) => {
            scrollState.updateTriggeredFlag(scrollParams.stats.scrolled, scrollParams.scrollDown)
        }),
        map(toInfiniteScrollAction)
    )
}

export function attachScrollEvent(options: Models.IScrollRegisterConfig): Observable<unknown> {
    let obs = fromEvent(options.container, 'scroll')

    if (options.throttle) {
        obs = obs.pipe(sampleTime(options.throttle))
    }

    return obs
}

export function toInfiniteScrollParams(lastScrollPosition: number, stats: Models.IPositionStats, distance: Models.IScrollerDistance): Models.IScrollParams {
    const { scrollDown, fire } = ScrollResolver.getScrollStats(lastScrollPosition, stats, distance)

    return {
        scrollDown,
        fire,
        stats
    }
}

export const InfiniteScrollActions = {
    DOWN: '[FBRIS] DOWN',
    UP: '[FBRIS] UP'
}

export function toInfiniteScrollAction(response: Models.IScrollParams): Models.IInfiniteScrollAction {
    const {
        scrollDown,
        stats: { scrolled: currentScrollPosition }
    } = response

    return {
        type: scrollDown ? InfiniteScrollActions.DOWN : InfiniteScrollActions.UP,
        payload: {
            currentScrollPosition
        }
    }
}
