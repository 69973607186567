import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { CampaignInventory, SmartInventory, SmartInventoryType } from '../models/SmartInventory'
import { SmartCampaignInventoryResponse } from '../models/SmartCampaign'
export class SmartInventoryDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a Smart Inventory Item by id.
     *
     * @param tenantId id of the current tenant
     * @param smartInventoryId id of the Smart Inventory Item to retrieve
     */
    byId(tenantId: string, smartInventoryId: string): Observable<SmartInventory> {
        return this.http.get<SmartInventory>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartinventory/${smartInventoryId}/?api-version=1.15`)
    }

    /**
     * Retrieve a Smart Inventory Types.
     * @param tenantId id of the current tenant
     */
    inventoryTypes(tenantId: string): Observable<SmartInventoryType[]> {
        return this.http.get<SmartInventoryType[]>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartinventorytype?api-version=1.15`)
    }

    /**
     * Create a Smart Inventory Item
     *
     * @param tenantId id of the current tenant
     * @param data for a new Smart Inventory Item object
     */
    create(tenantId: string, payload: SmartInventory): Observable<SmartInventory> {
        return this.http.post<SmartInventory>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartinventory?api-version=1.15`, payload)
    }

    /**
     * Update a Smart Inventory Item
     *
     * @param tenantId id of the current tenant
     * @param data for a existing Smart Inventory Item object
     */
    update(tenantId: string, id: string, payload: SmartInventory): Observable<SmartInventory> {
        return this.http.put<SmartInventory>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartinventory/${id}`, payload)
    }

    /**
     * Delete inventory Item
     *
     * @param tenantId id of the current tenant
     * @param streamId id of the stream item to delete
     */
    delete(tenantId: string, inventoryId: string): Observable<SmartInventory> {
        return this.http.delete<SmartInventory>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartinventory/${inventoryId}?api-version=1.15`)
    }

    /**
     * List Smart Inventory Items
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<SmartInventory>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<SmartInventory>>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartinventory?api-version=1.15`, { params })
    }

    /**
     * List Campaign Inventory Items for a Campaign
     *
     * @param tenantId id of the current tenant
     * @param campaignId id of the current campaign
     */

    campaignInventoryByCampaignId(tenantId: string, campaignId: string): Observable<SmartCampaignInventoryResponse> {
        return this.http.get<SmartCampaignInventoryResponse>(`${this.environment.appApiHost}/api/${tenantId}/admin/SmartCampaignInventories/Campaign/${campaignId}?api-version=1.15`)
    }

    /**
     * Update a campaign Smart Inventory Item
     *
     * @param tenantId id of the current tenant
     * @param campaignId id of the current tenant
     * @param inventoryId id of the current tenant
     * @param data for a existing Smart Inventory Item object
     */

    campaignInventoryUpdate(tenantId: string, campaignId: string, inventoryId: string, payload: any): Observable<CampaignInventory> {
        return this.http.put<CampaignInventory>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign/${campaignId}/smartinventory/${inventoryId}`, payload)
    }
}
