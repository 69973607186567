import { Observable } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'

import { AudioAdvertPlayCountViewModel, AudioAdvertPlaySummaryViewModel, PaginatedViewModel } from '../models/Metrics'
import { TimeUtils } from '../utils/time-utils'

export class StreamingAudioAdvertisingDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    advertPlays(tenantId: string, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date, page: number): Observable<PaginatedViewModel<AudioAdvertPlayCountViewModel>> {
        let params: { [param: string]: string | string[] } = {
            dimensionName,
            dimensionValue,
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString(),
            page: page.toString()
        }

        return this.http.get<PaginatedViewModel<AudioAdvertPlayCountViewModel>>(`${this.environment.metricsApiHost}/livestreamaudioadvertising/${tenantId}`, { params })
    }

    advertSummary(tenantId: string, dimensionName: string, dimensionValue: string, startDate: Date, endDate: Date): Observable<AudioAdvertPlaySummaryViewModel> {
        let params: { [param: string]: string | string[] } = {
            dimensionName,
            dimensionValue,
            startDate: TimeUtils.recreateDateAsUTC(startDate).toISOString(),
            endDate: TimeUtils.recreateDateAsUTC(endDate).toISOString()
        }

        return this.http.get<AudioAdvertPlaySummaryViewModel>(`${this.environment.metricsApiHost}/livestreamaudioadvertising/${tenantId}/summary`, { params })
    } 
}
