import { Component, Input } from '@angular/core'

import { ComponentRenderer } from '../interfaces/component-renderer.interface'
import { ColumnComponentRendererSpec, ColumnRendererSpec } from '../interfaces/datagrid-column.interface'

export interface ImgRendererConfig {
    src: string
    fallbackSrc: string
    width: number
    height: number
}

@Component({
    selector: 'fbr-img-renderer',
    template: `
        <img [src]="config.src" (error)="errorHandler($event)" [width]="config.width" [height]="config.height" />
    `
})
export class ImgRendererComponent implements ComponentRenderer<ImgRendererConfig> {
    @Input()
    config: ImgRendererConfig

    errorHandler(event: any) {
        event.target.src = this.config.fallbackSrc
    }
}

/**
 * Creates a {@link ColumnRendererSpec} for rendering an image in a column.
 */
export function ImgRenderer<R>(srcPropertyName: string, fallbackSrc: string = '/assets/community_icon-300x300.png', width: number = 60, height: number = 60): ColumnRendererSpec<R, ImgRendererConfig> {
    return ColumnComponentRendererSpec({
        type: ImgRendererComponent,
        config(record): ImgRendererConfig {
            return {
                src: ((record as R)[srcPropertyName] as string) || fallbackSrc,
                fallbackSrc,
                width,
                height
            }
        }
    })
}
