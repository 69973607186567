/**
 * This guard should be applied when no authentication is neccessary
 */

import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
    canActivate(): boolean {
        return true
    }
}
