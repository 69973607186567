import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Environment } from '@fabrik/common'
import { TimeUtils } from '../utils/time-utils'
import { EsgSummary } from '../models/Esg'

export class EsgDataService {

  constructor(private environment: Environment, private http: HttpClient) {}
  

  getEsgData(tenantId: string, monthDate: Date) {
    let params: { [param: string]: string | string[] } = {
      tenantId,
      monthDate: TimeUtils.recreateDateAsUTC(monthDate).toISOString()
    }
    return this.http.get<EsgSummary[]>(`${this.environment.appApiHost}/api/${tenantId}/admin/reports/esg`, { params })
  }
}
