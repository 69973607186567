import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { Observable, Subject } from 'rxjs'
import { TenantType } from '../enums/tenant-type.enum'
import { MeResponse, Tenant, TenantInfo, UserAccessType } from '../models/MeResponse'

export class AuthDataService {
    constructor(private environment: Environment, private http: HttpClient) {
        this.storage = [window.localStorage, window.sessionStorage].filter(this.testStorage)
    }
    demoModeChange$ = new Subject<any>()

    private storage = new Array<Storage>()

    private get keys() {
        return {
            demo: `${this.environment.tokenKey}_DEMO_MODE_ACTIVE`,
            token: this.environment.tokenKey,
            me: `${this.environment.tokenKey}_ME`,
            expiry: `${this.environment.tokenKey}_EXP`,
            tenant: `${this.environment.tokenKey}_TENANT`
        }
    }

    public setDemoMode(value: boolean) {
        if (value) {
            this.saveInStorage(this.keys.demo, '1')
        } else {
            this.removeFromStorage(this.keys.demo)
        }
        this.demoModeChange$.next(this.demoMode)
    }

    public get demoMode(): boolean {
        return Boolean(this.getFromStorage(this.keys.demo))
    }

    public get abilities(): string[] {
        if (!this.tenant) {
            return []
        }
        return this.tenant.products.map(p => p.abilities).reduce((prev, curr) => prev.concat(curr), [])
    }

    public get tenant(): Tenant | null {
        const tokenDetails = this.tokenDetails

        if (tokenDetails) {
            if (tokenDetails.tenants) {
                return tokenDetails.tenants.find(tenant => tenant.id === this.tenantId) || null
            }
        }

        return null
    }

    public get tenantId(): string {
        let tenantId = this.getFromStorage(this.keys.tenant)

        if (tenantId === '0') {
            let tokenDetails = this.tokenDetails

            if (tokenDetails && tokenDetails.tenants.length) {
                tenantId = tokenDetails.tenants[0].id
            }
        }

        return tenantId || '0'
    }

    public get me(): MeResponse | null {
        const me = this.getFromStorage(this.keys.me)
        if (me) {
            try {
                return JSON.parse(me) as MeResponse
            } catch (error) {
                console.warn(error)
                return null
            }
        }

        return null
    }

    public get tenants(): Tenant[] {
        return this.tokenDetails?.tenants || []
    }

    public get token(): string | null {
        const token = this.getFromStorage(this.keys.token)
        if (token) {
            return token
        }

        return null
    }

    public get tokenDetails(): MeResponse | null {
        if (!this.authenticated) {
            return null
        }

        return this.me
    }

    public get tokenExpiry(): number {
        const expiry = this.getFromStorage(this.keys.expiry)
        if (expiry) {
            return Number(expiry) || 0
        }
        return 0
    }
    
    public get isInternalUser(): boolean {
         const userAccessType = this.me?.userAccessType;
         if(userAccessType && userAccessType==UserAccessType.Internal){
            return true;
         }
         return false;
    }

    public get authenticated(): boolean {
        if (this.token) {
            return !this.tokenExpired
        } else {
            return false
        }
    }

    public get tokenExpired(): boolean {
        var expired = new Date().getTime() >= this.tokenExpiry
        if (expired) {
            window.localStorage.clear()
            window.sessionStorage.clear()
            this.clearStorage()
        }
        return expired
    }

    public get isRadioTenant(): boolean {
        return this.tenant?.tenantType === TenantType.Radio
    }

    public get isIdentityMember(): boolean {
        return this.tenants.filter(tenant => tenant.id == this.environment.identityTenantId)?.length > 0
    }

    /**
     * Return the redirect endpoint once authenticated with the fabrik platform
     *w
     * @param tenantId id of the current tenant
     * @param returnTo optional return to url
     */
    getAuthEndpoint(tenantId: string = '0', returnTo: string | null = null): string {
        let redirectURL = new URL(`/authenticate`, window.location.origin)

        if (tenantId !== '0') {
            redirectURL = new URL(`/authenticate/${tenantId}`, redirectURL.href)
        }

        if (returnTo && returnTo !== '/') {
            let parts = returnTo.split('/')
            let guidIndex = parts.findIndex(value => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(value))

            if (guidIndex !== -1) {
                parts = parts.slice(0, guidIndex)
            }

            redirectURL.searchParams.append('returnTo', parts.join('/'))
        }

        const authURL = new URL('/oauth/authorize', this.environment.authHost)
        authURL.searchParams.append('response_type', 'token')
        authURL.searchParams.append('client_id', this.environment.authClientId)
        authURL.searchParams.append('redirect_uri', redirectURL.href)
        authURL.searchParams.append('scope', `all_product_abilities tenant:${tenantId}`)

        return authURL.href
    }

    private saveInStorage(key: string, value: string) {
        this.storage.forEach(storage => storage.setItem(key, value))
    }

    private getFromStorage(key: string): string | null {
        const results = this.storage.map(storage => storage.getItem(key)).filter(Boolean) as string[]

        if (results.length) {
            return results[0]
        }

        return null
    }

    private removeFromStorage(key: string) {
        this.storage.forEach(storage => storage.removeItem(key))
    }

    clearStorage() {
        this.storage.forEach(storage => storage.clear())
    }

    /**
     * Logs the user out by clearing storage
     */
    logOut() {
        const authURL = new URL('/accounts/logout', this.environment.authHost)

        authURL.searchParams.append('returnUrl', window.location.href)

        this.clearStorage()

        window.location.href = authURL.href
    }

    /**
     * Save the oauth/me response to storage
     * @param payload The payload to save
     */
    saveMe(payload: MeResponse) {
        this.saveInStorage(this.keys.me, JSON.stringify(payload))
    }

    /**
     * Save the bearer JWT to session storage
     * @param token The bearer token
     */
    saveToken(token: string, tenantId: string = '0'): void {
        this.saveInStorage(this.keys.tenant, tenantId)
        this.saveInStorage(this.keys.token, token)
    }

    /**
     * Save the bearer JWT expiry time to session storage
     * @param tokenExpiresIn The amount of seconds until the token expires
     */
    saveTokenExpiry(tokenExpiresIn: number): void {
        let expiryTime = new Date()
        expiryTime.setSeconds(expiryTime.getSeconds() + tokenExpiresIn)
        this.saveInStorage(this.keys.expiry, (expiryTime.getTime() - 20).toString())
    }

    /**
     * Validates if a user has the specified ability
     */
    validateAbility(ability: string): boolean {
        return this.abilities.indexOf(ability) !== -1
    }

    /**
     * Get tenant information
     *
     * @param tenantId id of the current tenant
     */
    getTenantInfo(tenantId: string): Observable<TenantInfo> {
        return this.http.get<TenantInfo>(`${this.environment.appApiHost}/api/${tenantId}/admin/tenant/info`)
    }

    testStorage(storage?: Storage) {
        if (!storage) return false
        const key = 'test'
        try {
            storage.setItem(key, key)
            storage.removeItem(key)
            return true
        } catch (_error) {
            return false
        }
    }
}
