import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { AppDisplayName } from '../models/AppDisplayName'
import { Member } from '../models/Member'

export class AppDisplayNameDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Get app meta data.
     *
     * @param tenantId id of the current tenant
     */
    getAppmetadata(tenantId: string): Observable<AppDisplayName> {
        return this.http.get<AppDisplayName>(`${this.environment.appApiHost}/api/${tenantId}/admin/app`)
    }

    /**
     * Get a user.
     *
     * @param tenantId id of the current tenant
     */
    getUser(tenantId: string): Observable<Member> {
        return this.http.get<Member>(`${this.environment.appApiHost}/api/${tenantId}/admin/users?page=0&limit=1`)
    }

    /**
     * Update App meta with profile keys.
     *
     * @param tenantId id of the current tenant
     * @param data app meta data
     */
    updateAppMetaData(tenantId: string, data: any): Observable<AppDisplayName> {
        return this.http.put<AppDisplayName>(`${this.environment.appApiHost}/api/${tenantId}/admin/app`, data)
    }
}
