export enum PaymentProviderType {
    PayFast,
    Google,
    Apple,
    Huawei,
    Manual,
    Nouvobanq
}

export enum RoleType {
    Subscription = 0,
    OnceOff = 1
}

export enum TransactionStatus {
    Successful,
    Failed
}
