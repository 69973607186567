import { Component, EventEmitter, Input, Output } from '@angular/core'
import { LanguageCode } from '@fabrik/common'

@Component({
    selector: 'fbr-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
    @Input() currentLanguageCode: LanguageCode
    @Input() availableLanguageCodes: LanguageCode[]
    @Input() disabled = false
    @Output() languageCodeChange = new EventEmitter<LanguageCode>()
}
