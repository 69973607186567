import { Observable } from 'rxjs'

import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList } from '@fabrik/common'

import { CampaignPatchData, CreateCampaignData, SmartCampaign } from '../models/SmartCampaign'
import { Advertiser } from '../models/Advertiser'
import { CampaignInventory, InventoryUploadFile } from '../models/SmartInventory'
import { telemetryCampaigns } from '../models/SmartCampaignReport'

export class CampaignCastDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Retrieve a Smart Campaign by id.
     *
     * @param tenantId id of the current tenant
     * @param smartCampaignId id of the Smart Campaign item to retrieve
     */
    byId(tenantId: string, smartCampaignId: string): Observable<SmartCampaign> {
        return this.http.get<SmartCampaign>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign/${smartCampaignId}/?api-version=1.15`)
    }

    /**
     * Create a Smart Campaign item
     *
     * @param tenantId id of the current tenant
     * @param data for a new Smart Campaign object
     */
    create(tenantId: string, payload: SmartCampaign | CreateCampaignData): Observable<SmartCampaign | CreateCampaignData> {
        return this.http.post<SmartCampaign>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign?api-version=1.15`, payload)
    }

    /**
     * Update a Smart Campaign item
     *
     * @param tenantId id of the current tenant
     * @param data for a existing Smart Campaign object
     */
    update(tenantId: string, payload: SmartCampaign | CreateCampaignData, campaignId): Observable<SmartCampaign | CreateCampaignData> {
        return this.http.put<SmartCampaign>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign/${campaignId}?api-version=1.15`, payload)
    }

    /**
     * Update a Smart Campaign enddate
     *
     * @param tenantId id of the current tenant
     * @param campaignId id of the selected campaign
     * @param data for a existing smart campaign patch
     */
    updateEndDate(tenantId: string, campaignId: string, payload: CampaignPatchData[]): Observable<SmartCampaign> {
        return this.http.patch<SmartCampaign>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign/${campaignId}?api-version=1.15`, payload)
    }

    /**
     * Delete a Smart Campaign item
     *
     * @param tenantId id of the current tenant
     * @param streamId id of the stream item to delete
     */
    delete(tenantId: string, campaignId): Observable<SmartCampaign> {
        return this.http.delete<SmartCampaign>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign/${campaignId}?api-version=1.15`)
    }

    /**
     * List Smart Campaign items
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<SmartCampaign>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (args.page) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (args[key]) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<SmartCampaign>>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign?api-version=1.15`, { params })
    }

    /**
     * List Smart Campaign Reports
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    listReports(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<telemetryCampaigns>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (args.page) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (args[key]) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<telemetryCampaigns>>(`${this.environment.appApiHost}/api/${tenantId}/admin/telemetry/Campaigns?api-version=1.15`, { params })
    }

    /**
     * List Advertisers
     *
     * @param tenantId id of the current tenant
     */

    advertisers(tenantId: string, includeGlobal: boolean): Observable<PaginatedList<Advertiser>> {
        return this.http.get<PaginatedList<Advertiser>>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartadvertisers?api-version=1.15&limit=5000&includeGlobal=${includeGlobal}`)
    }

    /**
     * Get Advertiser by ID
     *
     * @param tenantId id of the current tenant
     * @param advertiserId id of the desired advertiser
     */

    advertiserById(tenantId: string, advertiserId: string): Observable<Advertiser> {
        return this.http.get<Advertiser>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartadvertisers/${advertiserId}?api-version=1.15`)
    }

    /**
     * Add Smart Inventory
     *
     * @param tenantId id of the current tenant
     * @param smartCampaign campaign object
     * @param smartInventoryId inventory object id to assign to campaign
     */

    addSmartInventory(tenantId: string, smartCampaign: SmartCampaign | CreateCampaignData, smartInventoryId: string): Observable<any> {
        return this.http.post<any>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign/${smartCampaign.id}?api-version=1.15`, {
            ...smartCampaign,
            smartInventoryId
        })
    }

    /**
     * Create Campaign Inventory
     *
     * @param tenantId id of the current tenant
     * @param smartCampaign campaign object
     * @param campaignInventory object to assign to campaign
     */

    createCampaignInventory(tenantId: string, smartCampaignId: string, campaignInventory: CampaignInventory): Observable<SmartCampaign> {
        return this.http.post<any>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign/${smartCampaignId}?api-version=1.15`, campaignInventory)
    }

    /**
     * Create Campaign Inventory for Smashboard
     * @param tenantId id of the current tenant
     * @param payload object to assign to campaign
     */
    createCampaignInventorySmashboard(tenantId: string, body: any) {
        const headers = new HttpHeaders().set('accept', 'application/vnd.smashboard.api.v2.0+json, application/json')
        return this.http.request('post', `${this.environment.smashboardAPI}/api/${tenantId}/filters`, { body, headers })
    }

    /**
     * Upload Campaign Assets
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadFile(tenantId: string, file: FormData): Observable<InventoryUploadFile> {
        return this.http.post<InventoryUploadFile>(`${this.environment.appApiHost}/api/${tenantId}/admin/attachments`, file)
    }

    /**
     * Upload Campaign Assets (with progress)
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadFileWithProgress(tenantId: string, file: FormData): Observable<HttpEvent<InventoryUploadFile>> {
        return this.http.post<InventoryUploadFile>(`${this.environment.appApiHost}/api/${tenantId}/admin/attachments`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }

    /**
     * Get Uploaded attachments data
     * @param {tenantId} tenantId of the current tenant
     * @param {smartAttachmentId} smartAttachmentId of the file
     */
    uploadedFileDetails(tenantId: string, smartAttachmentId: string) {
        return this.http.get(`${this.environment.appApiHost}/api/${tenantId}/admin/attachments/${smartAttachmentId}`)
    }
}
