import { TenantType } from '../enums/tenant-type.enum'

export interface Product {
    abilities: string[]
    id: string
    name: string
}

export interface Tenant {
    logo?: any
    products: Product[]
    id: string
    name: string
    slug?: any
    address?: any
    active: boolean
    echocastLiveStreamEnabled: boolean
    virtual: boolean
    acceptDefaultBusinesses: boolean
    userApprovalRequired: boolean
    tenantType?: TenantType
}

export interface TenantInfo {
    tenantId: string
    name: string
    slogan: string
    appDescription: string
    registrationMessage: string
    revenueShare: number
}

export interface MeResponse {
    tenants: Tenant[]
    username: string
    phoneNumber?: any
    profileImagePath?: any
    active: boolean
    id: string
    email: string
    firstName?: any
    lastName?: any
    userAccessType?: UserAccessType
}
export enum UserAccessType{
    None,
    Internal,
    External
}
