import { Component } from '@angular/core'
import { Environment } from '@fabrik/common'
import { Ticket } from '../../data/models/Ticket'
import { DataService } from '../../data/providers/data.service'

import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { Router } from '@angular/router'
import { Dialog } from '../../providers/modal/modal.service'

/**
 * A form input control which displays a number input with a percentage sign suffix.
 */
@Component({
    selector: 'fbr-feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements Dialog<Ticket> {
    anonymous: boolean
    ticketdata: Ticket

    constructor(public dataService: DataService, private environment: Environment, private router: Router) {}

    resolveWith: (result?: Ticket) => void

    cancel() {
        this.router.navigate(['./'], {
            queryParams: null
        })
        this.resolveWith()
    }

    submit() {
        if (this.anonymous) {
            this.ticketdata.name = 'Anonymous'
            this.ticketdata.email = 'Anonymous@immedia.co.za'
            this.ticketdata.contactNumber = 'Anonymous'
            this.resolveWith(this.ticketdata)
        } else {
            this.ticketdata.description = this.ticketdata.description + '_Email: ' + this.ticketdata.email
            this.ticketdata.description = this.ticketdata.description.split('_').join('<br><br>')
            this.ticketdata.email = 'FeedbackCommunityCenter@immedia.co.za'
            this.resolveWith(this.ticketdata)
        }
        this.router.navigate(['./'], {
            queryParams: null
        })
    }
}
