import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { Observable } from 'rxjs'
export class DocumentDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * Get policies
     * @param tenantId Id of the tenant
     */
    policy(tenantId: string, value: number): Observable<string> {
        return this.http.get<string>(`${this.environment.appApiHost}/api/${tenantId}/admin/policies/content/${value}`)
    }

    /**
     * Upload policies
     * @param tenantId Id of the tenant
     */
    uploadPolicy(tenantId: string, value: number, data: FormData): Observable<string> {
        return this.http.post<string>(`${this.environment.appApiHost}/api/${tenantId}/admin/policies/${value}`, data)
    }
}
