import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList } from '@fabrik/common'
import { campaignInventories, telemetryCampaigns, telemetryCampaignInventories, SmartCampaignReport } from '../models/SmartCampaignReport'

export class CampaignCastReportDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * List Smart Campaign Reports
     *
     * @param tenantId id of the current tenant
     * @param sortField competition field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    listReports(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<telemetryCampaigns>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (args.page) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (args[key]) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<telemetryCampaigns>>(`${this.environment.appApiHost}/api/${tenantId}/admin/smartcampaign?api-version=1.15`, { params })
    }

    /**
     * List smart campaign inventories
     * @param tenantId Id of the current tenant
     * @param campaignId campaign Id
     */
    listCampaignInventories(tenantId: string, campaignId: string): Observable<campaignInventories> {
        return this.http.get<campaignInventories>(`${this.environment.appApiHost}/api/${tenantId}/admin/SmartCampaignInventories/Campaign/${campaignId}`)
    }

    /**
     * Get telemetry data for a Campaign
     * @param tenantId
     */
    getCampaignTelemetry(tenantId: string, campaignId: string, includeSummaries = false, live = false): Observable<SmartCampaignReport> {
        return this.http.get<SmartCampaignReport>(
            `${this.environment.appApiHost}/api/${tenantId}/admin/telemetry/Campaigns/${campaignId}/summaryandKeys?api-version=1.15&includeSummaries=${includeSummaries.toString()}&live=${live.toString()}`
        )
    }

    /**
     * Get telemetry data for inventory
     * @param tenantId
     */
    getInventoryTelemetry(tenantId: string, campaignId: string): Observable<telemetryCampaignInventories> {
        return this.http.get<telemetryCampaignInventories>(`${this.environment.appApiHost}/api/${tenantId}/admin/telemetry/Campaigns/${campaignId}/summaryAndKeys?api-version=1.15&includeSummaries=true&live=true`)
    }
}
