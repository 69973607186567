import { Observable } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import { ManualProducts, Products, ProductSubscription } from '../models/ProductSubscription'

export class ProductSubscriptionsDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * List all product subscriptions
     *
     * @param tenantId id of the current tenant
     * @param sortField field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Products>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<Products>>(`${this.environment.appApiHost}/api/${tenantId}/admin/products`, { params })
    }

    /**
     * Delete android product subscription
     *
     * @param tenantId id of the current tenant
     * @param productId id of the product to delete
     */
    delete(tenantId: string, productId: string): Observable<ProductSubscription> {
        return this.http.delete<ProductSubscription>(`${this.environment.appApiHost}/api/${tenantId}/admin/products/${productId}`)
    }

    /**
     * Create android product subscription
     *
     * @param tenantId id of the current tenant
     * @param data product object
     */
    create(tenantId: string, data: ProductSubscription): Observable<ProductSubscription> {
        return this.http.post<ProductSubscription>(`${this.environment.appApiHost}/api/${tenantId}/admin/products`, data)
    }

    /**
     * Retrieve product subscription by id.
     *
     * @param tenantId id of the current tenant
     * @param productId id of the product to retrieve
     */
    byId(tenantId: string, productId: string): Observable<ProductSubscription> {
        return this.http.get<ProductSubscription>(`${this.environment.appApiHost}/api/${tenantId}/admin/products/${productId}`)
    }

    /**
     * Update product subscription
     *
     * @param tenantId id of the current tenant
     * @param productId id of the product to update
     * @param data for a existing product object
     */
    update(tenantId: string, productId: string, payload: ProductSubscription): Observable<ProductSubscription> {
        return this.http.put<ProductSubscription>(`${this.environment.appApiHost}/api/${tenantId}/admin/products/${productId}`, payload)
    }

    /**
     * List all manual product subscriptions
     *
     * @param tenantId id of the current tenant
     * @param sortField field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    listManualProducts(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<ManualProducts>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<ManualProducts>>(`${this.environment.appApiHost}/api/${tenantId}/admin/paymentTransactions`, { params })
    }

    /**
     * Complete/Approve payment
     *
     * @param tenantId id of the current tenant
     * @param referenceId id of the product/reference to complete
     */
    complete(tenantId: string, referenceId: string): Observable<ManualProducts> {
        return this.http.put<ManualProducts>(`${this.environment.appApiHost}/api/${tenantId}/admin/paymentTransactions/confirm/${referenceId}`, {})
    }
}
